import React, { useState, useEffect } from 'react';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import 'react-quill/dist/quill.snow.css';
import {Container, Typography, List, ListItem, ListItemText, Paper, Box, IconButton,} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import withAdminRole from "../hoc/withAdminRole";
import EmailEditor from "../components/editor/EmailEditor";

const EmailTemplateEditor = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [focusedField, setFocusedField] = useState('template');
    const fetchWithToken = useFetchWithToken();
    const [snackbarOpen, setSnackbarOpen] = useState(false); // snackbar is like a toast message !! I like toasts

    useEffect(() => {
        const fetchTemplates = async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/email-templates`;
            const response = await fetchWithToken(apiUrl, "POST");
            setTemplates(response);
        };

        fetchTemplates();
    }, []);

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
        setFocusedField('template'); // Reset focus to the template editor by default
    };

    const handleSave = async () => {
        const { id, template, subject,  description} = selectedTemplate;
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/email-templates/${id}`;
        await fetchWithToken(apiUrl, "POST", { template, subject, description });
        setSelectedTemplate(null);
        setSnackbarOpen(true);
        await handleUpdateTemplates();
    };

    const handleUpdateTemplates = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/email-templates`;
        const response = await fetchWithToken(apiUrl, "POST");
        setTemplates(response);
    };


    return (
        <Container className='email-editor-container'>
            <Typography variant="h4" component="h1" gutterBottom>
                Éditeur de Notifications Email
            </Typography>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" mt={4} className='email-editor'>
                <Box width={{ xs: '100%' }} mr={{ md: 2 }} mb={{ xs: 4, md: 0 }}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Sélectionner un Modèle
                        </Typography>
                        <List>
                            {templates.map((template) => (
                                <ListItem button key={template.id} onClick={() => handleTemplateSelect(template)}>
                                    <ListItemText primary={template.description} />
                                    <IconButton edge="end" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Box>
                <EmailEditor focusedField={focusedField} handleSave={handleSave} setFocusedField={setFocusedField} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                    message="Modifications enregistrées avec succès"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />

            </Box>
        </Container>
    );
};
export default withAdminRole(EmailTemplateEditor);

