import { Box, Card, CardContent, Checkbox, Divider } from "@mui/material";
import React, { useState } from "react";
import DerogationItem from "./Wish/DerogationItem";

const DeorgationList = ({ wishes, selectedWishes, setSelectedWishes, showClose }) => {
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

    const handleSelectWish = (wishId, isSelected, index, shiftKey) => {
        if (shiftKey && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, index);
            const end = Math.max(lastSelectedIndex, index);
            const newSelectedWishes = [...selectedWishes];
            for (let i = start; i <= end; i++) {
                const wish = wishes[i];
                if (isSelected) {
                    if (!newSelectedWishes.includes(wish.voeu_id)) {
                        newSelectedWishes.push(wish.voeu_id);
                    }
                } else {
                    const wishIndex = newSelectedWishes.indexOf(wish.voeu_id);
                    if (wishIndex > -1) {
                        newSelectedWishes.splice(wishIndex, 1);
                    }
                }
            }
            setSelectedWishes(newSelectedWishes);
        } else {
            setSelectedWishes((prevSelected) => {
                if (isSelected) {
                    return [...prevSelected, wishId];
                } else {
                    return prevSelected.filter((id) => id !== wishId);
                }
            });
        }
        setLastSelectedIndex(index);
    };

    const handleCheckboxChange = (e, wishId, index) => {
        handleSelectWish(wishId, e.target.checked, index, e.nativeEvent.shiftKey);
    };

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
            gap: 2,
        }}>
            {wishes.map((wish, index) => (
                <Card
                    sx={{ boxShadow: "none" }}
                    key={wish.voeu_id}
                    className={selectedWishes.includes(wish.voeu_id) ? "derogation-card selected" : "derogation-card"}
                >
                    <CardContent className="derogation-item" sx={{ boxShadow: "none" }}>
                        {showClose === false && (
                            <Checkbox
                                checked={selectedWishes.includes(wish.voeu_id)}
                                onChange={(e) => handleCheckboxChange(e, wish.voeu_id, index)}
                            />
                        )}
                        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                        <DerogationItem wish={wish} showClose={showClose} />
                    </CardContent>
                </Card>
            ))}
        </Box>
    )
}
export default DeorgationList
