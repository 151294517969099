import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const FormationMangerAdminListUserWaitTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Code Sycomore</TableCell>
                <TableCell>Prénom Nom</TableCell>
                <TableCell>Structure</TableCell>
                <TableCell>fonction</TableCell>

                <TableCell>Statut</TableCell>
                <TableCell>Actions</TableCell>
            </TableRow>
        </TableHead>
    )
};

export default FormationMangerAdminListUserWaitTableHead;
