import {formatDate} from "./functionTransforms";


export const getAllRelevantDates = (saturdays, openDates, trainingSessions) => {
    const filteredSaturdays = saturdays.filter(saturday => openDates.some(openDate => openDate.date === saturday.slice(5)))
    const trainingSessionDates = trainingSessions.filter(session => !filteredSaturdays.includes(session.date)).map(session => session.date);
    const allDates = [...new Set([...filteredSaturdays, ...trainingSessionDates])];
    return allDates.sort();
};

export   const getAdditionalInfo = (date, trainingSessions, t) => {
    const session = trainingSessions.find(session => session.date === date);
    return session ? {status: 'open', text: t("HAVE_SESSION")} : {status: 'closed', text: t("NO_SESSION")};
};

export function findEndDate(startDate, trainingSessionsDates) {
    const dateRange = trainingSessionsDates.find(range => range.start === startDate);
    return dateRange ? formatDate(dateRange.end) : null;
}