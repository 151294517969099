export const LevelOptions = [
    { key: 'cep1', label: 'CEP 1', section: ["louvetisme", "eclaireur", "eclaireuse"], "canSelectDate": true, "level": 1 },
    { key: 'cep2', label: 'CEP 2', section: ["louvetisme", "eclaireur", "eclaireuse"], "canSelectDate": true, "level": 2 },
    { key: 'cameleon', label: 'Cameleon', section: ["louvetisme", "eclaireur", "eclaireuse"], "canSelectDate": false, "level": 1.5 },
    { key: 'rega1', label: 'REGA 1', section: ["feu"], "canSelectDate": true, "level": 1 },
    { key: 'rega2', label: 'REGA 2', section: ["feu"], "canSelectDate": true, "level": 2 },
    { key: 'reap', label: 'REAP', section: ["route"], "canSelectDate": true, "level": 1 },
    { key: 'recp', label: ' RECP', section: ["route"], "canSelectDate": true, "level": 2 },
    { key: 'senemco1', label: 'SENAMCO Chef de groupe', section: ["territorial"], "canSelectDate": false, "level": 1 },
    { key: 'senemco2', label: 'SENAMCO Commissaire', section: ["territorial"], "canSelectDate": false, "level": 2 },
    { key: 'campnationaux', label: 'Camp national', section: ["louvetisme", "eclaireur", "eclaireuse", "route", "feu"], "canSelectDate": false, "level": 3 },
];
