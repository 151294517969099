import React, {useState} from "react";
import ConfirmModal from "./Modal/ConfirmModal";
import {style} from "../Utils/style";
import {Button, TextField} from "@mui/material";
import useTexts from "../hooks/useTexte";
import {useFetchWithToken} from "../hooks/useFetchWithToken";


const DerogationConfirme = ({action, loadWishes, setSelectedWishes, selectedWishes}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [comment, setComment] = useState('');
    const {t} = useTexts();
    const fetchWithToken = useFetchWithToken();

    const Handleclose = async () => {
        setModalOpen(false)
        setComment("")
    };
    const OnConfirme = async () => {
        try {
            const apiUrl = (action === "valide" ? `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations/validate` : `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations/refuse`);
            await fetchWithToken(apiUrl, "POST", {wishIds: selectedWishes, comment});


            setModalOpen(false);
            loadWishes();
            setSelectedWishes([]);
            setComment('');
        } catch (error) {
            console.error('Erreur lors de la validation des dérogations:', error);
        }
    };
    const labelBTN = (action === "valide" ? t("VALIDER_DEROGATION") : t("REFUSE_DEROGATION"))
    const labelModal = (action === "valide" ? t("CONFIRM_DEROGATION") : t("CONFIRM_UNVALIDATE_DEROGATION"))
    return (
        <>
            <Button className="azimuth-btn" onClick={() => setModalOpen(true)}>
                {labelBTN}
            </Button>
            <ConfirmModal
                open={modalOpen}
                message={
                    <>
                        <span style={{color: style.red, fontSize: '25px'}}>{labelModal}</span>
                        <TextField
                            label="Commentaire"
                            variant="outlined"
                            fullWidth
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            sx={{mt: 2}}
                        />
                    </>
                }
                onClose={Handleclose}
                onConfirm={OnConfirme}
            />
        </>
    )

}
export default DerogationConfirme