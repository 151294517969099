import {TableCell, TableRow} from "@mui/material";
import {formatDateHour} from "../Utils/functionTransforms";
import React from "react";


const UserLogItem = ({log, selectedPersonne}) => {

    return (
        <TableRow key={log.id}>
            <TableCell sx={{ verticalAlign: 'top'}}>{formatDateHour(log.date_add)}</TableCell>
            <TableCell sx={{ verticalAlign: 'top'}}>{log.event_code}</TableCell>
            <TableCell sx={{ verticalAlign: 'top'}}>
                {log.event_code === 'sendEmail' && (
                    <>
                        Sujet : {log.data.subject} <br/>
                        Destinataire : {log.data.to}
                        <div dangerouslySetInnerHTML={{__html: log.data.htmlMessage}}/>
                    </>
                )}
                {log.event_code === 'refuseDerogation' || log.event_code === 'validateDerogation' && (
                    <>
                        Avis : {log.data.comment} <br/>
                        Date du voeux : {log.data.requestDate} <br/>
                        Type de formation : {log.data.requestLevel} <br/>
                        Branche : {log.data.requestBranch}
                    </>
                )}

                {log.event_code === 'RefuseFormation' && (
                    <>
                        Formation refusée : {log.data.formationNom} <br/>
                        Date de la formation : {log.data.requestDate} <br/>
                        Type de formation : {log.data.requestLevel} <br/>
                        Branche : {log.data.requestBranch}
                    </>
                )}

                {log.event_code === 'AddWish' && (
                    <>
                        Ajout d'un voeu : <br/>
                        Branche : {log.data.requestBranch} <br/>
                        Date : {log.data.requestDate} <br/>
                        Niveau : {log.data.requestLevel} <br/>
                        Spécialité : {log.data.requestSpeciality.length > 0 && (
                        <ul>
                            {log.data.requestSpeciality.map((speciality, index) => (
                                <li key={index}>
                                    {speciality === 'nospecialite' ? 'no_preference' : speciality}
                                </li>
                            ))}
                        </ul>

                    )}
                    </>
                )}
                {log.event_code === 'removeWish' && (
                    <>
                        Suppression d'un voeu : <br/>
                        Date : {log.data.requestDate} <br/>
                        Niveau : {log.data.requestLevel} <br/>
                        Spécialité : {log.data.requestSpeciality.length > 0 && (
                        <ul>
                            {log.data.requestSpeciality.map((speciality, index) => (
                                <li key={index}>
                                    {speciality.type === 'nospecialite' ? 'no_preference' : speciality.type}
                                </li>
                            ))}
                        </ul>

                    )}
                    </>
                )}

                {log.event_code === 'validateFormation' && (
                    <>
                        Formation validée : {log.data.formationNom} <br/>
                        Formation validée : {log.data.formationNom} <br/>
                        Date de la formation : {log.data.requestDate} <br/>
                        Type de formation : {log.data.requestLevel} <br/>
                        Branche : {log.data.requestBranch}
                    </>
                )}

            </TableCell>
            <TableCell sx={{ verticalAlign: 'top'}}>
                {log.writer_id !== log.user_id
                    ? `${log.writer_lastname} ${log.writer_firstname}`
                    : selectedPersonne.firstname + "  " + selectedPersonne.lastname}
            </TableCell>
        </TableRow>
    )

}

export default UserLogItem;
