import React, { useEffect, useState } from 'react';
import { Button, Box, Grid2, } from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { useAuth } from "../../Context/AuthContext";
import MyFicheSection from "../MyFicheSection";
import './styles/MaFiche.css';
import MaficheDocument from "./MaficheDocument";
import LoaderWait from "../LoaderWait";

function MaFiche({ id, onClose }) {
    const { currentFunction, functionOptions } = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const fetchWithToken = useFetchWithToken();
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});

    const loadWishesDetail = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}`;
            const data = await fetchWithToken(apiUrl);

            const newForm = { "sections": [] };
            for (const key in data.data) {
                const section = data.data[key];
                newForm.sections.push(section);
            }
            setFormData(newForm);
            setFormState(data.answer);
            setIsLoadingDetail(false);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {
        loadWishesDetail();
    }, []);

    const handleCheckboxChange = (sectionIndex, fieldName, optionValue, isChecked, isMultiple) => {
        if (isMultiple) {
            setFormState(prevState => {
                const existingValues = prevState[`${sectionIndex}_${fieldName}`] || [];
                return {
                    ...prevState,
                    [`${sectionIndex}_${fieldName}`]: isChecked ? [...existingValues, optionValue] : existingValues.filter(value => value !== optionValue)
                };
            });
        } else {
            setFormState(prevState => ({
                ...prevState,
                [`${sectionIndex}_${fieldName}`]: isChecked
            }));
        }
    };

    const handleInputChange = (sectionIndex, fieldName, value) => {
        setFormState(prevState => ({
            ...prevState,
            [`${sectionIndex}_${fieldName}`]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/savesanswer`;
            const body = { formData: formState };
            await fetchWithToken(apiUrl, "POST", body);
            onClose();
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
        }
    };

    if (!formData || isLoadingDetail) {
        return <LoaderWait />
    }

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" id='ficheFormContent'>
            <Grid2 container spacing={3}>
                <Grid2 size='grow'>
                    <MaficheDocument id={id} />
                </Grid2>
                <Grid2>
                    <MyFicheSection
                        formData={formData}
                        formState={formState}
                        handleInputChange={handleInputChange}
                        handleCheckboxChange={handleCheckboxChange}
                        chefFunction={chefFunction}
                    />
                </Grid2>
            </Grid2>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }} className="actions-button">
                <Button type="submit" variant="contained" color='success' sx={{ mr: 2 }}>Enregistrer et fermer</Button>
                <Button variant="outlined" color='error' onClick={onClose}>Fermer sans Sauvegarder</Button>
            </Box>
        </Box>
    );
}

export default MaFiche;
