import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {Container, Typography, Divider,} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import withAdminRole from "../hoc/withAdminRole";
import './styles/TextEditor.css';
import useTexts from '../hooks/useTexte';
import TableVirtualize from '../components/TableVirtualize/TableVirtualize';
import TextEditorModal from "../components/editor/TextEditorModal";
import TextHeader from "../components/editor/TextHeader";

const TextEditor = () => {
    const [texts, setTexts] = useState([]);
    const [newKey, setNewKey] = useState('');
    const [newText, setNewText] = useState('');
    const [editText, setEditText] = useState({ id: '', key: '', text: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();

    // Table states
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
    const [searchTable, setSearchTable] = useState('');
    const [dialogType, setDialogType] = useState('');

    const fetchTexts = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts`;
            const data = await fetchWithToken(apiUrl, "GET");
            setTexts(data);
        } catch (error) {
            console.error(error);
        }
    }, [fetchWithToken, setTexts]);

    useEffect(() => {
        fetchTexts();
    }, []);

    const addText = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts`;
            const newTextData = { key: newKey, text: newText };
            await fetchWithToken(apiUrl, "POST", newTextData);
            setNewKey('');
            setNewText('');
            fetchTexts();
        } catch (error) {
            console.error(error);
        }
    };

    const handleTableSearch = (e) => {
        setSearchTable(e.target.value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchTable);
        }, 300); // delay for perf
        return () => {
            clearTimeout(handler);
        };
    }, [searchTable]);

    const filteredTexts = useMemo(() => {
        if (!debouncedSearchQuery) {
            return texts;
        }
        return texts.filter(text =>
            (text.key && debouncedSearchQuery && text.key.toLowerCase().includes(debouncedSearchQuery.toLowerCase()))
            || (text.text && debouncedSearchQuery && text.text.toLowerCase().includes(debouncedSearchQuery.toLowerCase()))
        );
    }, [debouncedSearchQuery, texts]);

    const handleDialogOpen = (text, type) => {
        if (type === 'edit') {
            setEditText(text);
            setDialogType('edit');
        } else if (type === 'delete') {
            setEditText(text);
            setDialogType('delete');
        }
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleEditSave = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts/${editText.id}`;
            await fetchWithToken(apiUrl, "PUT", editText);
        } catch (error) {
            console.error(error);
        } finally {
            fetchTexts();
            setIsDialogOpen(false);
        }
    };

    const handleDeleteText = async() => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts/${editText.id}`;
            await fetchWithToken(apiUrl, 'DELETE', editText);
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Error while deleting text: ', error);
        } finally {
            fetchTexts();
        }
    }

    return (
        <Container className='textEditor-container'>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: 'black', margin: '16px 0' }}>
                {t("TEXT_EDITOR_TITLE")}
            </Typography>
            <TextHeader addText={addText} newText={newText} newKey={newKey} setNewText={setNewText} setNewKey={setNewKey} handleTableSearch={handleTableSearch} searchTable={searchTable}/>
            <Divider />
                <div className='tableVirtuoso desktop-restrict'>
                    <TableVirtualize
                        texts={filteredTexts}
                        fetchTexts={fetchTexts}
                        handleDialogOpen={handleDialogOpen}
                        handleDialogClose={handleDialogClose}
                        handleDeleteText={handleDeleteText}
                        handleEditSave={handleEditSave}
                        setEditText={setEditText}
                    />
                </div>
            <TextEditorModal setEditText={setEditText} editText={editText} handleDeleteText={handleDeleteText} handleEditSave={handleEditSave} dialogType={dialogType}
                             handleDialogClose={handleDialogClose} isDialogOpen={isDialogOpen} />
            <div className='mobile-restrict'>
                <p>
                    {t("PAGE_MOBILE_RESTRICT")}
                </p>
            </div>
        </Container>
    );
};

export default withAdminRole(TextEditor);