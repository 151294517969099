import React, { useState } from "react";
import Button from "@mui/material/Button";
import useTexts from "../../hooks/useTexte";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { IconButton } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const MyFormsDuplicator = ({ loadForms, selectedFormId, type }) => {
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const [open, setOpen] = useState(false);
    const [newFormName, setNewFormName] = useState("");

    const handleDuplicate = async () => {
        try {
            const apiUrl =
                type === "admin"
                    ? `${process.env.REACT_APP_API_URL}/api/form/${selectedFormId}/duplicate`
                    : type === "my"
                        ? `${process.env.REACT_APP_API_URL}/api/myform/${selectedFormId}/duplicate`
                        : `${process.env.REACT_APP_API_URL}/api/myform/duplicateFromtemplate/${selectedFormId}`;

            const body = { name: newFormName };
            await fetchWithToken(apiUrl, "POST", body);
            loadForms();
            handleClose();
        } catch (error) {
            console.error('Error duplicating form:', error);
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <>
            <IconButton
                onClick={() => handleOpen()}
                color="primary"
            >
                <ContentCopyRoundedIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 4,
                        boxShadow: 8,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        {t(type === "my" ? "DUPLIQUER_MY_FORM" : type === "admin" ? "DUPLIQUER_FORM" :
                            "ADD_FROM_TEMPLATE")}
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom du nouveau formulaire"
                        type="text"
                        fullWidth
                        value={newFormName}
                        onChange={(e) => setNewFormName(e.target.value)}
                    />
                    <Button onClick={handleDuplicate} variant="contained" color="primary" sx={{ mt: 2 }}>
                        {t("DUPLIQUER_FORM")}
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default MyFormsDuplicator;