import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";

const AvisBox = ({ title, avis, onClick, type, isPage }) => (
    <Paper sx={{
        padding: 2,
        borderRadius: 2,
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%'
    }}>
        <Box>
            <Typography variant="body1">{title}</Typography>
            <Box
                mt={1}
                p={1}
                maxHeight="150px"
                overflow="auto"
                border="1px solid #e0e0e0"
                borderRadius="4px"
                bgcolor="#f5f5f5"
                flexGrow={1}
            >
                <Typography variant="body2">
                    {avis ? (avis.idontknow === 1 ? 'Je ne connais pas le chef' : avis.avis_avis) : <em>Pas d'avis</em>}
                </Typography>
            </Box>
        </Box>

        {/* Bouton placé sous la box */}
        {onClick && isPage && (
            <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" onClick={() => onClick(type, avis)}>
                    Modifier
                </Button>
            </Box>
        )}
    </Paper>
);

export default AvisBox;