import React, {useState, useEffect} from "react";
import {Box, Button, Typography, Grid} from "@mui/material";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import LoaderWait from "./LoaderWait";

const AdminDatesMonthes = ({monthData}) => {
    const [selectedDates, setSelectedDates] = useState(new Set());
    const fetchWithToken = useFetchWithToken();
    const [loading, setLoading] = useState(true);


    const {month, days} = monthData;

    // Charger les dates ouvertes pour le mois
    useEffect(() => {
        const loadOpenDates = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/get-month?month=${(month.getMonth() + 1).toString().padStart(2, "0")}`;
                const openDates = await fetchWithToken(apiUrl, "POST");

                // Convertir les dates en objets `Date` basés sur l'année et le mois actuels
                const newDates = new Set(
                    openDates.dates.map((date) => {
                        const [month, day] = date.split("-");
                        return new Date(monthData.month.getFullYear(), parseInt(month, 10) - 1, parseInt(day, 10)).getTime();
                    })
                );

                setSelectedDates(newDates);
                setLoading(false)
            } catch (error) {
                console.error("Erreur lors du chargement des dates ouvertes :", error);
            }
        };

        loadOpenDates();
    }, [monthData]);


    const toggleDate = async (date) => {
        const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/toggle-day`;
            await fetchWithToken(apiUrl, "POST", {date: formattedDate});
            setSelectedDates((prevDates) => {
                const newDates = new Set(prevDates);
                if (newDates.has(date.getTime())) {
                    newDates.delete(date.getTime());
                } else {
                    newDates.add(date.getTime());
                }
                return newDates;
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour du jour :", error);
        }
    };

    const toggleMonth = async (open) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/toggle-month`;
            await fetchWithToken(apiUrl, "POST", {
                month: (month.getMonth() + 1).toString().padStart(2, "0"),
                open,
            });
            setSelectedDates((prevDates) => {
                const newDates = new Set(prevDates);
                days.forEach((day) => {
                    if (open) {
                        newDates.add(day.getTime());
                    } else {
                        newDates.delete(day.getTime());
                    }
                });
                return newDates;
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour du mois :", error);
        }
    };

    const isSelected = (date) => selectedDates.has(date.getTime());

    return (
        <Box key={month} sx={{mb: 4, border: "1px solid #ccc", borderRadius: 2, p: 2}}>
            <Typography variant="h6" gutterBottom>
                {month.toLocaleDateString("fr-FR", {month: "long", year: "numeric"})}
            </Typography>
            {loading == true ? (
                <LoaderWait/>
            ) : (
                <>

                    <Box sx={{display: "flex", justifyContent: "space-between", mb: 2}}>
                        <Button variant="contained" color="primary" onClick={() => toggleMonth(true)}>
                            Ouvrir tout le mois
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => toggleMonth(false)}>
                            Fermer tout le mois
                        </Button>
                    </Box>
                    <Grid container spacing={1}>
                        {days.map((day) => (
                            <Grid
                                item
                                key={day}
                                xs={1.5}
                                sx={{
                                    textAlign: "center",
                                    cursor: "pointer",
                                    padding: 1,
                                    borderRadius: 1,
                                    backgroundColor: isSelected(day) ? "primary.main" : "inherit",
                                    color: isSelected(day) ? "white" : "inherit",
                                    border: "1px solid #ddd",
                                }}
                                onClick={() => toggleDate(day)}
                            >
                                <Typography variant="body1" component="div">
                                    {day.getDate()}
                                </Typography>
                                <Typography variant="caption" component="div"
                                            sx={{color: isSelected(day) ? "white" : "text.secondary"}}>
                                    {day.toLocaleDateString("fr-FR", {weekday: "short"}).charAt(0).toUpperCase() + day.toLocaleDateString("fr-FR", {weekday: "short"}).slice(1)}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </Box>
    )
        ;
};

export default AdminDatesMonthes;
