// AuthContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useAuth} from '../Context/AuthContext';

import {useFetchWithToken} from "../hooks/useFetchWithToken"

const SystemContext = createContext();

export const useSystem = () => useContext(SystemContext);

export const SystemProvider = ({children}) => {
    const fetchWithToken = useFetchWithToken();

    const [districts, setDistricts] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [datesGroupe, setDatesGroupe] = useState([]);
    const [userData, setUserData] = useState([]);
    const [myFormationAsChef, setMyFormationAsChef] = useState([]);
    const [configuration, setConfiguration] = useState([]);
    const [textes, setTextes] = useState([]);
    const [currentYear, setCurrentYear] = useState(null);
    const [openMonth, setOpenMonth] = useState([]);
    const {token} = useAuth()


    const getConfiguration = (key) => {
        const configItem = configuration.find(item => item.KEY === key);
        return configItem ? configItem.value : null;
    };
    const loadText = async () => {
        const apiUrlText = `${process.env.REACT_APP_API_URL}/api/texts`;
        const textsData = await fetchWithToken(apiUrlText, "GET");
        setTextes(textsData);

    }

    const loadCurrentYear= async () => {
        const apiUrlText = `${process.env.REACT_APP_API_URL}/api/currentYear`;
        const data = await fetchWithToken(apiUrlText, "GET");
        setCurrentYear(data)

    }
    const loadConfig = async () => {
        const apiUrlText = `${process.env.REACT_APP_API_URL}/api/configuration`;
        const data = await fetchWithToken(apiUrlText, "GET");
        setConfiguration(data)

    }
    const loadDate = () => {

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/setup`;
            fetchWithToken(apiUrl, "GET").then(data => {
                    if (token) {
                        setProvinces(data.structures)
                        setDistricts(data.structures2)
                        setDatesGroupe(data.dates)
                        setMyFormationAsChef(data.mycep)
                        setUserData(data.dataUser)
                        setConfiguration(data.configuration)
                        setCurrentYear(data.currentYear)
                        setOpenMonth(data.openMonth)
                    }
                }
            );

    }

    useEffect(() => {
        if (token) {
            loadDate()
        }
    }, [token]);

    useEffect(() => {
        loadText()
        loadConfig()
        loadCurrentYear()
        // eslint-disable-next-line
    }, []);

    return (
        <SystemContext.Provider value={{
            provinces,
            datesGroupe,
            userData,
            myFormationAsChef,
            getConfiguration,
            textes,
            currentYear,
            openMonth,
            districts,
        }}>
            {children}
        </SystemContext.Provider>
    );
};