import React from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import { useFetchWithToken } from '../hooks/useFetchWithToken';

const DerogationExportButton = ({ filters, page }) => {
    const fetchWithToken = useFetchWithToken();

    const handleExport = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations/export`;
            const body = {
                branche: filters.selectedBranche,
                startDate: filters.selectedstartDate,
                endDate: filters.selectedendDate,
                itemsPerPage: filters.itemsPerPage,
                level: filters.selectedType,
                showClose: filters.showClose,
                showOpen: filters.showOpen,
                user: (filters.selectedPersonne ? filters.selectedPersonne.id : null),
                page,
            };
            // const blob = await fetchWithToken(apiUrl, "POST", formData, false, "BLOB");

            const response = await fetchWithToken(apiUrl, 'POST', body, false, "BLOB");

            if (response) {
                // Télécharge le fichier en utilisant file-saver
                saveAs(response, `derogations_export_${new Date().toISOString()}.xlsx`);
            } else {
                console.error('Erreur lors de l\'exportation : Réponse vide.');
            }
        } catch (error) {
            console.error('Erreur lors de l\'exportation des données :', error);
        }
    };

    return (
        <Button
            className='azimuth-btn'
            onClick={handleExport}
        >
            Exporter
        </Button>
    );
};

export default DerogationExportButton;
