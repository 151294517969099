import React from "react";
import AvisBoxGrid from "./AvisBoxGrid";
import { Box } from "@mui/material";

const UserFunctionAvis = ({u, isPage, openModalAvisCallBack}) => {
    const avisChef = u.avis.find(avis => avis.avis_level === 1);
    const avisChef2 = u.avis.find(avis => avis.avis_level === 2);
    const avisPeda = u.avis.find(avis => avis.avis_is_peda === 1);

    const avisData = [
        { title: "Avis 1", avis: avisChef, type: 1, chefData: u.chefObj, haveChef: u.chef === 1 },
        { title: "Avis 2", avis: avisChef2, type: 2, chefData: u.chefGpObj, haveChef: u.chefGp === 1 },
        { title: "Avis pédagogique", avis: avisPeda, type: 3, haveChef: u.chefAscom === 1 }
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, width: '100%' }}>
            {avisData.map((data, index) => (
                <AvisBoxGrid
                    key={index}
                    title={data.title}
                    avis={data.avis}
                    type={data.type}
                    chefData={data.chefData}
                    isPage={isPage}
                    haveChef={data.haveChef}
                    onClick={openModalAvisCallBack}
                />
            ))}
        </Box>
    )
}

export default UserFunctionAvis;
