import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import { AuthProvider } from './Context/AuthContext';
import { SystemProvider } from './Context/System';
import './styles/global.css';
import Main from "./Main";
import FreshdeskWidgetProvider from "./Provider/FreshdeskWidgetProvider";
import LoaderWait from "./components/LoaderWait";
import ErrorConnexion from "./Page/ErrorConnexion";

function App() {
    const [isBackendAlive, setIsBackendAlive] = useState(null); // null = état initial, true = OK, false = erreur
    useEffect(() => {
        const checkBackendHealth = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/isAlive`;
                const response = await fetch(apiUrl);
                if (response.ok) {
                    setIsBackendAlive(true);
                } else {
                    setIsBackendAlive(false);
                }
            } catch (error) {
                setIsBackendAlive(false);
                console.error('check if backend is running:', error);
            }
        };

        checkBackendHealth();
    }, []);

    if (isBackendAlive === null) {
        // Optionnel : un loader pendant la vérification
        return  <LoaderWait />;
    }
    if (!isBackendAlive) {
        return (<ErrorConnexion />);
    }
    return (
        <AuthProvider>
                <SystemProvider>
                    <Router>
                        <FreshdeskWidgetProvider>
                         <Main/>
                        </FreshdeskWidgetProvider>
                    </Router>
                </SystemProvider>
        </AuthProvider>
    );
}

export default App;
