import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";


const CompetencesType = ({question, updateCompetence, sectionIndex, questionIndex, removeCompetence, addCompetence, updateLevel, addLevel, removeLevel}) => {


    return (

        <Box sx={{marginBottom: 2}}>
            <Typography variant="subtitle1" sx={{marginBottom: 1}}>
                Ligne - Compétences
            </Typography>

            {question.competences?.map((competence, competenceIndex) => (
                <Box key={competenceIndex} sx={{display: 'flex', alignItems: 'center', marginBottom: 1}}>
                    <TextField
                        label={`Compétence ${competenceIndex + 1}`}
                        fullWidth
                        variant='filled'
                        value={competence || ''}
                        onChange={(e) =>
                            updateCompetence(sectionIndex, questionIndex, competenceIndex, e.target.value)
                        }
                        sx={{marginBottom: 1}}
                    />
                    <IconButton
                        color="error"
                        onClick={() => removeCompetence(sectionIndex, questionIndex, competenceIndex)}
                        aria-label="supprimer la compétence"
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ))}

            <Button
                startIcon={<AddCircleIcon/>}
                onClick={() => addCompetence(sectionIndex, questionIndex)}
                sx={{marginBottom: 2}}
            >
                Ajouter une compétence xxx
            </Button>

            <Typography variant="subtitle1" sx={{marginBottom: 1, marginTop: 2}}>
                Colonne - Niveaux
            </Typography>

            {question.options?.map((level, levelIndex) => (
                <Box key={levelIndex} sx={{display: 'flex', alignItems: 'center', marginBottom: 1}}>
                    <TextField
                        label={`Niveau ${levelIndex + 1}`}
                        fullWidth
                        variant='standard'
                        value={level || ''}
                        onChange={(e) => updateLevel(sectionIndex, questionIndex, levelIndex, e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => removeLevel(sectionIndex, questionIndex, levelIndex)}
                                    aria-label="supprimer le niveau"
                                >
                                    <ClearIcon/>
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
            ))}

            <Button
                startIcon={<AddCircleIcon/>}
                onClick={() => addLevel(sectionIndex, questionIndex)}
                sx={{marginBottom: 2}}
            >
                Ajouter un niveau
            </Button>
        </Box>
    )

}
export default CompetencesType