import {Box, Button} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";


const EmailEditorAction = ({setSelectedTemplate, handleSave}) => {

    return (
        <Box mt={2} display="flex" justifyContent="flex-end" gap={'8px'}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setSelectedTemplate(null)}
                startIcon={<CancelIcon />}
                sx={{ textTransform: 'none' }}
            >
                Annuler
            </Button>
            <Button
                variant="contained"
                color="success"
                onClick={handleSave}
                startIcon={<SaveIcon />}
                sx={{ textTransform: 'none' }}
            >
                Enregistrer
            </Button>
        </Box>
    )
}

export default EmailEditorAction;
