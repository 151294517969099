import {Box, Typography} from "@mui/material";
import {formatDate} from "../../Utils/functionTransforms";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {LevelOptions} from "../../Utils/LevelOption";
import useTexts from "../../hooks/useTexte";

const FormationMangerItemInfo = ({formation,nbrStagiaire }) => {
    const level = LevelOptions.find((branch) => formation.level === branch.key)
    const {t} = useTexts();

    const statusIcons = {
        "open": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "selecting": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "selected": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "draft": {"icon": <CheckCircleIcon color="warning"/>, label: t("PROJET_FORMATION_LABEL")},

    };
    return (
        <>
            <Typography id='cep-title' variant="h5" component="div">{level.label}</Typography>
            <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "16px"}} variant="h6"
                        component="div">{formation.nom}</Typography>
            <div id='cep-date'>
                <div>du {formatDate(formation.start_date)}</div>
                <div>au {formatDate(formation.end_date)}</div>
            </div>

            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0'}}>
                {statusIcons[formation.status].icon}<Typography variant="body1"
                                                                sx={{marginLeft: 1}}>{statusIcons[formation.status].label}</Typography>
            </Box>
            {nbrStagiaire !== false && (
                <div id='cep-people-count'>
                    {t('NB_STAGIAIRES_IN_THIS_FORM', nbrStagiaire)}
                </div>
            )}
        </>
    );
};

export default FormationMangerItemInfo;
