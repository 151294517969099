import React from 'react';
import { CardMedia, Typography, Box, TextField, Divider } from '@mui/material';
import { branchOptions } from "../../Utils/BranchOption";
import { LevelOptions } from "../../Utils/LevelOption";
import { formatDate } from "../../Utils/functionTransforms";

const UserFormations = ({ f }) => {
    const branch = branchOptions.find((option) => f.section_id === option.id);
    const level = LevelOptions.find((option) => f.level === option.level && option.section.includes(branch.key));

    return (
        <Box sx={{ borderRadius: 2, p: 2, border: 1, borderColor: 'grey.300' }}>
            <Box display="flex" justifyContent="center" p={2}>
                <CardMedia
                    component="img"
                    sx={{ width: 64, height: 64, objectFit: 'contain' }}
                    image={branch.logo}
                    alt={branch.name}
                />
            </Box>
            <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {level.label}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Date : {formatDate(f.date_formation)}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        Avis : {f.Avis}
                    </Typography> */}
                <Divider sx={{ my: 1 }} />
                {f.Avis.length > 0 &&
                    <TextField label="" variant='standard' rows={4} value={f.Avis} fullWidth disabled id='formation-avis' multiline={f.Avis.length > 50 ? true : false} />
                }
            </Box>
        </Box>
    );
};

export default UserFormations;
