import React from 'react';
import {Link} from 'react-router-dom';
import logoAgse from './../../assets/logo_entete.png';
import logoAzimuth from './../../assets/logo/AZIMUTH.svg';
import {useSystem} from "../../Context/System";

function HeaderLogo() {
    const {currentYear} = useSystem();
    return (
        <div className="col-xs-3 col-md-4 hidden-xs header-gauche">
            <div className="header-element header-logo">
                <Link to={'/'} className='header-logo-link'>
                    <img
                        className="logo"
                        id='agse'
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/logo_entete.png';
                        }}
                        src={logoAgse}
                        alt="logoAgse"
                        style={{borderWidth: 0, width: 129, opacity: 1}}
                    />
                    <img
                        className="logo"
                        id='azimuth'
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/logo_entete.png';
                        }}
                        src={logoAzimuth}
                        alt="logo"
                        style={{borderWidth: 0, width: 42, height: 42, opacity: 1, borderRadius: '12px'}}
                    />
                </Link>
            </div>
            <div className="header-saison" id="headerSaison">
                <span>{currentYear && currentYear.name}</span>
            </div>
        </div>
    );
}

export default HeaderLogo;
