import React, { useState, useEffect } from 'react';
import {branchOptions} from "../Utils/BranchOption"
import {Button, Box, Grid} from '@mui/material';
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import withAdminRole from "../hoc/withAdminRole";
import RulesList from "../components/editor/RulesList";
import LoaderWait from "../components/LoaderWait";
function RulesPage() {
    const [rules, setRules] = useState([]);
    const [rulesConfig, setRulesConfig] = useState({});
    const [loading, setLoading] = useState(true);
    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        const fetchRulesAndConfig = async () => {
            setLoading(true);
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/rules`;
                let rulesData = await fetchWithToken(apiUrl);

                setRules(rulesData);

                const apiUrl2 = `${process.env.REACT_APP_API_URL}/api/rules/section`;
                let sectionRulesData = await fetchWithToken(apiUrl2);
                const initRulesConfig = branchOptions.reduce((acc, branch) => {
                    const sectionKey = `section-${branch.id}`;
                    acc[sectionKey] = {};
                    rulesData.forEach(rule => {
                        const ruleKey = `rule-${rule.id}`;
                        // Initialisez toutes les règles comme non activées et non dérogatoires par défaut
                        acc[sectionKey][ruleKey] = { isEnabled: false, canDerogate: false };
                    });
                    return acc;
                }, {});
                sectionRulesData.forEach(({ section_id, rules_id, is_derogatoire }) => {
                    const sectionKey = `section-${section_id}`;
                    const ruleKey = `rule-${rules_id}`;
                    if (initRulesConfig[sectionKey] && initRulesConfig[sectionKey][ruleKey]) {
                        initRulesConfig[sectionKey][ruleKey] = { isEnabled: true, canDerogate: !!is_derogatoire };
                    }
                });
                setRulesConfig(initRulesConfig);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
                setLoading(false);
            }

        };
        fetchRulesAndConfig();
    }, []);

    const handleRuleToggle = (sectionId, ruleId) => {
        setRulesConfig(prevConfig => ({
            ...prevConfig,
            [`section-${sectionId}`]: {
                ...prevConfig[`section-${sectionId}`],
                [`rule-${ruleId}`]: {
                    ...prevConfig[`section-${sectionId}`][`rule-${ruleId}`],
                    isEnabled: !prevConfig[`section-${sectionId}`][`rule-${ruleId}`].isEnabled,
                },
            },
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const apiUrl =`${process.env.REACT_APP_API_URL}/api/rules/section/save`;
            const body ={
                formData: (rulesConfig)
            }
            await fetchWithToken(apiUrl, "POST", body);

        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
            // Gérez l'erreur (par exemple, affichez un message d'erreur)
        }
    };

    if (loading) {
        return <LoaderWait />
    }

    return (
        <Box sx={{ mt: 4, mx: 2 }}>
            {/*<Typography variant="h4" gutterBottom>{t("TEXT_RULES_DISPO")}</Typography>*/}
            <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={3}>
                    {branchOptions.map((branch, branchIndex) => (
                        <RulesList rules={rules} key={branchIndex} branch={branch} rulesConfig={rulesConfig} branchIndex={branchIndex} handleRuleToggle={handleRuleToggle}/>
                    ))}
                </Grid>
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>Envoyer</Button>
            </Box>
        </Box>
    );
}
export default withAdminRole(RulesPage);