import { Box, Typography } from "@mui/material";
import { Check, CheckCircleOutlineRounded } from "@mui/icons-material";
import React from "react";


const WishBodyValidate = ({ setWishValidation, wish, setOpenValidation }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
            }}
        >
            <span
                style={{
                    color: 'green',
                    marginRight: '4px',
                    alignItems: 'center',
                    borderRadius: '5px',
                    display: 'flex',
                    gap: '2px'
                }}
            >
                <CheckCircleOutlineRounded color="success" /> Place attribuée
            </span>
            <button
                className="azimuth-btn success"
                onClick={() => {
                    setWishValidation(wish);
                    setOpenValidation(true);
                }}
            >
                <Check sx={{ fontSize: '16px' }} />
                <Typography variant={{xs: 'body2', sm: 'body1'}} sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                    Valider l'inscription
                </Typography>
            </button>
        </Box>

    )
}

export default WishBodyValidate
