import React from 'react';
import { Box, Container, Alert, Typography } from "@mui/material";
import Azimuthlogin from "../assets/logo/AZIMUTHlogin.webp";
import logoETN from "../assets/logo-etn.jpg";
import useTexts from "../hooks/useTexte";
import './styles/LoginPage.css';
import ListProvider from "../components/auth/ListProvider";
// import MagicLogin from "../MagicLogin";

const LoginPage = () => {
    const params = new URLSearchParams(window.location.search);
    const { t } = useTexts();
    const error = params.get('error');
    // const [displayEmail, setDisplayEmail] = useState(true);

    // const handleBack = () => {
    //     setDisplayEmail(false);
    // };

    return (
        <Container maxWidth="sm" className='loginPage-container'>
            <Box sx={{ textAlign: 'center' }} className='logo-and-providers'>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Azimuthlogin} alt="Azimuth Logo" id='azimuth-logo' />
                </Box>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {error === '_error_' ? t("ERREUR_TECHNIQUE") : t(error)}
                    </Alert>
                )}
                <Box sx={{ textAlign: 'center' }} className='providers'>
                    <ListProvider />
                    {/* {!displayEmail ? (
                    ) : (
                        <MagicLogin onBack={handleBack} />
                    )} */}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                <Typography variant="body2" color="textSecondary" sx={{ marginRight: 1 }}>
                    Réalisation ETN Numérique
                </Typography>
                <img src={logoETN} alt="Logo ETN" style={{ width: '54px' }} />
            </Box>
        </Container>
    );
};

export default LoginPage;
