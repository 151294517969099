import React from 'react';
import { TableBody } from '@mui/material';
import StagiareCardAdmin from "./StagiareCardAdmin";

const FormationAdminListUserTableBody = ({ users, filters, isAdministrator, wish, handleListUser }) => {
    const applyFilters = (user) => {
        const userStatus = user.status || 'WAIT';

        // Check if user status matches any of the selected statuses
        const isStatusSelected = Object.values(filters.status).some(value => value);
        const statusMatch = isStatusSelected ? Object.keys(filters.status).some(status => filters.status[status] && status === userStatus) : true;

        // Check if code Sycomore matches
        const codeSycomoreMatch = filters.codeSycomore ? user.user_code_sycomore.includes(filters.codeSycomore) : true;
        const selectedNameMatch = filters.selectedName ? user.user_lastname.toUpperCase().includes(filters.selectedName.toUpperCase()) : true;
        const hideMtach = filters.hideInWait ? user.other_inscription_wait_validation == "No" : true;
        return statusMatch && codeSycomoreMatch && hideMtach && selectedNameMatch;
    };

    return (
        <TableBody>
            {users && users.filter(applyFilters).map((user, index) => (
                <StagiareCardAdmin
                    key={index}
                    index={index}
                    user={user}
                    isAdministrator={isAdministrator}
                    refreshUser={handleListUser}
                    formation={wish}
                    displayDateStatus={true}
                />
            ))}
        </TableBody>
    );
};

export default FormationAdminListUserTableBody;
