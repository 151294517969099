import React from "react";
import {Route} from "react-router-dom";
import LoginPage from "../Page/LoginPage";
import OAuthCallback from "../Page/OAuthCallback";

const AuthRoutes = [
    <Route path="/login" element={<LoginPage />} key="login" />,
    <Route path="/auth/callback/:provider" element={<OAuthCallback />} key="oauth-callback" />,
    <Route path="/auth/:provider/callback" element={<OAuthCallback />} key="auth-callback" />
];

export default AuthRoutes;