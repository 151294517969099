import React from "react";
import { Box, Snackbar, Typography } from "@mui/material";
import useTexts from "../../hooks/useTexte";
import ChangeConfigWishDisplayFormModalAction from "./ChangeConfigWishDisplayFormModalAction";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const ChangeConfigWishDisplayForm = ({
    wish,
    realodWish,
    openChangeFunction,
    setOpenChangeFunction
}) => {
    const { t } = useTexts();
    const [snackbar, setSnackbar] = React.useState({ open: false, message: "", severity: "success" });

    return (
        <>
            <div className="wish-content-function">
                <div id="wish-content-item-left">
                    {wish.formations.length > 0 && (
                        <Typography>{t("TEXT_HAVE_FORMATION_SPECIFIQUE")}</Typography>
                    )}
                    {!wish.types.includes("no_preference") && (
                        <Typography>{t("TEXT_HAVE_SPECALISTE_SPECIFIQUE")}</Typography>
                    )}

                    {(wish.formations.length > 0 || !wish.types.includes("no_preference")) && (
                        <Typography>{t("TEXT_HAVE_FORMATION_WARNING_VERIRIFY")}</Typography>
                    )}
                </div>
                <ChangeConfigWishDisplayFormModalAction wish={wish} openChangeFunction={openChangeFunction} setOpenChangeFunction={setOpenChangeFunction} realodWish={realodWish} setSnackbar={setSnackbar} />
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={() => setSnackbar({ open: false, message: "" })}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    message={
                        <Box display={"inline-flex"} gap={1} alignItems={"center"}>
                            <CheckCircleRoundedIcon color="success" />
                            <Typography sx={{ p: 0, m: 0 }} variant="body2">{snackbar.message}</Typography>
                        </Box>
                    }
                />
            </div>
        </>
    );
};

export default ChangeConfigWishDisplayForm;
