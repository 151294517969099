import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import {useFetchWithToken} from '../hooks/useFetchWithToken';
import {useAuth} from '../Context/AuthContext';
import {branchOptions} from '../Utils/BranchOption';
import ErrorModal from "../components/Modal/ErrorModal";
import ValidateModal from "../components/Modal/ValidateModal";
import useTexts from "../hooks/useTexte";
import WishListTable from "../components/Wish/WishListTable";
import ConfirmModal from "../components/Modal/ConfirmModal";
import './styles/WishListPage.css';
import GenericFilter from "../components/Filter/GenericFilter";
import AddWishFormWrapper from "../components/Wish/AddWishFormWrapper";
import WishList from "../components/Wish/WishList";
import LoaderWait from "../components/LoaderWait";
import { Box, Typography } from '@mui/material';

const WishListPage = () => {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const [filters, setFilters] = useState({
        selectedType: "",
        selectedBranche: "",
        showClose: false,
        showOpen: true,
    });
    const {isLoadingFunction, section, currentFunction} = useAuth();
    const [modalOpenError, setModalOpenError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState("");
    const [modalOpenValidate, setModalOpenValidate] = useState(false);
    const [modalValidateMessage, setModalValidateMessage] = useState("");
    const [modalOpenValidateRemove, setModalOpenValidateRemove] = useState(false);
    const [modalOpenValidateRemoveId, setModalOpenValidateRemoveId] = useState(0);

    const handleCloseRemove = () => {
        setModalOpenValidateRemove(false);
        setModalOpenValidateRemoveId(0);
    };

    const handleCloseModalError = () => {
        setModalOpenError(false);
        setModalErrorMessage(null);
    };

    const handleCloseModalSucces = () => {
        setModalOpenValidate(false);
        setModalValidateMessage(null);
    };

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes`;
            const data = await fetchWithToken(apiUrl);
            setWishes(data);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {
        loadWishes();
    }, [currentFunction]);

    const onDeleteWishCallback = async () => {
        try {
            handleCloseRemove();
            const updatedWishes = wishes.filter(wish => wish.voeu_id !== modalOpenValidateRemoveId);
            setWishes(updatedWishes);
            await deleteWish(modalOpenValidateRemoveId);
            loadWishes();
        } catch (error) {
            console.error('Erreur lors de la suppression du vœu:', error);
        }
    };
    const deleteWish = async (voeuUserId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${voeuUserId}`;
            await fetchWithToken(apiUrl, "DELETE");
        } catch (error) {
            console.error('Erreur lors de la suppression du vœu utilisateur:', error);
        }
    };
    const [wishes, setWishes] = useState([]);

    if (!isLoadingFunction) {
        return <LoaderWait />;
    }
    const filteredWishes = wishes.filter(wish => {
        const matchesType = filters.selectedType ? wish.level === filters.selectedType  : true;
        const matchesBranche = filters.selectedBranche ? wish.branch === filters.selectedBranche  : true;
        let  matchesClose =   true;
        let  matchesOpen =   true;

        if (wish.close == 1 && filters.showClose == false){
            matchesClose = false;
        }
        if (wish.close == 0 && filters.showOpen == false){
            matchesOpen = false;
        }
        return matchesType && matchesBranche  && matchesClose && matchesOpen;
    });

    const filterbranchOptions = branchOptions.filter(branchOption => {
        return branchOption.section == section || branchOption.section == "mixte";
    });

    return (
        <Container maxWidth="xl">
            <AppBar position="static" color="default" sx={{ top: 10, boxShadow: "none", background: "none" }}>
              <AddWishFormWrapper loadWishes={loadWishes} filterbranchOptions={filterbranchOptions} handleCloseRemove={handleCloseRemove} setModalErrorMessage={setModalErrorMessage} setModalOpenError={setModalOpenError} />
            </AppBar>
            <GenericFilter filters={filters} page={0} setFilters={setFilters} filterbranchOptions={filterbranchOptions}/>
            <WishList loadWishes={loadWishes} filteredWishes={filteredWishes} setModalOpenValidateRemoveId={setModalOpenValidateRemoveId} setModalOpenValidateRemove={setModalOpenValidateRemove} />
            <ErrorModal open={modalOpenError} error={modalErrorMessage} onClose={handleCloseModalError}/>
            <ValidateModal open={modalOpenValidate} message={modalValidateMessage} onClose={handleCloseModalSucces}/>
            <ConfirmModal open={modalOpenValidateRemove} message={
                <Box display={'flex'} gap={1} flexDirection={'column'}>
                    <Typography variant='h4' color='error' gutterBottom>{t("CONFIRM_REMOVE_WISH")}</Typography>
                    <WishListTable wish={filteredWishes.find(f => f.voeu_id == modalOpenValidateRemoveId)} type={"delete"} />
                </Box>
            } onClose={handleCloseRemove} onConfirm={onDeleteWishCallback}/>
        </Container>
    )
};

export default WishListPage;
