import React from 'react';
import { CardMedia } from '@mui/material';
import './styles/StagiaireCard.css';
import AvatarPlaceholder from "../AvatarPlaceholder";

const StagiareCardMedia = ({ imageUrl }) => {
    const commonStyles = {
        width: '56px',
        height: '56px',
        zIndex: 1,
        position: 'relative',
        borderRadius: '50%',
        objectFit: 'cover',
        padding: '8px 16px',
    };

    return imageUrl ? (
        <CardMedia
            sx={commonStyles}
            component="img"
            image={imageUrl || ""}
            alt="photo"
        />
    ) : (
        <AvatarPlaceholder />
    );
};

export default StagiareCardMedia;
