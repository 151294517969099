import React from 'react';
import { Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import useTexts from "../../hooks/useTexte";

const FeedbackFormHead = ({ feedback, handleChange, editable, checkboxState, handleCheckboxChange, structure }) => {
    const { t } = useTexts();
    return (
        <>
            <Typography variant="h6" gutterBottom>
                {t("MY_AVIS_IN_CHEF")}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t("AVIS_DEFAULT")}
            </Typography>
            <TextField
                fullWidth
                label={t("TEXT_LABEL_AVIS")}
                multiline
                rows={2}
                variant="outlined"
                value={feedback}
                onChange={handleChange}
                margin="normal"
                disabled={!editable}
            />
            {editable && ( structure?.diff === 2 || structure?.diff ===  "peda" ) && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxState.idontknow}
                            onChange={handleCheckboxChange("idontknow")}
                            color="primary"
                            disabled={!editable}
                        />
                    }
                    label={t("I_DONT_KNOW_CHEF")}
                />
            )}
            {editable && structure?.diff === 1 && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxState.validateByAnticipation}
                            onChange={handleCheckboxChange("validateByAnticipation")}
                            color="primary"
                            disabled={!editable}
                        />
                    }
                    label={t("I_VALIDE_ANTICIPATION")}
                />
            )}
        </>
    );
};

export default FeedbackFormHead;
