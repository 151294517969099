import React from 'react';
import './styles/WishItem.css';
import WishItemStatusWishDisplayWaitHasValidate from "./WishItemStatusWishDisplayWaitHasValidate";
import WishItemStatusWishDisplayWaitHasToValidate from "./WishItemStatusWishDisplayWaitHasToValidate";
import WishItemStatusWishDisplayWaitHead from "./WishItemStatusWishDisplayWaitHead";

const WishItemStatusWishDisplayWait = ({ formation, formationData, isAdmin, handleClickValidate }) => {
    let userHasValide = false;
    let chefHasValide = false;

    formationData.map((formation) => {
        if (formation.statusValidation) {
            if (formation.statusValidation.find(f => f.role === 1)) {
                userHasValide = true;
            }
            if (formation.statusValidation.find(f => f.role === 2)) {
                chefHasValide = true;
            }
        }
    });

    return (
        <div>
            <WishItemStatusWishDisplayWaitHead formation={formation} />
            {userHasValide ? (
                <WishItemStatusWishDisplayWaitHasValidate label='CHEF_HAS_VALIDATE' />
            ) : (
                <WishItemStatusWishDisplayWaitHasToValidate
                    handleClickValidate={handleClickValidate}
                    isAdmin={isAdmin}
                    level={1}
                    label='CHEF_HAS_TO_VALIDATE'
                />
            )}
            {chefHasValide ? (
                <WishItemStatusWishDisplayWaitHasValidate label='STAGIAIRE_HAS_VALIDATE' />
            ) : (
                <WishItemStatusWishDisplayWaitHasToValidate
                    handleClickValidate={handleClickValidate}
                    isAdmin={isAdmin}
                    level={2}
                    label='STAGIAIRE_HAS_TO_VALIDATE'
                />
            )}
        </div>
    );
};

export default WishItemStatusWishDisplayWait;
