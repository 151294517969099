import React from "react";
import { Box, Typography } from "@mui/material";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import AdminDatesMonthes from "../components/AdminDatesMonth";
import { useSystem } from "../Context/System";

const AdminDatesPage = () => {
    const { currentYear } = useSystem();

    const scoutYearStart = new Date(currentYear.start);
    const scoutYearEnd = new Date(currentYear.end);

    const generateMonths = () => {
        const months = [];
        let currentDate = new Date(scoutYearStart);

        while (currentDate <= scoutYearEnd) {
            const daysInMonth = [];
            const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            for (let date = new Date(startOfMonth); date <= endOfMonth; date.setDate(date.getDate() + 1)) {
                daysInMonth.push(new Date(date));
            }

            months.push({
                month: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
                days: daysInMonth,
            });

            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return months;
    };

    const months = generateMonths();

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Gestion des dates ouvertes (année scoute : {currentYear.name})
            </Typography>
            {months.map((month, index) => (
                <AdminDatesMonthes key={index} monthData={month} />
            ))}
        </Box>
    );
};

export default withAdminOrFormationRole(AdminDatesPage);
