import React, { useState } from "react";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import PreviewModal from "./PreviewModal";
import PreviewIcon from '@mui/icons-material/Preview';
import { IconButton } from "@mui/material";

const MyFormsPreview = ({ selectedFormId }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [sections, setSections] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const loadFormSections = async (id) => {
        try {

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/template/${id}`;
            const data = await fetchWithToken(apiUrl);
            const newForm = data.data.map((section) => ({
                ...section,
                fields: section.fields || [],
            }));
            setSections(newForm);
            setPreviewOpen(true)
        } catch (error) {
            console.error('Erreur lors du chargement des sections:', error);
        } finally {
            // collapse les sections par défaut
            setSections((prevSections) => prevSections.map((section) => ({ ...section, expanded: false })));
        }
    };

    const closePreview = () => setPreviewOpen(false);

    return (
        <>
            <IconButton
                onClick={() => loadFormSections(selectedFormId)}
                color="primary"
            >
                <PreviewIcon />
            </IconButton>
            <PreviewModal
                open={previewOpen}
                onClose={closePreview}
                sections={sections}
            />
        </>
    );
}

export default MyFormsPreview;