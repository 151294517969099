import React from 'react';
import {TextField,  } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './styles/Search.css';

const SearchModalInput = ({label , value, callBack }) => {
   return (
       <TextField
           label={label}
           value={value}
           onChange={(e) => callBack(e.target.value)}
           placeholder={label}
           fullWidth
           variant="outlined"
           margin="normal"
           InputProps={{
               startAdornment: (
                   <SearchIcon position="start" />
               ),
           }}
       />
   )
};

export default SearchModalInput;
