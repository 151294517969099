import React, {useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,} from '@mui/material';

import useTexts from "../../hooks/useTexte";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import SearchModal from "../Search/SearchModal";
import {useConfirm} from "../../Provider/ConfirmProvider";


const FormationMangerAdminListUserAdd = ({wish, handleListUser}) => {
    const fetchWithToken = useFetchWithToken();
    const {requestConfirm} = useConfirm();
    const [addOption, setAddOption] = React.useState('withoutValidate');

    const [searchOpen, setSearchOpen] = useState(false);

    const handleAddOptionChange = (event) => {
        setAddOption(event.target.value);
    };
    const handleAddTrainee = (newuser, function_id,) => {
        // Handle add trainee logic
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE"), async () => {
            try {

                var data = {
                    type: addOption
                }
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${wish.id}/${newuser.id}/${function_id}/force`;
                await fetchWithToken(apiUrl, "POST", data);
                handleListUser();
                setSearchOpen(false)
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };


    const {t} = useTexts();

    return (
        <>
            <Button
                className='azimuth-btn full-width'
                onClick={() => setSearchOpen(true)}
            >
                {t("BTN_ADD_STAGIARE")}
            </Button>
            <Dialog open={searchOpen} onClose={() => setSearchOpen(false)} aria-labelledby="search-dialog-title">
                <DialogTitle id="search-dialog-title">{t("SEARCH")}</DialogTitle>

                <DialogContent>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('OPTION')}</FormLabel>
                        <RadioGroup
                            aria-label="add-option"
                            name="add-option"
                            value={addOption}
                            onChange={handleAddOptionChange}
                        >
                            <FormControlLabel
                                value="withoutValidate"
                                control={<Radio/>}
                                label={t('AJOUTER_SANS_VALIDER')}
                            />
                            <FormControlLabel
                                value="withValidate"
                                control={<Radio/>}
                                label={t('AJOUTER_ET_VALIDER')}
                            />
                        </RadioGroup>
                    </FormControl>
                    <SearchModal onClick={handleAddTrainee} functionMode={1}/>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default FormationMangerAdminListUserAdd;
