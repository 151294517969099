import React,  { useState } from 'react'
import HeaderLogo from '../HeaderLogo'
import HeaderProfile from '../HeaderProfile'
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Menu } from '@mui/material';

function MenuBurger({ menuItems, renderMenuItemsMobile }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }
    return (
        <div className='navbar-burger-container'>
            <Button
                id='basic-button'
                aria-controls='basic-menu'
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className='menu-burger'
            >
                {renderMenuItemsMobile(menuItems, handleClose)}
            </Menu>
        </div>
    )
}

export default function NavbarMobile({ menuItems, renderMenuItemsMobile}) {
    return (
        <div className="mobile-version">
            <MenuBurger menuItems={menuItems} renderMenuItemsMobile={renderMenuItemsMobile}/>
            <HeaderLogo/>
            <HeaderProfile/>
        </div>
    )
}
