import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './styles/PreviewModal.css';
import PreviewModalItem from "./PreviewModalItem";

const PreviewModal = ({open, onClose, sections = []}) => {

    if (sections.length < 1) {
        return null;
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box className="preview-modal"
                 sx={{padding: 4, backgroundColor: 'white', maxWidth: '80%', margin: 'auto', marginTop: '5%'}}>
                <Typography variant="h5" gutterBottom>
                    Aperçu du formulaire
                </Typography>
                <form className="preview-form">
                    {sections.map((section, sectionIndex) => (
                        <Box key={sectionIndex} className="section-preview" sx={{marginBottom: 4}}>
                            <Typography variant="h6" sx={{marginBottom: 2}}>
                                {section?.title || `Section ${sectionIndex + 1}`}
                            </Typography>
                            {section.fields?.map((question, questionIndex) => (
                               <PreviewModalItem question={question} key={questionIndex} questionIndex={questionIndex}/>
                            ))}
                        </Box>
                    ))}
                </form>
                <Button onClick={onClose} variant="contained" color="primary" sx={{mt: 3}}>
                    Fermer
                </Button>
            </Box>
        </Modal>
    );
};

export default PreviewModal;
