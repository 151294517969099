import {TableBody, TableCell, TableRow} from "@mui/material";
import React from "react";
import ForeignPageEditor from "./ForeignPageEditor";
import useTexts from "../hooks/useTexte";

const ForeignTableBody =({etrangers, handleDeleteEtranger, getEtranger}) => {
    const { t } = useTexts();

    return (
        <TableBody>
            {etrangers.map(p => (
                <TableRow key={p.id}>
                    <TableCell>{p.code_sycomore}</TableCell>
                    <TableCell align='left'>{p.lastname}</TableCell>
                    <TableCell align='left'>{p.firstname}</TableCell>
                    <TableCell align='left'>{p.email}</TableCell>
                    <TableCell align='left'>{p.phone}</TableCell>
                    <TableCell>
                        <div className='actions-button'>

                            <ForeignPageEditor  personne={p} getEtranger={getEtranger}/>

                            <button
                                onClick={() => handleDeleteEtranger(p.id)}
                                className='azimuth-btn'
                            >
                                {t("BUTTON_DELETE")}
                            </button>
                        </div>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}
export default ForeignTableBody