import {Checkbox, FormControlLabel} from "@mui/material";
import React from "react";

const  AzCheckbox = ({formState,sectionIndex , field, handleCheckboxChange})=>{
    const res = formState[`${sectionIndex}_${field.name}`];
    return (
        field.options.map((option, key) => (
            <FormControlLabel                 key={key}
                className="form-element"
                sx={{ mt: 3 }}
                control={
                    <Checkbox
                        checked={res && res.indexOf(option) !== -1}
                        onChange={e => handleCheckboxChange(sectionIndex, field.name, option, e.target.checked, true)}
                    />
                }
                label={option}
            />
        ))
    );
}

export default  AzCheckbox
