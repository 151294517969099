import React, { useEffect, useState } from 'react';
import {Typography} from '@mui/material';
import { branchOptions } from '../../Utils/BranchOption';
import { LevelOptions } from '../../Utils/LevelOption';
import { calculateEndDate, formatDate } from '../../Utils/functionTransforms';
import './styles/WishItem.css';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import WishItemStatusWish from "./WishItemStatusWish";
import WishItemStatusFormation from "./WishItemStatusFormation";

const WishItem = ({ wish, user, type , isAdmin}) => {
    const level = LevelOptions.find((branch) => wish.level === branch.key);
    const branch = branchOptions.find((branch) => wish.branch === branch.key);
    const [formationData, setFormationData] = useState([]);
    const [formation, setFormation] = useState(0);
    const [isRequireValidate, setIsRequireValidate] = useState(false);
    const fetchWithToken = useFetchWithToken();



    const loadWishesData = async () => {
        try {
            const body = {
                "parentSearch": true,
                "user_id_search": user.user_id,
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/data`;
            const data = await fetchWithToken(apiUrl, "POST", body);
            setFormationData(data.formations);
            data.formations.map((formation) => {
                if (formation.statusInscription) {
                    if (formation.statusInscription.status === 'WAIT_VALIDATION') {
                        setIsRequireValidate(true);
                        setFormation(formation);
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (type == "voeu") {
            loadWishesData();
        }
    }, []);
    return (
        <div className="wish-item-container">
            <div className='formation-detail'>
                <img src={branch.logo} alt="Branch logo" loading='lazy' />
                <Typography variant="h6">{level.label}</Typography>
            </div>
            <div id='date-from-to'>
                <Typography variant='body2'>du <span id='chip'>{formatDate(wish.date)}</span></Typography>
                <Typography variant='body2'>au <span id='chip'>{calculateEndDate(wish.date, branch.key, level.key)}</span></Typography>
            </div>
            <div className='wish-item-validation'>
                {type == "voeu" ? (
                    <WishItemStatusWish wish={wish} formation={formation} formationData={formationData} isRequireValidate={isRequireValidate} isAdmin={isAdmin} loadWishesData={loadWishesData} />
                ) : (
                    <WishItemStatusFormation wish={wish} user={user} />
                )}
            </div>
        </div>
    );
};

export default WishItem;
