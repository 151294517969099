import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import './styles/Forms.css';
import useTexts from "../hooks/useTexte";
import { useConfirm } from "../Provider/ConfirmProvider";
import MyFormsTemplate from "../components/customFormBuilder/MyFormsTemplate";
import MyFormsDuplicator from "../components/customFormBuilder/MyFormsDuplicator";
import MyFormsLibrary from "../components/customFormBuilder/MyFormsLibrary";
import LoaderWait from "../components/LoaderWait";
import { Typography } from '@mui/material';

const MyForms = () => {
    const fetchWithToken = useFetchWithToken();
    const { requestConfirm } = useConfirm();
    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTexts();

    const loadForms = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myform`;
            const data = await fetchWithToken(apiUrl);
            setForms(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading forms:', error);
        }
    }, [fetchWithToken]);

    useEffect(() => {
        loadForms();
    }, []);

    const handleDeleFom = (id) => {
        requestConfirm(t("DELETE_MY_FORM_WARNIBF"), async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/${id}/delete`;
            await fetchWithToken(apiUrl, "POST",);
            loadForms();
        });
    };

    if (isLoading) {
        return (<LoaderWait />);
    }

    return (
        <Container maxWidth="md">
            <Grid spacing={2}>
                <h1>{t("MY_TEMPLATES")}</h1>
                <div className='formList'>
                    {forms.length > 0 ? (
                        forms.map((form, index) => (
                            <div key={index} className='formDetails'>
                                <span id='formName'>{form.name}</span>
                                <Box className='button-box'>
                                    <MyFormsDuplicator loadForms={loadForms} type="my" selectedFormId={form.id} />
                                    <MyFormsTemplate loadForms={loadForms} existFormId={form.id} />
                                    <Button variant="contained" color="error" onClick={() => handleDeleFom(form.id)}>{t("DELETE_MY_FORM")}</Button>
                                </Box>
                            </div>
                        ))
                    ) : (
                        <Typography variant='body1' sx={{ textAlign:'center' }}>{t("NO_FORMS")}</Typography>
                    )}
                </div>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                    <MyFormsTemplate loadForms={loadForms} />
                    <MyFormsLibrary loadForms={loadForms} />
                </Box>
            </Grid>
        </Container>
    );
};

export default MyForms;
