import { Box } from "@mui/material";
import FormationAdminItemTable from "./FormationAdminItemTable";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import LoaderWait from "../LoaderWait";

const FormationAdminListBody = ({ addFormation, loadWishes, formations, noadmin, reloadFormations }) => {
    const { functionOptions } = useAuth();
    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchWithToken = useFetchWithToken();

    const fetchQuestionnaires = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/form`;
        const data = await fetchWithToken(apiUrl);

        setQuestionnaires(data);
        setLoading(false);
    };

    useEffect(() => {
        if (noadmin != 1) {
            fetchQuestionnaires();
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <LoaderWait />;
    }

    return (
        <Box className='cep-container'>
            {formations.map((formation, index) => (
                <FormationAdminItemTable
                    key={index}
                    formation={formation}
                    noadmin={noadmin}
                    index={index}
                    questionnaires={questionnaires}
                    functionOptions={functionOptions}
                    addFormation={addFormation}
                    loadWishes={loadWishes}
                    reloadFormations={reloadFormations}
                />
            ))}
        </Box>
    );
};

export default FormationAdminListBody;