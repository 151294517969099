import React from 'react';
import {Typography} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import './styles/WishItem.css';



const WishItemStatusWishDisplayWaitHead = ({formation}) => {
    const {t} = useTexts();

    return (<>

            <Typography
                sx={{fontFamily: "ubuntu", fontWeight: "bold"}}
                variant="body1"
                className=""
            >
                {formation.nom} <span
                style={{
                    color: 'orange',
                    margin: "5px"
                }}
            >{t("PLACE_HAS_PROPOSE")}</span>

            </Typography>

            <Typography
                sx={{
                    display: {xs: 'block', sm: 'flex'},
                    fontFamily: "ubuntu",
                    alignItems: 'center',
                    gap: 1
                }}
            >

            </Typography>

            <Typography
                sx={{fontFamily: "ubuntu", marginTop: 1}}
                variant="body2"
                className=""
            >
                Lieu de formation : {formation.lieu}
            </Typography>
        </>
    )

};

export default WishItemStatusWishDisplayWaitHead;
