import {Button, TableBody, TableCell, TableRow} from "@mui/material";
import React from "react";

const ConfigListBody= ({configs, handleDialogOpen}) => {
    return (
        <TableBody>
            {configs.map(config => (
                <TableRow key={config.id}>
                    <TableCell align='left'>{config.description}</TableCell>
                    <TableCell align='left'>{config.value}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDialogOpen(config)}
                        >
                            Edit
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}

export default ConfigListBody;