import {Button, Dialog, DialogContent, TextField, Typography} from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";

const ForeignPageEditorModal = ({dialogOpen, setDialogOpen, personne , formValues, setFormValues, handleSave})=>
{
    const {t} = useTexts();

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    return (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogContent>
                <Typography variant="h6" gutterBottom>

                    {!personne ? (
                        t("ADD_NEW_ETRANGER")
                    ) : (
                        t("EDIT_ETRANGER")
                    )}


                </Typography>

                {!personne && (
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                )}
                <TextField
                    fullWidth
                    label="Nom"
                    name="nom"
                    value={formValues.nom}
                    onChange={handleInputChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Prénom"
                    name="prenom"
                    value={formValues.prenom}
                    onChange={handleInputChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Téléphone"
                    name="telephone"
                    value={formValues.telephone}
                    onChange={handleInputChange}
                    margin="normal"
                />
                <div style={{marginTop: '16px', textAlign: 'right'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        style={{marginRight: '8px'}}
                    >
                        {t("BUTTON_SAVE")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setDialogOpen(false)}
                    >
                        {t("BUTTON_CANCEL")}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default ForeignPageEditorModal