import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid2 as Grid } from "@mui/material";
import React, { useState } from "react";

const UserlogFilter = ({ setFilter }) => {
    const [filterDateFrom, setFilterDateFrom] = useState("");
    const [filterDateTo, setFilterDateTo] = useState("");
    const [filterEventType, setFilterEventType] = useState("");

    const applyFilter = () => {
        // Mettre à jour les filtres dans le parent
        setFilter({
            dateFrom: filterDateFrom || null,
            dateTo: filterDateTo || null,
            eventType: filterEventType || null,
        });
        // Charger les logs avec les nouveaux filtres

    };
    return (
        <Box id="test" sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                <TextField
                        type="date"
                        label="Date début"
                        value={filterDateFrom}
                        onChange={(e) => setFilterDateFrom(e.target.value)}
                        slotProps={{ inputLabel: { shrink: true } }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        type="date"
                        label="Date fin"
                        value={filterDateTo}
                        onChange={(e) => setFilterDateTo(e.target.value)}
                        fullWidth
                        slotProps={{ inputLabel: { shrink: true } }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl variant="outlined" sx={{ width: "max-content" }}>
                        <InputLabel id="user-log-filter" htmlFor="user-log-filter">Type d'événement</InputLabel>
                        <Select
                            labelId="user-log-filter"
                            id="user-log-filter"
                            value={filterEventType}
                            onChange={(e) => setFilterEventType(e.target.value)}
                            label="Type d'événement"
                            sx={{ width: "200px" }}
                        >
                            <MenuItem value=""><em>Tous</em></MenuItem>
                            <MenuItem value={"sendEmail"}>Email envoyé</MenuItem>
                            <MenuItem value={"validateFormation"}>Formation validée</MenuItem>
                            <MenuItem value={"RefuseFormation"}>Formation refusée</MenuItem>
                            <MenuItem value={"AddWish"}>Ajout de voeu</MenuItem>
                            <MenuItem value={"removeWish"}>Suppression de voeu</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button className="azimuth-btn" onClick={() => applyFilter()} fullWidth>
                        Appliquer les filtres
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UserlogFilter
