// AuthContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import { getUserAgent } from '../Utils/UserAgent';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [functionOptions, setFunctionOptions] = useState([]);
    const [isLoadingFunction, setIsLoadingFunction] = useState(false);
    const [section, setSection] = useState('');
    const [currentFunction, setCurrentFunction] = useState(localStorage.getItem('currentfonction'));
    const [childUnite, setChildUnite] = useState();
    const [authErrorNoFunction, setAuthErrorNoFunction] = useState(false);
    const [runAsStart, setRunasstart] = useState(false);
    const [runAsId, setRunasstartId] = useState(false);
    const [isRunAsFrom, setIsRunAsFrom] = useState(false);
    const login = (newToken) => {
        const body = getUserAgent();
        console.log('USER AGENT', getUserAgent());
        fetch(`${process.env.REACT_APP_API_URL}/api/saveuseragent`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${newToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })



        localStorage.setItem('currentfonction', false);
        localStorage.setItem('token', newToken);
        setToken(newToken);
        updateSectionFromToken(newToken);
        setAuthErrorNoFunction(false);

    };

    const updateSectionFromToken = (token) => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const sectionFromToken = decodedToken.section;
                const runUserfrom = decodedToken.runUserfrom;
                localStorage.setItem('user', JSON.stringify(decodedToken));
                setSection(sectionFromToken);
                setIsRunAsFrom(runUserfrom)
            } catch (error) {
                console.error("Erreur lors du décodage du token:", error);
                setSection('');
            }
        } else {
            setSection('');
        }
    };

    const loadChildStructure = () => {
        const body = {
            function_id: currentFunction,
        };

        fetch(`${process.env.REACT_APP_API_URL}/api/chilsstruct`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response => response.json())
            .then(data => {
                setChildUnite(data);
            })
            .catch(error => console.error("Erreur lors de la récupération des fonctions:", error));
    };

    const setcurrentFUnctionHanlder = (id) => {
        localStorage.setItem('currentfonction', id);
        setCurrentFunction(id);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('token2');
        localStorage.removeItem('currentfonction');
        localStorage.removeItem('currentfonction2');
        setToken(null);
        setCurrentFunction(null);
        setAuthErrorNoFunction(false);


    };
    const Runas = async (targetUserId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/switch-user`;
            if (targetUserId) {
                // Appeler l'API avec le token courant, en passant l'ID de l'utilisateur cible
                const chefFunction = functionOptions.find(func => func.id == currentFunction);

                const body = {
                    function_id: chefFunction.id,
                    targetUserId: targetUserId.id, // Remplacez par l'ID de l'utilisateur cible
                }
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
                    .then(response => response.json())
                    .then(data => {
                        const newToken = data.token;
                        localStorage.setItem('currentfonction2', currentFunction);
                        localStorage.setItem('token2', token);
                        localStorage.setItem('currentfonction', false);
                        localStorage.setItem('token', newToken);
                        initConnexion(newToken);
                        setToken(newToken)

                    })
                    .catch(error => console.error("Erreur lors de la récupération des fonctions:", error));



            }


        } catch (error) {
            console.error('Erreur lors du changement d\'utilisateur:', error);
        }
    };
    const Runasend = async (callBack) => {


        const newToken = localStorage.getItem('token2');
        const currentfonction = localStorage.getItem('currentfonction2');
        setCurrentFunction(currentfonction)
        localStorage.setItem('currentfonction2', false);
        localStorage.setItem('token2', false);
        localStorage.setItem('currentfonction', currentfonction);
        localStorage.setItem('token', newToken);
        initConnexion(newToken);
        setToken(newToken)
        if (callBack){
            callBack()
        }
    };

    useEffect(() => {
        if (runAsStart && runAsId) {
            Runas(runAsId)
        }
    }, [runAsStart, runAsId]);
    const handeRunas = async (targetUserId, setDialogOpenSearch, callback) => {
        setRunasstart(true)
        setRunasstartId(targetUserId)
        setDialogOpenSearch(false)
        if (callback) {
            callback();
        }
    };
    const getFunctionNames = () => {
        return functionOptions.result.map(fonction => fonction.nom);
    };
    const handeRunasEnd = (callBack) => {
        setRunasstart(true)
        Runasend(callBack);
    };

    const verifyToken = (token2) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/verifToken`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token2}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Token invalid');
                }
                return true
            });
    };

    function initConnexion(token) {
        verifyToken(token)
            .then(() => {
                updateSectionFromToken(token);
                fetch(`${process.env.REACT_APP_API_URL}/api/functions`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.message && data.message === "aucune fonction") {
                            setAuthErrorNoFunction(true);
                        } else {
                            if (!currentFunction || runAsStart) {
                                setcurrentFUnctionHanlder(data[0].id);
                            }
                            setFunctionOptions(data);
                            setIsLoadingFunction(true);
                            setRunasstartId(false)
                            setRunasstart(false)
                        }
                    })
                    .catch(() => {
                        setAuthErrorNoFunction(true);
                    });
            })
            .catch(() => {
                // If token verification fails, remove token from localStorage and reset state
                localStorage.removeItem('token');
                localStorage.removeItem('currentfonction');
                setToken(null);
                setCurrentFunction(null);
            });
    }

    useEffect(() => {
        if (token && !runAsStart) {
            initConnexion(token);
        }
    }, [token]);

    useEffect(() => {
        if (currentFunction) {
            loadChildStructure();
        }
    }, [currentFunction]);

    return (
        <AuthContext.Provider value={{
            token, setToken, isRunAsFrom,
            login,
            logout,
            functionOptions,
            getFunctionNames,
            isLoadingFunction,
            section,
            currentFunction, runAsStart,
            childUnite, handeRunas,
            setcurrentFUnctionHanlder, handeRunasEnd,
            authErrorNoFunction, updateSectionFromToken, initConnexion
        }}>
            {children}
        </AuthContext.Provider>
    );
};
