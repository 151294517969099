import {Box, Card, CardContent, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";

const RulesList  = ({branch ,branchIndex,  rulesConfig, rules, handleRuleToggle}) => {
    const { t } = useTexts();

    return (
        <Grid item xs={12} md={6} lg={4} key={branch.id}>
            <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" color="primary">{branch.label}</Typography>
                    {rules.map((rule, ruleIndex) => {
                        const sectionConfig = rulesConfig[`section-${branch.id}`];
                        const ruleConfig = sectionConfig && sectionConfig[`rule-${rule.id}`];
                        return (
                            <Box key={rule.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <Typography sx={{ flexGrow: 1 }}>
                                    {rule.name}
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox checked={ruleConfig?.isEnabled} onChange={(e) => handleRuleToggle(branch.id, rule.id, e, branchIndex, ruleIndex)} />}
                                    label={t("TEXT_ACTIVER")}
                                />

                            </Box>
                        );
                    })}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default  RulesList;