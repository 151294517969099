import {Box, List, ListItem, ListItemText, Typography,} from "@mui/material";
import React from "react";

import useTexts from "../../hooks/useTexte";

const ChangeConfigWishDisplayRemove = ({
                              wish,

                          }) => {
    const {t} = useTexts();



    return (
            <>

                    <Box>
                        {wish.formations && wish.formations.length > 0 ? (
                            <>
                                <Typography variant="h6" sx={{marginBottom: 2}}>
                                    {t("FORMATIONS_LIST")}
                                </Typography>
                                <List>
                                    {wish.formations.map((formation, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={formation.nom}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        ) : (
                            <Typography variant="body1">{t("NO_FORMATIONS_SPECFIQUE")}</Typography>
                        )}

                        {!wish.types.includes("no_preference") ? (
                            <>
                                <Typography variant="h6" sx={{marginBottom: 2}}>
                                    {t("SCPEALISTE_LIST")}
                                </Typography>
                                <List>
                                    {wish.types.map((type, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={type}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        ) : (
                            <Typography variant="body1">{t("NO_SPECALISTE_SPECFIQUE")}</Typography>
                        )}
                    </Box>
            </>
    );
};

export default ChangeConfigWishDisplayRemove;
