import React from 'react';
import { Typography } from "@mui/material";
import { LevelOptions } from "../Utils/LevelOption";
import { branchOptions } from "../Utils/BranchOption";
import { formatDate } from "../Utils/functionTransforms";

const AgendaItem = ({ formation, filters }) => {
    const level = LevelOptions.find((branch) => formation.level === branch.key);
    const branch = branchOptions.find((branch) => formation.code === branch.key);

    return (
        <React.Fragment>
            {branch && (branch.key === filters.selectedBranche || !filters.selectedBranche) && (
                <div className='agenda-event-item'>
                    <div className='item-infos'>
                        {branch && branch.logo && (
                            <img src={branch.logo} alt="Training" style={{ width: '50px', height: '50px' }} />
                        )}
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                            {level.label} - {branch.label}
                        </Typography>
                    </div>
                    <Typography variant="body2" sx={{ flexGrow: 1, marginRight: '8px' }}>
                        {formation.lieu}
                    </Typography>
                    <div id='date-from-to'>
                        <Typography variant='body2'>Du <span id='chip'>{formatDate(formation.start_date)}</span></Typography>
                        <Typography variant='body2'>au <span id='chip'>{formatDate(formation.end_date)}</span></Typography>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default AgendaItem;
