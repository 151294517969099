import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const client = createClient({
    projectId: '2t6te9dv',
    dataset: 'production',
    useCdn: true,
    apiVersion: '2025-02-27',
})

const builder = imageUrlBuilder(client)

export function urlFor(source) {
    return builder.image(source)
}

export default client
