import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React, {useState} from "react";
import AddWishForm from "./AddWishForm";
import {LevelOptions} from "../../Utils/LevelOption";
import useTexts from "../../hooks/useTexte";
import {useConfirm} from "../../Provider/ConfirmProvider";
import {useAuth} from "../../Context/AuthContext";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";

const AddWishFormWrapper = ({loadWishes, setModalErrorMessage,setModalOpenError, handleCloseRemove, filterbranchOptions }) => {
    const {t} = useTexts();
    const fetchWithToken = useFetchWithToken();

    const [open, setOpen] = useState(false);
    const {requestConfirm} = useConfirm();
    const { functionOptions, currentFunction} = useAuth();
    const createWishCall = async (wish) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishe`;
            await fetchWithToken(apiUrl, 'POST', (wish));
            handleCloseRemove();
            loadWishes();
            setOpen(false);
        } catch (error) {
            const response = JSON.parse(error.message);
            setModalErrorMessage(response.status);
            setModalOpenError(true);
            console.error('Erreur lors de la suppression du vœu utilisateur:', error);
        }

    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addWish = async (wish) => {
        await addWishToAPI(wish);
    };
    const addWishToAPI = async (wish) => {
        if (wish) {
            try {
                const branchNeedValidationForGroup = ["louvetisme", "eclaireur", "eclaireuse"]
                const branchNeedVerifFunctioTern = ["territorial"]
                const FunctionNeedVerifFunctionTer = ["COM", "COMAD", "CH", "CADJ"]
                const chefFunction = functionOptions.find(func => func.id == currentFunction);
                if (chefFunction.type == "Groupe" && branchNeedValidationForGroup.includes(wish.branch)) {
                    requestConfirm(t("GROUP_ASK_UNITE_FOMRATION_MESSAGE"), () => {
                        createWishCall(wish);
                    });
                } else if (!FunctionNeedVerifFunctionTer.includes(chefFunction.fonction) && branchNeedVerifFunctioTern.includes(wish.branch)) {
                    requestConfirm(t("TERRITORIAL_VERIF_FUNCTION_MESSAGE"), () => {
                        createWishCall(wish);
                    });
                } else {
                    createWishCall(wish);
                }

            } catch (error) {
                const response = JSON.parse(error.message);
                setModalErrorMessage(response.status);
                setModalOpenError(true);
                console.error('Erreur lors de la suppression du vœu utilisateur:', error);
            }
        }
    };

    return (
        <div className='wishList-header'>
            <Button
                onClick={handleOpen}
                startIcon={<AddIcon/>}
                sx={{
                    minWidth: 200,
                    width: 'fit-content !important',
                }}
                className='azimuth-btn'
            >
                {t("TEXT_ADD_WISH")}
            </Button>
            <AddWishForm
                onFormOpen={open}
                onClose={handleClose}
                onAdd={addWish}
                LevelOptions={LevelOptions}
                branchOptions={filterbranchOptions}
            />
        </div>
    )
}
export default AddWishFormWrapper
