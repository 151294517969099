import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React from "react";

const ConfigEditorModal= ({isDialogOpen, setEditText ,setIsDialogOpen, editText, updateText }) => {
    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };
    const handleDialogSave = () => {
        updateText(editText.id, editText.KEY, editText.value);
    };
    return (
        <Dialog fullWidth open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Edit Text</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Modify the text and key as needed.
                </DialogContentText>
                <TextField
                    margin="dense"
                    label="Text"
                    fullWidth
                    value={editText.value}
                    onChange={e => setEditText({ ...editText, value: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                    Annuler
                </Button>
                <Button onClick={handleDialogSave} color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfigEditorModal;