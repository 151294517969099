import React from 'react';
import {
    Box,
    Card, CardContent,
    CardMedia,
    Typography,

} from '@mui/material';
import { formatDate } from '../../Utils/functionTransforms';
import { LevelOptions } from "../../Utils/LevelOption";
import { branchOptions } from "../../Utils/BranchOption";
import { useAuth } from "../../Context/AuthContext";
import { downloadDocument } from "../../Utils/downloadDocument";
import "./styles/Formation.css"
import FormationItemMyDoc from "./FormationItemMyDoc";
import FormationItemDoc from "./FormationItemDoc";
import FormationItemQuestionaire from "./FormationItemQuestionaire";


const FormationMangerItem = ({ formation }) => {
    const { token, } = useAuth()

    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, { "type": "mestre", "formationId": formation.id })

    };

    const level = LevelOptions.find((branch) => formation.level === branch.key)
    const branch = branchOptions.find((branch) => formation.branch === branch.key)

    return (
        <>
            <Card className='cep-item' sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{ width: '68px', height: '68px', margin: '-25px auto 10px', zIndex: 1, position: 'relative' }}
                    image={branch.logo}
                />
                <CardContent sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="h4">{level.label}</Typography>
                        <Typography variant="h6" component="div">{formation.nom}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', textAlign: "center" }}>
                            <Typography variant="body1">du {formatDate(formation.date)}</Typography>
                            <Typography variant="body1">du {formatDate(formation.end_date)}</Typography>
                        </Box>
                        <Typography variant="body1" color="text.secondary">
                            <span>Lieu {(formation.lieu)}</span>
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-start', ml: 4, mt: 2 }}>
                        <FormationItemDoc formation={formation} handleDownloadDocument={handleDownloadDocument} />
                        <FormationItemMyDoc formation={formation} handleDownloadDocument={handleDownloadDocument} />
                        <FormationItemQuestionaire formation={formation} />
                    </Box>
                </CardContent>

            </Card>

        </>
    )
};

export default FormationMangerItem;
