import AuthRoutes from './AuthRoutes';
import defaultRoutes from "./DefaultRoutes";
import AdminRoutes from "./AdminRoutes";


const AppRoutes = [
    ...AuthRoutes,
     ...AdminRoutes,
    ...defaultRoutes
];

export default AppRoutes;