import { Button } from "@mui/material";
import React from "react";
import { allowAlgoStatus } from "../../Utils/AllowStatus";

const StagiareCardAdminActionExcludeMoulinette = ({ user, isAdministrator, formation, excludeStagiaireMoulinette }) => {
    return (isAdministrator && allowAlgoStatus.includes(user.status) && user.exclude_moulinette == 0 && formation.close == 0) && (
        <Button
            className="azimuth-btn full-width error outlined"
            onClick={() => excludeStagiaireMoulinette(user)}
        >
            exclure
        </Button>
    )
};

export default StagiareCardAdminActionExcludeMoulinette;
