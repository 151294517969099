import {Button} from "@mui/material";
import React from "react";
import {allowAddStatus} from "../../Utils/AllowStatus";


const StagiareCardAdminActionValidate = ({user, isAdministrator, formation, validateStagiaire}) => {
   return (isAdministrator && allowAddStatus.includes(user.status) && formation.close == 0) && (
       <Button
           className="azimuth-btn success full-width"
           onClick={() => validateStagiaire("withValidate")}
       >
           Valider
       </Button>
       )
};

export default StagiareCardAdminActionValidate;
