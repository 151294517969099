import {Box, Button, Skeleton} from "@mui/material";
import ImageSecure from "../Core/ImageSecure";
import React  from "react";
import useTexts from "../../hooks/useTexte";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";


function MaficheDocumentMedia({photoAttachment , loadAttachement, id  }) {
    const dataImage = { "formation_id": id, "auth": "stagaire" };
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();

    const handleDeleteAttachment = async (attachmentId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/attachment/${attachmentId}`;
            await fetchWithToken(apiUrl, "DELETE");
            loadAttachement();
        } catch (error) {
            console.error('Error deleting attachment:', error);
            loadAttachement();
        }
    };


    return photoAttachment ? (
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2}}>
                  <ImageSecure
                      path={photoAttachment.path}
                      params={dataImage}
                      style={{width: '100px', height: 'auto', marginBottom: '8px'}}
                  />
                  <Button onClick={() => handleDeleteAttachment(photoAttachment.id)} id='upload-photo'
                          variant="outlined">{t("TEXT_DELETE_PHOTO")}</Button>
              </Box>
          ) : (
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2}} id="skeleton">
                  <Skeleton variant="rectangular" width={100} height={100}/>
              </Box>
          )
}

export default MaficheDocumentMedia;
