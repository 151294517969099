import {Typography} from "@mui/material";
import {formatDate} from "../../Utils/functionTransforms";
import React from "react";
import {LevelOptions} from "../../Utils/LevelOption";
import {branchOptions} from "../../Utils/BranchOption";
import useTexts from "../../hooks/useTexte";

const FormationAdminItemHead = ({formation}) => {
    const level = LevelOptions.find((branch) => formation.level === branch.key)
    const branch = branchOptions.find((branch) => formation.branch === branch.key)
    const {t} = useTexts()

    return (
        <>

            <Typography id='cep-title' variant="h5" component="div">{level?.label} {branch?.label}</Typography>
            <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "16px"}} variant="h6"
                        component="div">{formation.nom}</Typography>
            <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "16px"}} variant="h6"
                        component="div">Code Azimuth : {formation.id}</Typography>
            <div id='cep-date'>
                <div>du {formatDate(formation.date)}</div>
                <div>au {formatDate(formation.end_date)}</div>
            </div>
            <div id='cep-people-count'>
                <div>{t("NBR_VOEUX_IN_THIS_FORM", formation.user_count)}</div>
                <div>{t("NBR_VALIDATE_IN_THIS_FORM", formation.user_count_validate)}</div>
                <div>{t("NBR_IN_WAIT_IN_THIS_FORM", formation.user_count_wait_validation)}</div>

            </div>

        </>
    )
};

export default FormationAdminItemHead;
