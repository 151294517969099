import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import { useConfirm } from "../Provider/ConfirmProvider";
import useTexts from "../hooks/useTexte";
import MyFormsDuplicator from "../components/customFormBuilder/MyFormsDuplicator";
import LoaderWait from "../components/LoaderWait";
import './styles/Forms.css';

const Forms = () => {
    const fetchWithToken = useFetchWithToken();
    const navigate = useNavigate();
    const { t } = useTexts();
    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { requestConfirm } = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation

    const loadForms = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form`;
            const data = await fetchWithToken(apiUrl);
            setForms(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading forms:', error);
        }
    }, [fetchWithToken]);

    useEffect(() => {
        loadForms();
    }, []);

    if (isLoading) {
        return (<LoaderWait />);
    }
    const handleDeleFom = (id) => {
        requestConfirm(t("DELETE_MY_FORM_WARNIBF"), async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/${id}/delete`;
            await fetchWithToken(apiUrl, "POST",);
            loadForms();
        });
    };
    return (
        <Container maxWidth="md">
            <Box sx={{ my: { xs: 0, md: 2 } }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Liste des questionnaires
                </Typography>
                <div className='formDetails' style={{ marginBottom: '8px' }}>
                    <Typography variant='body2' component='span'>Nouveau questionnaire</Typography>
                    <Box className='button-box'>
                        <button
                            className='azimuth-btn success'
                            onClick={() => navigate('/creer-template')}
                        >
                            Nouveau questionnaire
                        </button>
                    </Box>
                </div>
                <div className='formList'>
                    {forms.length > 0 ? (
                        forms.map((form, index) => (
                            <div key={index} className='formDetails'>
                                <Typography variant='body2' component='span'>{form.name}</Typography>
                                <Box className='button-box'>
                                    <MyFormsDuplicator loadForms={loadForms} selectedFormId={form.id} type="admin" />
                                    <button
                                        className='azimuth-btn'
                                        onClick={() => navigate(`/creer-template/${form.id}`)}
                                    >
                                        Modifier
                                    </button>
                                    <button
                                        className='azimuth-btn error'
                                        onClick={() => handleDeleFom(form.id)}
                                    >
                                        {t("DELETE_FORM")}
                                    </button>
                                </Box>
                            </div>
                        ))
                    ) : (
                        <Typography variant='body1' sx={{ textAlign: 'center', my: 4 }}>Aucun questionnaire trouvé</Typography>
                    )}
                </div>
            </Box>
        </Container>
    );
};

export default Forms;
