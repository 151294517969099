import React from "react";
import { Route } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import PrivateRoute from "../components/Core/PrivateRoute";
import TextEditor from "../Page/TextEditor";
import ConfigEditor from "../Page/ConfigEditor";
import RulesPage from "../Page/rulesPage";
import DerogationListPage from "../Page/DerogationListPage";
import AdminVoeuxListPage from "../Page/AdminVoeuxistPage";
import UserListPage from "../Page/UserListPage";
import FormationAdminListPage from "../Page/FormationAdminListPage";
import ForeignPage from "../Page/ForeignPage";
import EmailPage from "../Page/EmailPage";
import AdminDatesPage from "../Page/AdminDatesPage";

const AdminRoutes = [
        <Route
            path="/texteditor"
            element={<DefaultLayout><PrivateRoute><TextEditor /></PrivateRoute></DefaultLayout>}
            key="texteditor"
        />,
        <Route
            path="/dates"
            element={<DefaultLayout><PrivateRoute><AdminDatesPage /></PrivateRoute></DefaultLayout>}
            key="texteditor"
        />,

        <Route
            path="/configuration"
            element={<DefaultLayout><PrivateRoute><ConfigEditor /></PrivateRoute></DefaultLayout>}
            key="configuration"
        />,
        <Route
            path="/rules"
            element={<DefaultLayout><PrivateRoute><RulesPage /></PrivateRoute></DefaultLayout>}
            key="rules"
        />,
        <Route
            path="/gerer-derogation"
            element={<DefaultLayout><PrivateRoute><DerogationListPage /></PrivateRoute></DefaultLayout>}
            key="gerer-derogation"
        />,
        <Route
            path="/gerer-voeux"
            element={<DefaultLayout><PrivateRoute><AdminVoeuxListPage /></PrivateRoute></DefaultLayout>}
            key="gerer-voeux"
        />,
        <Route
            path="/gerer-utilisateur"
            element={<DefaultLayout><PrivateRoute><UserListPage /></PrivateRoute></DefaultLayout>}
            key="gerer-utilisateur"
        />,
        <Route
            path="/gerer-ceps"
            element={<DefaultLayout><PrivateRoute><FormationAdminListPage /></PrivateRoute></DefaultLayout>}
            key="gerer-ceps"
        />,
        <Route
            path="/gerer-etranger"
            element={<DefaultLayout><PrivateRoute><ForeignPage /></PrivateRoute></DefaultLayout>}
            key="gerer-etranger"
        />,
        <Route
            path="/email"
            element={<DefaultLayout><PrivateRoute><EmailPage /></PrivateRoute></DefaultLayout>}
            key="email"
        />
];

export default AdminRoutes;