import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { branchOptions } from '../../Utils/BranchOption';
import { LevelOptions } from '../../Utils/LevelOption';
import { calculateEndDate, formatDate } from '../../Utils/functionTransforms';
import { formatErrors } from "../../Utils/formatErrors";
import useTexts from "../../hooks/useTexte";
import UserListPage from "../../Page/UserListPage";
import './styles/Derogation.css';
import { useMediaQuery } from '@mui/system';

const DerogationItem = ({ wish, ficheMode, showClose }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { t } = useTexts()
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCodeSycomore, setSelectedCodeSycomore] = useState('');
    const level = LevelOptions.find((branch) => wish.level === branch.key)
    const branch = branchOptions.find((branch) => wish.branch === branch.key)
    const invalideDate = JSON.parse(wish.invalide_data)

    const handleOpenUserModal = (codeSycomore) => {
        setSelectedCodeSycomore(codeSycomore);
        setDialogOpen(true);
    };

    const renderDerogationStatus = () => {
        if (wish.is_derogation_status === 1) {
            return "Dérogation accordée";
        } else if (wish.is_derogation_status === 2) {
            return "Dérogation refusée";
        }
        return "Statut inconnu";
    };

    const handleCloseUserModal = () => {
        setDialogOpen(false);
        setSelectedCodeSycomore('');
    };

    return (
        <Box className="derogation-item-content" >
            <Box className='derogation-item-header'>
                <Box id='left-content' sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img src={branch.logo} alt="Training" />
                    <Typography variant={isMobile ? 'h6' : 'h5'}>{level.label}</Typography>
                    {(!ficheMode || ficheMode === 0) && (
                        <Typography variant="{isMobile ? 'h6' : 'h5'}">{wish.user_firstname} {wish.user_lastname}</Typography>
                    )}
                </Box>
                {!isMobile && (!ficheMode || ficheMode === 0) && (
                    <a className='voir-fiche' onClick={() => handleOpenUserModal(wish.user_code_sycomore)}>
                        Voir la fiche
                    </a>
                )}
            </Box>

            <Box className='derogation-item-body'>
                <div id='from-to' style={{ flex: '1 1 20%', marginBottom: { xs: 2, sm: 0 } }}>
                    <Typography variant='body2'>du {formatDate(wish.date)}</Typography>
                    <Typography variant='body2'>au {calculateEndDate(wish.date, branch.key, level.key)}</Typography>
                </div>
                <div style={{ flex: '1 1 40%', alignContent: "center", marginBottom: { xs: 2, sm: 0 } }}>
                    {invalideDate && invalideDate.length > 0 ? (
                        wish.valide !== 1 ? (
                            <Typography variant="body1" className="requirevalidation">
                                {formatErrors(wish.invalide_data, t)}
                            </Typography>
                        ) : (
                            <Typography variant="body1">Dérogation accordée</Typography>
                        )
                    ) : (
                        <Typography variant="body1">Voeu Valide</Typography>
                    )}
                </div>
            </Box>

            {showClose === true && (
                <>
                    <div style={{ flex: '1 1 20%', marginBottom: { xs: 2, sm: 0 } }}>
                        <Typography variant="body1">
                            Nom opérateur: {wish.duser_firstname} {wish.duser_lastname}
                        </Typography>
                    </div>
                    <div style={{ flex: '1 1 20%', marginBottom: { xs: 2, sm: 0 } }}>
                        <Typography variant="body1">Statut: {renderDerogationStatus()}</Typography>
                        <Typography variant="body1">Date: {formatDate(wish.derogation_date)}</Typography>
                    </div>
                    <div style={{ flex: '1 1 20%', marginBottom: { xs: 2, sm: 0 } }}>
                        <Typography variant="body1">Commentaire: {wish.derogation_commentaire}</Typography>
                    </div>
                </>
            )}
            <Dialog
                open={dialogOpen}
                onClose={handleCloseUserModal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>Informations Utilisateur</DialogTitle>
                <DialogContent>
                    <UserListPage codeSycomoreData={selectedCodeSycomore} />
                </DialogContent>
                <Button onClick={handleCloseUserModal}>Fermer</Button>
            </Dialog>
        </Box>
    );
};

export default DerogationItem;
