export const cepBranchType = {

    'nospecialite': 'Terrestre',
    'marin': 'Marin',
    'montagne': 'Montagne',
};

export const cepBranchTypeNoFreeDate = [
    'marin', 'montagne'
]

