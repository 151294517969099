import dayjs from "dayjs";


export const paramValueFormatInit = (key, paramValue) => {
    if (paramValue !== null) {
        switch (key) {
            case "showOpen":
            case "showClose":
                return paramValue === "true";
            case "selectedPersonne":
                try {
                    return paramValue ? JSON.parse(paramValue) : [];
                } catch{
                    return false
                }
            case "selectedStruct":
                if (paramValue && paramValue != 0) {
                    return paramValue.split(',').map(Number)
                }
                return '';
            case "selectedProvince":
            case "selectedDistrict":
                try {
                    return paramValue ? JSON.parse(paramValue) : [];
                } catch  {
                    return null
                }
            case "selectedstartDate":
            case "selectedendDate":
                return paramValue ? new dayjs(Number(paramValue)) : null;
            case "itemsPerPage":
                return Number(paramValue);
            default:
                return paramValue;
        }

    }
};

export const paramValueFormat = (filters) => {
    const params = {};

    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== "") {
            switch (key) {
                case "showOpen":
                case "showClose":
                    params[key === "showOpen" ? "open" : "close"] = value ? "true" : "false";
                    break;
                case "selectedStruct":
                    params[key] = value.join(',')
                    break;
                case "selectedPersonne": {
                    const {id, firstname, lastname} = value;
                    params[key] = JSON.stringify({id, firstname, lastname});
                    break;
                }
                case "selectedProvince":
                case "selectedDistrict":

                    params[key] = JSON.stringify(value)

                    break;
                case "selectedstartDate":
                case "selectedendDate":
                    params[key] = new Date(value).getTime(); // Stocke en timestamp
                    break;
                case "itemsPerPage":
                    params["perPage"] = value;
                    break;
                default:
                    params[key] = value;
            }

        }
    });
    return params;
};
