import React, {useState} from 'react';
import {Button, Dialog, DialogContent, IconButton, Typography,} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import ListIcon from "@mui/icons-material/List";
import {CloseRounded} from "@mui/icons-material";
import StagiareCard from "./StagiareCard";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import FormationManagerItemListUserAction from "./FormationManagerItemListUserAction";


const FormationMangerItemListUser = ({formation, teams}) => {
    const fetchWithToken = useFetchWithToken();
    const [attachements, setAttachements] = useState([]);
    const [users, setUsers] = useState([]);
    const [openListModal, setOpenListModal] = useState(false);
    const {t} = useTexts();

    const handleCloseListModal = () => {
        // Fermer le modal directement si aucune modification n'est détectée
        setOpenListModal(false);
    };
    const handleOpenListModal = () => {
        listUserSelecing();
    };
    const listUserSelecing = async () => {
        try {
            const apiUrlAttachement = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/attachements`;
            await fetchWithToken(apiUrlAttachement).then(data => {
                setAttachements(data)

            });
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${formation.id}/selected`;
            await fetchWithToken(apiUrl).then(data => {
                setUsers(data)
                setOpenListModal(true);
            });

        } catch (error) {
            console.error("Error listUserSelecing:", error);
        }

    };

    const assignUserToTeam = async (userId, teamId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/team/assigne/${userId}`;
            await fetchWithToken(apiUrl, "POST", {team_id: teamId});
        } catch (error) {
            console.error("Erreur lors de l'affectation d'un utilisateur à une équipe :", error);
        }
    };

    return (
        <>
            <Button
                className='azimuth-btn full-width'
                variant="contained"
                onClick={() => handleOpenListModal()}
                startIcon={<ListIcon />}
            >
                {t("LIST_STAGIAIRE")}
            </Button>
            <Dialog open={openListModal} onClose={handleCloseListModal} maxWidth="lg" fullWidth>
                <DialogContent>
                    <div className='stagiaire-card-container'  spacing={2}>
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <FormationManagerItemListUserAction formation={formation}/>
                            <IconButton color='inherit' onClick={handleCloseListModal} sx={{height: 'fit-content'}}>
                                <CloseRounded/>
                            </IconButton>
                        </div>
                        {users && attachements && users.map((user, index) => (
                            <StagiareCard
                                key={index} user={user}
                                teams={teams}
                                onAssign={assignUserToTeam}
                                attachments={attachements.filter((attachement => attachement.formation_user_id === user.formation_user_id))}
                            />
                        ))}
                        {users.length === 0 && (
                            <Typography variant="body1"
                                        sx={{textAlign: 'center', width: '100%'}}>{t("NO_STAGIAIRE")}</Typography>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default FormationMangerItemListUser;
