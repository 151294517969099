import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid2 } from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken"
import useTexts from "../../hooks/useTexte";
import DocIcon from '@mui/icons-material/Description';
import DocumentCard from "../Core/DocumentCard";
import "./styles/Formation.css"
import FormationMangerItemMyDocBtn from "./FormationItemMyDocBtn";

const FormationMangerItemMyDoc = ({ formation, handleDownloadDocument }) => {
    const fetchWithToken = useFetchWithToken();
    const [mydocuments, setMyDocuments] = useState([]);
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const { t } = useTexts();

    const handleUploadDocumentsModal = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/form/voeu/${formation.id}/mydocs`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setMyDocuments(data);
            setOpenDocumentsModal(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };
    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    const handleDeleteDocument = async (documentId) => {
        const apiUrlDelete = `${process.env.REACT_APP_API_URL}/api/form/voeu/${formation.id}/mydocs/${documentId}`;
        try {
            await fetchWithToken(apiUrlDelete, 'DELETE', {});
            handleUploadDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error deleting document:", error);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'center', cursor: 'pointer' }} onClick={handleUploadDocumentsModal}>
                <DocIcon />
                {t("UPLOAD_MES_DOCUMENTS")}
            </Box>

            <Dialog open={openDocumentsModal} onClose={handleCloseDocumentsModal} maxWidth="lg" fullWidth>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                    <Grid2 container spacing={2}>
                        {mydocuments.map((doc) => (
                            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={doc.id}>
                                <DocumentCard document={doc}
                                    onDelete={() => handleDeleteDocument(doc.id)}
                                    onDownload={() => handleDownloadDocument(doc.path)} />
                            </Grid2>
                        ))}
                    </Grid2>
                    <FormationMangerItemMyDocBtn handleUploadDocumentsModal={handleUploadDocumentsModal} selectedFile={selectedFile} setSelectedFile={setSelectedFile}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
};

export default FormationMangerItemMyDoc;
