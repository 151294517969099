import React, { useState } from 'react';
import { Typography, TableRow, TableCell, } from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { formatStatus } from "../../Utils/Status";
import { useSystem } from "../../Context/System";
import dayjs from "dayjs";
import StagiareCardAdminAction from "./StagiareCardAdminAction";
import AdminDisplayFiche from "./AdminDisplayFiche";
import { getStatusStyle } from "../../Utils/getStatusStyle";

const StagiareCardAdmin = ({ user, refreshUser, formation, index, isAdministrator, displayDateStatus }) => {
    const [formations, setFormations] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const { getConfiguration } = useSystem();

    const fetchFormations = async () => {
        try {
            const body = {
                branche: formation.branch,
                level: formation.level,
                page: 1
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin`;
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setFormations(data.formations); // Assuming the response is the list of formations
        } catch (error) {
            console.error('Erreur lors de la récupération des formations:', error);
        }
    };

    const NB_RELANCE_VALIDATION_PLACE = parseInt(getConfiguration("NB_RELANCE_VALIDATION_PLACE"))
    const NB_RELANE_DAY = parseInt(getConfiguration("NB_RELANE_DAY"))

    const dateStatus = dayjs(user.date_status); // Conversion de la date
    const totalRelanceDays = (NB_RELANCE_VALIDATION_PLACE + 1) * NB_RELANE_DAY;
    const dateFinValidation = dateStatus.add(totalRelanceDays, 'day');

    return (
        <TableRow key={index}>
            <TableCell>{user.user_code_sycomore}</TableCell>
            <TableCell>
                <div>{user.user_firstname} {user.user_lastname}</div>
                <AdminDisplayFiche user={user} />
            </TableCell>
            <TableCell>{user.structure_nom}</TableCell>
            <TableCell>{user.fonction}</TableCell>

            {displayDateStatus && (
                <TableCell>
                    {user.status == "WAIT_VALIDATION" && (
                        <div>{dateFinValidation.format('DD MMMM')}</div>
                    )}
                </TableCell>
            )}
            <TableCell>
                <Typography
                    sx={{
                        padding: 0.5,
                        borderRadius: 1,
                        ...getStatusStyle(user.status),
                    }}
                    variant='body2'
                >
                    {formatStatus(user.status, user.other_inscription_wait_validation, 1)}
                </Typography>
            </TableCell>
            <StagiareCardAdminAction
                user={user}
                formations={formations}
                isAdministrator={isAdministrator}
                formation={formation}
                fetchFormations={fetchFormations}
                refreshUser={refreshUser}
            />
        </TableRow>
    );
};

export default StagiareCardAdmin;
