import React from "react";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";

const FormationMangerAdminListUserWaitExport = ({wish, isAdministrator}) => {
    const fetchWithToken = useFetchWithToken();

    const exportWaitList = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/waitlist/${wish.id}/export`;
            const formData = {};
            const response = await fetchWithToken(apiUrl, "POST", formData, false, "RESPONSE");
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = 'export.xlsx'; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            // Créer un lien de téléchargement et déclencher le téléchargement du fichier
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Nettoyage après téléchargement
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };


    return isAdministrator && (
        <button className="azimuth-btn outlined" onClick={() => exportWaitList()}>Exporter</button>
    )
};

export default FormationMangerAdminListUserWaitExport;
