export    const getStatusStyle = (status) => {
    switch (status) {
        case 'WAIT':
            return {
                backgroundColor: 'warning.main',
                color: '#fff',
            };
        case 'WAIT_VALIDATION':
            return {
                backgroundColor: 'info.main',
                color: '#fff',
            };
        case 'PROPOSITION':
            return {
                backgroundColor: 'info.main',
                color: '#fff',
            };
        case 'VALIDATE':
            return {
                backgroundColor: 'success.main',
                color: '#fff',
            };
        case 'REFUSE':
            return {
                backgroundColor: 'white',
                color: 'red',
            };
        default:
            return {};
    }
};