import { FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React from "react";

const  AzRadio = ({sectionIndex , field, fieldValue, handleInputChange})=>{
    return (
        <FormControl className="form-element" sx={{ mt: 3 }}>
            <RadioGroup
                row
                value={fieldValue}
                onChange={e => handleInputChange(sectionIndex, field.name, e.target.value)}
            >
                {field.options.map(option => (
                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} className="form-element" />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default  AzRadio
