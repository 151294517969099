
import React from "react";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";


const PreviewSelect = ({question})=>{
    return (
        <TextField
            select
            label="Choisir une option"
            fullWidth
            variant="outlined"
            sx={{marginTop: 1}}
        >
            {question.options?.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    )
}
export default PreviewSelect