import React from "react";
import { Grid2, FormControl, FormControlLabel, Switch, } from "@mui/material";

const GenericFilterRadio = ({ filters, handleFilterChange, target, target2, label }) => {
    const handleSwitchChange = (checked) => {
        // Appeler handleFilterChange pour le switch actuel
        handleFilterChange(target, checked);
        if (filters?._displayOneRadio === "true") {
            handleFilterChange(target2, !checked);
        }
    };

    return (
        <>
            {target in filters && (
                <Grid2 xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={filters[target]}
                                    onChange={(e) => handleSwitchChange(e.target.checked)}
                                />
                            }
                            label={label}
                        />
                    </FormControl>
                </Grid2>
            )}
        </>
    );
};

export default GenericFilterRadio;
