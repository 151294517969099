import React, {useState} from "react";
import {AppBar, Box, Button, Container, Dialog, DialogContent, Grid, TextField, Toolbar,} from "@mui/material";


import SearchModal from "./Search/SearchModal";

const UserListSelector = ({codeSycomoreData, selectedPersonne, setSelectedPersonne})=>{
    const [dialogOpenSearch, setDialogOpenSearch] = useState(false);

    const HandlePersonneSearch = (newuser) => {
        setSelectedPersonne(newuser);
        setDialogOpenSearch(false);
    };


    const handleResetPersonne = () => {
        setSelectedPersonne(null);
        setDialogOpenSearch(false);
    };

    const handleOpenSearchDialog = () => {
        setDialogOpenSearch(true);
    };

    const formatSearchUser = (user) => {
        return user.firstname + " " + user.lastname;
    };


    return (!codeSycomoreData &&
       <>
           <AppBar position="static" color="default" sx={{top: 10, boxShadow: "none", background: "none"}}>
               <Toolbar>
                   <Container maxWidth="xl"
                              sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2}}>

                       <Grid item xs={12}>
                           <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} mt={2}>
                               <TextField
                                   label="Nom de l'utilisateur"
                                   value={(selectedPersonne ? formatSearchUser(selectedPersonne) : "")}
                                   InputProps={{readOnly: true}}
                                   onClick={handleOpenSearchDialog}
                                   variant="outlined"
                                   sx={{flex: 1}}
                               />
                           </Box>
                       </Grid>


                   </Container>
               </Toolbar>
           </AppBar>

           <Dialog open={dialogOpenSearch} onClose={() => setDialogOpenSearch(false)}
                   aria-labelledby="search-dialog-title">
               <DialogContent>
                   <SearchModal onClick={HandlePersonneSearch} functionMode={0}/>
                   <Button variant="contained" color="secondary" onClick={handleResetPersonne}>
                       Réinitialiser
                   </Button>
               </DialogContent>
           </Dialog>

           <Toolbar/>
       </>
   )


}
export  default UserListSelector