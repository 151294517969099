import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from "../Context/AuthContext";
import {jwtDecode } from 'jwt-decode';
import './styles/OAuthCallback.css';
import MultiCompte from "../components/auth/MultiCompte"; // Assurez-vous de créer ce fichier CSS pour ajouter du style

const OAuthCallback = () => {
    let navigate = useNavigate();
    const { login } = useAuth();
    const { provider } = useParams();
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempToken, setTempToken] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');
            const state = params.get('state');
            if (code && provider) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/${provider}/callback?code=${code}`);
                    const data = await response.json();
                    if (response.ok) {
                        const token = data.token;
                        const decodedToken = jwtDecode(token);

                        if (decodedToken.users) {
                            setTempToken(token);
                            setUsers(decodedToken.users);
                            setIsModalOpen(true);
                        } else {
                            login(data.token);
                            if (state) {
                                navigate(state, { replace: true });
                            } else {
                                navigate('/');
                            }
                        }
                    } else {
                        navigate('/login?error=' + data.message);
                    }
                } catch (error) {
                    console.error('Error during OAuth callback:', error);
                    navigate('/login?error=_error_');
                }
            } else {
                navigate('/login?error=_error_');
            }
        };

        fetchToken();
    }, [login, navigate, provider]);



    return (
        <div>
            <p>Loading...</p>
            {isModalOpen && (
                <MultiCompte isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} users={users}  tempToken={tempToken} />
            )}
        </div>
    );
};

export default OAuthCallback;
