import { Button, Typography, Box } from "@mui/material";
import { formatDate } from "../Utils/functionTransforms";
import React, { useState } from "react";
import Userlog from "../Page/Userlog";
import { useMediaQuery } from "@mui/system";

const UserListPageInfo = ({selectedPersonne, isFunctionsEmpty, email, codeSycomore, birthday}) => {
    const [dialogOpenLogs, setDialogOpenLogs] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 2, width: '50%' }} id="user-info-container">
            {isFunctionsEmpty && (
                <Typography variant="body1" sx={{color: 'red'}}>
                    Pas de fonction
                </Typography>
            )}

            {email ? (
                <Typography id="personnal-data" variant="body1" sx={{marginBottom: 2}}>
                    Email de l'utilisateur : {email}
                </Typography>
            ) : (
                <Typography variant="body1" sx={{marginBottom: 2}}>
                    Email de l'utilisateur : <span style={{ fontStyle: 'italic', fontSize: "smaller", color: "gray" }} >Aucun email trouvé</span>
                </Typography>
            )}

            {codeSycomore ? (
                <Typography id="personnal-data" variant="body1" sx={{marginBottom: 2}}>
                    code sycomore de l'utilisateur : {codeSycomore}
                </Typography>
            ) : (
                <Typography id="personnal-data" variant="body1" sx={{marginBottom: 2}}>
                    code sycomore de l'utilisateur : <span style={{ fontStyle: 'italic', fontSize: "smaller", color: "gray" }} >Aucun code sycomore trouvé</span>
                </Typography>
            )}

            {birthday ? (
                <>
                    <Typography id="personnal-data" variant="body1" sx={{marginBottom: 2}}>
                        {`Date de naissance : ${formatDate(birthday)} (${calculateAge(birthday)})`}
                    </Typography>
                </>
            ) : (
                <>
                    <Typography id="personnal-data" variant="body1" sx={{marginBottom: 2}}>
                        Date de naissance : <span style={{ fontStyle: 'italic', fontSize: "smaller", color: "gray" }} >Aucune date de naissance trouvée</span>
                    </Typography>
                </>
            )}

            {codeSycomore ? (
                <Button
                    className={`azimuth-btn ${isMobile ? 'full-width' : ''}`}
                    onClick={async () => {
                        setDialogOpenLogs(true); // Ouvrir la modal après le chargement des logs
                    }}
                >
                    Voir journal utilisateur
                </Button>
            ) : (
                <Button variant="contained" color="primary" disabled sx={{marginBottom: 2}}>
                    Voir journal utilisateur
                </Button>
            )}

            <Userlog selectedPersonne={selectedPersonne} dialogOpenLogs={dialogOpenLogs} setDialogOpenLogs={setDialogOpenLogs} />
        </Box>
    );
}

export default UserListPageInfo;