import React from "react";
import AvisBox from "./AvisBox";
import { Box, Divider, Typography } from "@mui/material";

const AvisBoxGrid = ({ title, avis, type, haveChef, onClick, isPage, chefData }) => {
    const writor =  avis?.writor
    const isDifferentUser = avis?.writor?.user_id !== chefData?.user_id;

    if (!haveChef && !isDifferentUser) {
        return null;
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: {xs: '100%', md: '33%'} }} className="avisboxgrid">
            {(avis || haveChef) && (
                <AvisBox
                    isPage={isPage}
                    title={title}
                    avis={avis}
                    type={type}
                    onClick={haveChef ? onClick : null}
                />
            )}
            {haveChef && chefData && (
                <div style={{ padding: "16px", border: "1px solid #ddd", borderRadius: "8px", display: "flex", flexDirection: "column", gap: 8, height: "100%", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <Typography variant="h5">Chef associé</Typography>
                    <Typography variant="body1"><strong>Prénom :</strong> {chefData.firstname}</Typography>
                    <Typography variant="body1"><strong>Nom :</strong> {chefData.lastname}</Typography>
                    <Typography variant="body1"><strong>Email :</strong> {chefData.email}</Typography>
                    <Typography variant="body1"><strong>Code Sycomore :</strong> {chefData.code_sycomore}</Typography>
                    <Typography variant="body1"><strong>Tel 1 :</strong> {chefData.phone}</Typography>
                    <Typography variant="body1"><strong>Tel 2 :</strong> {chefData.phone_2}</Typography>
                    <Typography variant="body1"><strong>Tel 3 :</strong> {chefData.phone_3}</Typography>
                </div>
            )}
            {isDifferentUser && writor && (
                <div style={{ padding: "16px", border: "1px solid #ddd", borderRadius: "8px", display: "flex", flexDirection: "column", gap: 8, height: "100%", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <Typography variant="h6" style={{marginBottom: "8px"}}>Auteur de l'avis</Typography>
                    <Typography variant="body1"><strong>Prénom :</strong> {writor.firstname}</Typography>
                    <Typography variant="body1"><strong>Nom :</strong> {writor.lastname}</Typography>
                    <Typography variant="body1"><strong>Email :</strong> {writor.email}</Typography>
                    <Typography variant="body1"><strong>Tel 1 :</strong> {writor.phone}</Typography>
                    <Typography variant="body1"><strong>Tel 2 :</strong> {writor.phone_2}</Typography>
                    <Typography variant="body1"><strong>Tel 3 :</strong> {writor.phone_3}</Typography>
                </div>
            )}
            {isDifferentUser && <Divider id='avisboxgrid-divider' sx={{ my: 2, mx: 4, display: { xs: 'block', md: 'none' } }} />}
        </Box>
    );
};
export default AvisBoxGrid;