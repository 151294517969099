import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Radio from "@mui/material/Radio";

const PreviewCompetences = ({question})=>{
    return (
        <TableContainer sx={{marginTop: 2}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {question.options.map((level, levelIndex) => (
                            <TableCell key={levelIndex} align="center">
                                {level}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {question.competences.map((competence, competenceIndex) => (
                        <TableRow key={competenceIndex}>
                            <TableCell component="th" scope="row">
                                {competence || `Compétence ${competenceIndex + 1}`}
                            </TableCell>
                            {question.options.map((level, levelIndex) => (
                                <TableCell key={levelIndex} align="center">
                                    <Radio
                                        checked={false}
                                        value={level}

                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PreviewCompetences;