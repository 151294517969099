import React, {useState} from 'react';
import {useAuth} from "../Context/AuthContext";
import { useNavigate} from "react-router-dom";
import Header from "../components/Core/Header";
import useTexts from "../hooks/useTexte";
import ErrorModal from "../components/Modal/ErrorModal";
import Dashboard from '../components/Dashboard/Dashboard';
import './DefaultLayout.css'
import CmsTopPage from "../components/CmsTopPage";

const DefaultLayout = ({children}) => {
    const {token, logout} = useAuth();
    const navigate = useNavigate(); // Import useNavigate
    const [mobileOpen, setMobileOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const {authErrorNoFunction} = useAuth();
    const authErrorCloseHand = () => {
        logout()
        navigate('/login', {state: null}); // Rediriger en purgant location.state
    }
    const {t} = useTexts()
    const handleSearchToggle = () => {
        setSearchOpen(!searchOpen);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    if (!authErrorNoFunction) {
        return (

            <div className='defaultLayout-container'>
                {token && <Header handleDrawerToggle={handleDrawerToggle} handleSearchToggle={handleSearchToggle}/>}
                <div className='defaultLayout-mainContent'>
                    <Dashboard/>
                    <div className="cmsPageTop">
                       <CmsTopPage />
                        <div id="_content" className="row content">
                            <div id="ctl00__divContentMid" className="col-xs-12">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorModal open={authErrorNoFunction} error={t("NO_FUNCTION")} onClose={authErrorCloseHand}/>
            </div>
        );
    } else {
        return (
            <div className="">
                <ErrorModal open={authErrorNoFunction} error="no_function" onClose={authErrorCloseHand}/>
            </div>
        );
    }
}

export default DefaultLayout;
