import React, { useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,} from '@mui/material';

import {useFetchWithToken} from "../../hooks/useFetchWithToken"


const FormationMangerItemTeam = ({formation, fetchTeams, teams}) => {
    const fetchWithToken = useFetchWithToken();
    const [openTeamsModal, setOpenTeamsModal] = useState(false);
    const [newTeamName, setNewTeamName] = useState("");


    const deleteTeam = async (teamId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/team/${teamId}`;
            await fetchWithToken(apiUrl, "DELETE");
            fetchTeams();
        } catch (error) {
            console.error("Erreur lors de la suppression d'une équipe :", error);
        }
    };
    const addTeam = async () => {
        if (!newTeamName) return;
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/team`;
            await fetchWithToken(apiUrl, "POST", {name: newTeamName});
            setNewTeamName("");
            fetchTeams();
        } catch (error) {
            console.error("Erreur lors de l'ajout d'une équipe :", error);
        }
    };


    return (
        <>
            <Button
                className='azimuth-btn full-width'
                variant="contained"
                onClick={() => setOpenTeamsModal(true)}
            >
                Gérer les équipes
            </Button>

            <Dialog open={openTeamsModal} onClose={() => setOpenTeamsModal(false)}>
                <DialogTitle>Gérer les équipes</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <TextField
                                label="Nom de l'équipe"
                                value={newTeamName}
                                onChange={(e) => setNewTeamName(e.target.value)}
                                fullWidth
                            />
                            <Button onClick={addTeam} variant="contained" sx={{ml: 2}}>Ajouter</Button>
                        </Box>

                        {/* Liste des équipes */}
                        <Box>
                            {teams.map((team) => (
                                <Box key={team.id} display="flex" alignItems="center" justifyContent="space-between"
                                     mb={1}>
                                    <Typography>{team.nom}</Typography>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => deleteTeam(team.id)}
                                    >
                                        Supprimer
                                    </Button>
                                </Box>
                            ))}
                        </Box>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTeamsModal(false)}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FormationMangerItemTeam;
