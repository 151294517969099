import React from 'react';
import { Typography} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import './styles/WishItem.css';
import {CheckCircleOutlineRounded} from "@mui/icons-material";


const WishItemStatusWishDisplayWaitHasValidate = ({label}) => {
    const {t} = useTexts();

    return (
        <Typography
            sx={{
                display: 'flex',
                color: "green",
                alignItems: 'center',
                fontFamily: 'ubuntu',
                marginTop: 1,
            }}
            variant="body2"
        >
            <CheckCircleOutlineRounded color="success" sx={{marginRight: '8px'}}/>
            {t(label)}
        </Typography>
    )

};

export default WishItemStatusWishDisplayWaitHasValidate;
