import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery } from "@mui/system";
import { FilterListOffRounded, FilterListRounded } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import useTexts from "../../hooks/useTexte";
import { useSystem } from "../../Context/System";
import { useAuth } from "../../Context/AuthContext";
import { branchOptions } from '../../Utils/BranchOption';
import { paramValueFormat, paramValueFormatInit } from "../../Utils/formatFilters";

import GenericFilterType from "./GenericFilterType";
import GenericFilterBranch from "./GenericFilterBranch";
import GenericFilterDate from "./GenericFilterDate";
import GenericFilterDateInterval from "./GenericFilterDateInterval";
import GenericFilterStructure from "./GenericFilterStructure";
import GenericFilterText from "./GenericFilterText";
import GenericFilterRadio from "./GenericFilterRadio";
import GenericFilterPersonne from "./GenericFilterPersonne";
import GenericFilterPagination from "./GenericFilterPagination";

import './styles/GenericFilter.css';

/**
 * Composant de filtre générique
 * @param filters Les filtres
 * @param setFilters Fonction pour mettre à jour les filtres
 * @param page La page actuelle
 * @param setPage Fonction pour mettre à jour la page
 * @param totalPages Le nombre total de pages
 * @param filterbranchOptions Les options pour les filtres de branche
 */
const GenericFilter = ({filters, setFilters, page, setPage, totalPages, filterbranchOptions}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {childUnite} = useAuth();
    const {t} = useTexts();
    const {provinces, districts} = useSystem();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    function setParams() {
        const params = paramValueFormat(filters)
        if (page && page > 1) params["page"] = page;
        setSearchParams(params);
        if (!filters.init) {
            setFilters(prev => ({...prev, init: 1}));
        }
    }

    useEffect(() => {
        const allowedKeys = Object.keys(filters);
        const initialFilters = {};
        allowedKeys.forEach((key) => {
            const paramValue = searchParams.get(key);
            if (paramValue !== null) {
                initialFilters[key] = paramValueFormatInit(key, paramValue);

            }
        });
        setFilters(prev => ({...prev, ...initialFilters}));
        const initialPage = Number(searchParams.get("page")) || 1;
        if (setPage) {
            setPage(initialPage);
        }
    }, []);
    useEffect(() => {
        setParams();
    }, [filters, page, setSearchParams]);

    // Gérer les changements dans les filtres
    const handleFilterChange = (key, value) => {
        setFilters(prev => ({...prev, [key]: value}));
        if (setPage) {
            setPage(1);
        }
    };
    const listBranchOption = Array.isArray(filterbranchOptions) ? filterbranchOptions : branchOptions;

    const renderAllFilters = () => {
        return (
            <React.Fragment>
                <Box className="filter-type">
                    <GenericFilterBranch filters={filters} listBranchOption={listBranchOption} handleFilterChange={handleFilterChange} />
                    <GenericFilterType filters={filters} handleFilterChange={handleFilterChange} />
                    <GenericFilterDate filters={filters} handleFilterChange={handleFilterChange} />
                    <GenericFilterDateInterval filters={filters} handleFilterChange={handleFilterChange} interval="selectedstartDate" label={t("START_DATE_LABEL")} />
                    <GenericFilterDateInterval filters={filters} handleFilterChange={handleFilterChange} interval="selectedendDate" label={t("END_DATE_LABEL")} />
                    <GenericFilterStructure filters={filters} handleFilterChange={handleFilterChange} target="selectedProvince" source={provinces} label={t("LIST_FORMATION_PROVINCE")} />
                    <GenericFilterStructure filters={filters} handleFilterChange={handleFilterChange} target="selectedStruct" source={childUnite} label={t("LIST_FORMATION_STRUCT")} />
                    <GenericFilterStructure filters={filters} handleFilterChange={handleFilterChange} target="selectedDistrict" source={districts} label={t("LIST_FORMATION_DISTRICT")} />
                    <GenericFilterText filters={filters} handleFilterChange={handleFilterChange} target="selectedNom" label={t("NOM_LABEL")} />
                    <GenericFilterPersonne filters={filters} handleFilterChange={handleFilterChange} />
                </Box>

                <Box className="filter-radio">
                    <GenericFilterRadio filters={filters} handleFilterChange={handleFilterChange} target="showOpen" target2="showClose" label={t("LABEL_SHOW_OPEN")} />
                    <GenericFilterRadio filters={filters} handleFilterChange={handleFilterChange} target="showClose" target2="showOpen" label={t("LABEL_SHOW_CLOSE")} />
                </Box>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="filters-container">
                {isMobile ? (
                    <>
                        <Button startIcon={
                            filters.show ? <FilterListOffRounded /> : <FilterListRounded />
                        } className="toggle-filters" onClick={() => setFilters(prev => ({ ...prev, show: !prev.show }))}>
                            {filters.show ? t("FILTERS_HIDE") : t("FILTERS_SHOW")}
                        </Button>
                        {filters.show && renderAllFilters()}
                    </>
                ) : (
                    renderAllFilters()
                )}
                <GenericFilterPagination handleFilterChange={handleFilterChange} itemsPerPage={filters.itemsPerPage} page={page} setPage={setPage} totalPages={totalPages} />
            </div>
        </React.Fragment>
    );
};

export default GenericFilter;
