import CMSTop from "./CMSTop";
import React, {useEffect, useRef, useState} from "react";
import client from "../sanityClient";
import {useLocation} from "react-router-dom";
import {useAuth} from "../Context/AuthContext";

const CmsTopPage = () => {
    const location = useLocation();
    const {token} = useAuth();
    const [cmsData, setCmsData] = useState(false)

    const cache = useRef({});


    useEffect(() => {
        if (token) {
            let codepage = location.pathname;

            if (cache.current[codepage]) {
                setCmsData(cache.current[codepage]);
                return;
            }


            if (!codepage.startsWith("/avis-formation/")) {
                if (codepage && !codepage.startsWith("/page/")) {
                    client.fetch(`
          *[_type == "editorialPage" && codepage == $codepage]{
            title,
            content,
               imageHero,
                    description,
            list[]{
              image,
              text,
              link->{
                _id,
                title
              }
            }
          }
        `, {codepage}).then((data) => {
                        cache.current[codepage] = data[0];
                        setCmsData(data[0]);
                    });
                }
            }
        } else {
            setCmsData(false)
        }
    }, [token, location.pathname]);


    return (cmsData && token) && (
        <CMSTop cmsData={cmsData}/>
    )
}

export default CmsTopPage