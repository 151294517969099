import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import { useMediaQuery } from "@mui/system";
import React from "react";

export default function GenericFilterPagination({setPage, page, totalPages, handleFilterChange, itemsPerPage}) {
    const isMobile = useMediaQuery('(max-width: 600px)');
    return (
        <div className="filter-pagination">
            {setPage && <Pagination count={totalPages} page={page} siblingCount={isMobile ? 0 : 1} boundaryCount={isMobile ? 0 : 1} onChange={(event, value) => setPage(value)} size={isMobile ? 'small' : 'medium'} />}
            {itemsPerPage !== undefined && (
                <FormControl variant="outlined" size="small" sx={{minWidth: 150}}>
                    <InputLabel id='label-elem-per-page'>Éléments par page</InputLabel>
                    <Select
                        labelId='label-elem-per-page'
                        label="Éléments par page"
                        value={itemsPerPage}
                        onChange={(e) => handleFilterChange("itemsPerPage", Number(e.target.value))}
                    >
                        {[10, 20, 40, 80].map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </div>
    )
}
