import React, {useEffect, useState, useCallback} from 'react';
import { Container, Typography,} from '@mui/material';
import {useAuth} from '../Context/AuthContext';
import {useFetchWithToken} from "../hooks/useFetchWithToken"
import useTexts from "../hooks/useTexte";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import useRoles from "../hooks/useRoles";
import './styles/FormationAdminListPage.css';
import GenericFilter from "../components/Filter/GenericFilter";
import TestingDate from "../components/TestingDate";
import FormationAdminListBody from "../components/Formation/FormationAdminListBody";
import LoaderWait from "../components/LoaderWait";
import { loadFormation } from '../Utils/loadFormations';

const FormationAdminListPage = () => {
    const {isLoadingFunction, functionOptions, currentFunction} = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const isAdministrator = roles.includes('Administrator');
    const [isLoadAdmin, setIsloadAdmin] = useState(false);
    const [filters, setFilters] = useState({
        selectedType: "",
        selectedDate: "",
        selectedNom: "",
        showClose: false,
        showOpen: true,
        itemsPerPage: 10, // Afficher ce filtre
    });
    const {t} = useTexts();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [wishes, setWishes] = useState([]);
    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        if (isAdministrator === true) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedBranche: "",
            }));
        }
        setIsloadAdmin(true)
    }, [isAdministrator, setFilters]);

    const handleLoadFormation = useCallback(async () => {
        const { formations, totalPages } = await loadFormation(fetchWithToken, filters, page);
        setWishes(formations);
        setTotalPages(totalPages);
    }, [filters, page, fetchWithToken]);

    const reloadFormations = () => {
        handleLoadFormation();
    };

    useEffect(() => {
        if (filters.init === 1) {
            handleLoadFormation();
        }
    }, [page, filters]);

    const addFormation = async (formation) => {
        try {
            let apiUrl = `${process.env.REACT_APP_API_URL}/api/formation`;
            const data = await fetchWithToken(apiUrl, 'POST', formation);
            loadFormation();
            return data;
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la formation:', error);
        }
    };

    if (!isLoadingFunction || !isLoadAdmin) {
        return (<LoaderWait/>);
    }

    return (
        <Container
            maxWidth="xl"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant='h4' mt={2} gutterBottom>{t('TITRE_PAGE_GERER_CEPS')}</Typography>
            <TestingDate/>
            <GenericFilter filters={filters} setPage={setPage} page={page} setFilters={setFilters} totalPages={totalPages} />
            <FormationAdminListBody loadWishes={loadFormation} formations={wishes} addFormation={addFormation} reloadFormations={reloadFormations} />
        </Container>
    );
};

export default withAdminOrFormationRole(FormationAdminListPage);
