import {Collapse} from "@mui/material";
import WishItem from "./WishItem";
import React from "react";

const VoeuxList = ({userWithWish, isAdmin}) => {
return (
    <Collapse in={true} timeout="auto" unmountOnExit>
        <div className='user-collapse'>
            {userWithWish.formations.map((wish, index) => (
                <WishItem key={`formation-${wish.voeu_id}-${index}`} wish={wish} isAdmin={isAdmin}  user={userWithWish.user} type="formation"/>
            ))}
            {userWithWish.wishes.map((wish, index) => (
                <WishItem key={`voeu-${wish.voeu_id}-${index}`} wish={wish} isAdmin={isAdmin}   user={userWithWish.user} type="voeu"/>
            ))}
        </div>
    </Collapse>
    );
};

export default VoeuxList;
