import { Box } from "@mui/material";
import DerogationConfirme from "./DerogationConfirme";
import React from "react";
import useTexts from "../hooks/useTexte";
import DerogationExportButton from "./DerogationExportButton";
const DeorgationListHead = ({ loadWishes, selectedWishes, setSelectedWishes, filters, page }) => {
    const { t } = useTexts();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <p style={{
                margin: 0,
                padding: 0,
                fontWeight: 'bold',
            }}>
                {t('DEROGATION_DETAIL_NUMBER', selectedWishes.length)}
            </p>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '4px' }}>
                {!filters?.showClose && (
                    <>
                        <DerogationConfirme
                            action="valide"
                            loadWishes={loadWishes}
                            selectedWishes={selectedWishes}
                            setSelectedWishes={setSelectedWishes}
                        />
                        <DerogationConfirme
                            action="refuse"
                            loadWishes={loadWishes}
                            selectedWishes={selectedWishes}
                            setSelectedWishes={setSelectedWishes}
                        />
                    </>
                )}
                <DerogationExportButton filters={filters} page={page} />
            </Box>
        </Box>
    );
};

export default DeorgationListHead;