import useTexts from "./useTexte";


const useMenuConfig = (setDialogOpenSearch) => {
    const {t} = useTexts();

    const baseMenuItems = [
        {
            text: t("MENU_ACTION_FORMATION"),
            permissions: ["User"],
            subItems: [
                {text: t("MENU_MES_VOEUX"), to: "/voeux", permissions: ["User"]},
                {text: t("MENU_MES_FORMATIONS"), to: "/formations", permissions: ["User"]},
            ]
        },
        {
            text: t("MENU_FORMATION"),
            permissions: ["Administrator"],
            subItems: [
                {text: t("MENU_MANAGE_FORMATION"), to: "/gerer-ceps", permissions: ["Administrator"]},
                {text: t("MENU_MANAGE_DEROGATION"), to: "/gerer-derogation", permissions: ["Administrator"]},
                {text: t("MENU_MANAGE_VOEUX"), to: "/gerer-voeux", permissions: ["Administrator"]},
                {text: t("MENU_MANAGE_QUESTIONAIRES"), to: "/template", permissions: ["Administrator"]},
            ]
        },
        {
            text: t("MENU_UTILISATOR"),
            permissions: ["Administrator"],
            subItems: [
                {text: t("MENU_USER"), to: "/gerer-utilisateur", permissions: ["Administrator"]},
                {text: t("MENU_FOREIGN"), to: "/gerer-etranger", permissions: ["Administrator"]},
                {
                    text: t('SWITCH_USER'),
                    to: "#",
                    permissions: ["Administrator"],
                    onClick: () => setDialogOpenSearch(true),
                }
            ]
        },
        {text: t("MENU_FORMATION_RESP"), to: "/gerer-ceps", permissions: ["Responsable_formation"]},
        {text: t("MENU_MANAGE_DEROGATION"), to: "/gerer-derogation", permissions: ["Responsable_formation"]},
        {
            text: t("MENU_CONFIGURATION_SYSTEM"),
            permissions: ["Administrator"],
            subItems: [
                {text: t("MENU_TEXTEEDITOR"), to: "/texteditor", permissions: ["Administrator"], mobile: false},
                {
                    text: t("MENU_MANAGE_CONFIGURATION"),
                    to: "/configuration",
                    permissions: ["Administrator"],
                    mobile: false
                },
                {text: t("MENU_RULES"), to: "/rules", permissions: ["Administrator"]},
                {text: t("MENU_DATES"), to: "/dates", permissions: ["Administrator"]},
                {text: t("MENU_EMAIL"), to: "/email", permissions: ["User", "Administrator"], mobile: false},
                {
                    text: t("MENU_QUESTIONAIRE_POST_FORMATION"),
                    to: "/questionaireeval",
                    permissions: ["Administrator"]
                },


            ]
        },

        {text: t("FORMATION_CALENDAR"), to: "/agenda", permissions: ["Administrator", "Responsable_formation", "User"]},
    ];


    return {
        baseMenuItems
    };
};

export default useMenuConfig;
