import {TableBody} from "@mui/material";
import React from "react";
import UserLogItem from "./UserlogItem";


const UserLogBody = ({logs, selectedPersonne}) => {

    return (
        <TableBody>
            {logs.map((log, index) => (
                <UserLogItem  key={index} log={log} selectedPersonne={selectedPersonne} />
            ))}
        </TableBody>
    )

}

export default UserLogBody;
