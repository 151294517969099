import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import CustomFormBuilder from "./CustomFormBuilder";
import useTexts from "../../hooks/useTexte";
import { useConfirm } from "../../Provider/ConfirmProvider";

const MyFormsTemplate = ({ existFormId, loadForms }) => {
    const [openFormModal, setOpenFormModal] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);
    const { t } = useTexts();
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const { requestConfirm } = useConfirm();

    const handleOpenFormModal = (id) => {
        if (!id) {
            setInitialFormData(false);
        } else {
            setInitialFormData(id);
        }
        setOpenFormModal(true);
    };

    const handleCloseFormModal = () => {
        if (hasUnsavedChangesFiche) {
            // Demander la confirmation si des modifications non sauvegardées sont présentes
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false);
            });
        } else {
            setOpenFormModal(false);
        }
        loadForms();
        setHasUnsavedChangesFiche(false);
    };

    return (
        <>
            {existFormId ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenFormModal(existFormId)}
                >
                    {t("MODIFIER_MY_FORM")}
                </Button>
            ) : (
                <button
                    className="azimuth-btn success"
                    onClick={() => handleOpenFormModal()}
                >
                    {t("ADD_NEW_TEMPLATES")}
                </button>

            )}

            <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="lg" fullWidth>
                <DialogTitle> {initialFormData === false ? (t("ADD_NEW_TEMPLATE ")) : (t("UPDATE_TEMPLATE "))} </DialogTitle>
                <DialogContent>
                    <CustomFormBuilder initialData={initialFormData} isModal={true}
                        closeModal={handleCloseFormModal}
                        user={true}
                        setHasUnsavedChangesParent={setHasUnsavedChangesFiche} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MyFormsTemplate