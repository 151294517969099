import {Button,  InputAdornment, TextField} from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";
import SearchIcon from "@mui/icons-material/Search";

const TextHeader  = ({addText , setNewKey, setNewText  , newKey , newText, searchTable,handleTableSearch }) => {
    const { t } = useTexts();

    return (
        <div className='add-text-container desktop-restrict'>

            <div className='search-table'>
                <TextField
                    id='search-table'
                    label='Rechercher'
                    value={searchTable}
                    onChange={handleTableSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <TextField id='text-input' label="KEY_LABEL" value={newKey} onChange={e => setNewKey(e.target.value)}/>
            <TextField id='text-input' label="Texte" value={newText} onChange={e => setNewText(e.target.value)}/>
            <Button
                variant="contained"
                color="success"
                onClick={addText}
                id='add-text-button'
            >
                {t("BUTTON_ADD_TEXT")}
            </Button>
        </div>
    )
}

export default  TextHeader;