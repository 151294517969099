import React, { useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import useTexts from "../../hooks/useTexte";
import { useAuth } from "../../Context/AuthContext";
import { LevelOptions } from "../../Utils/LevelOption";
import { functionMapping } from "../../Utils/functionMapping";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VoeuxListPage from "../../Page/VoeuxListPage";

const StructureItemChef = ({ structure, chef, callBacks }) => {
    const { t } = useTexts();
    const [displayInscription, setDisplayInscription] = useState(false);

    const allowRules = ["CH", "COM", "COMAD", "CADJ"];
    const avisChef = chef.avis.find(avis => avis.avis_level === 1);
    const avisChef2 = chef.avis.find(avis => avis.avis_level === 2);
    const avisPeda = chef.avis.find(avis => avis.avis_is_peda === 1);
    const { currentFunction, functionOptions } = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);

    const isPedaWrite =
        (structure.section_id === 1 && chefFunction.fonction === 'ASCBJ') ||
        (structure.section_id === 2 && chefFunction.fonction === 'ASCBV') ||
        (structure.section_id === 3 && chefFunction.fonction === 'ASCBV') ||
        (structure.section_id === 4 && chefFunction.fonction === 'ASCBR') ||
        (structure.section_id === 5 && chefFunction.fonction === 'ASCBR');

    const openInscription = () => {
        setDisplayInscription(true);
    };
    const closeInscription = () => {
        setDisplayInscription(false);
    };
    const getMappedFunction = (functionCode) => {
        return functionMapping[functionCode] || functionCode;
    };

    const getFirstButtonText = () => {
        if (structure.type === "Unité Locale") return "Avis du CG";
        if (["Unité Territoriale", "Unité Territoriale facturable", "Groupe"].includes(structure.type)) return "Avis du CD";
        if (structure.type === "District") return "Avis du CPr";
        return "";
    };

    const getFirstButtonColor = () => {
        if (structure.diff === 1) {
            return avisChef ? "success" : "warning";
        }
        return avisChef ? "success" : "warning";
    };

    const getFirstButtonLabel = () => {
        if (structure.diff === 1) {
            return avisChef ? "Modifier" : "Ajouter";
        }
        return avisChef ? "Consulter" : "Non disponible";
    };

    const getSecondButtonText = () => {
        if (structure.type === "Unité Locale") return "Avis du CD";
        if (["Unité Territoriale", "Unité Territoriale facturable", "Groupe"].includes(structure.type)) return "Avis du CPr";
        return "";
    };

    const getSecondButtonColor = () => {
        if (structure.diff === 2) {
            return avisChef2 ? "success" : "warning";
        }
        return avisChef2 ? "success" : "warning";
    };

    const getSecondButtonLabel = () => {
        if (structure.diff === 2) {
            return avisChef2 ? "Modifier" : "Ajouter";
        }
        return avisChef2 ? "Consulter" : "Non disponible";
    };

    const getThirdButtonColor = () => {
        return avisPeda ? "success" : "warning";
    };

    const getThirdButtonLabel = () => {
        if (isPedaWrite) {
            return avisPeda ? "Modifier mon avis" : "Ajouter mon avis";
        }
        return avisPeda ? "Voir l'avis" : "Avis non disponible";
    };

    const renderButton = (text, color, label, onClick) => {
        return(
        <Button
            onClick={onClick}
            variant="contained"
            className={`azimuth-btn ${color}`}
            color={color}
            disabled={label.toLowerCase().includes("non disponible")}
            sx={{ lineHeight: 1.25, }}
        >
            {text && (<>{text} :<br /></>)}
            {label}
        </Button>
    )};

    let highestLevelFormation = null;
    if (Array.isArray(chef.formations)) {
        const formations = chef.formations.filter(f => f.section_id == structure.section_id);
        if (formations.length > 0) {
            const highestLevelFormationSearch = formations.reduce((max, formation) => {
                return (max.level > formation.level) ? max : formation;
            });
            highestLevelFormation = LevelOptions.find(option =>
                option.level === highestLevelFormationSearch.level &&
                option.section.includes(highestLevelFormationSearch.code)
            );
        }
    }

    return (
        <Card>
            <CardContent>
                <div className='chef-infos'>
                    <Typography variant="h6" align="left">
                        {chef.firstname} {chef.lastname} ({getMappedFunction(chef.fonction)})
                    </Typography>
                    <div id='formation'>
                        <Typography variant="p" align="left">
                            Plus haut niveau de formation : {highestLevelFormation ? highestLevelFormation.label : "Pas de formation"}
                        </Typography>
                        <Typography variant="p" align="left">
                            Formation Saint Nicolas : {chef.formation_saint_nicolas ? "Faite " : "à faire"}
                        </Typography>
                    </div>
                </div>

                {!chef.sameunite && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        <div className='actions-de-formations'>
                            <Typography variant="h6" sx={{ textAlign: "left", fontSize: "1.2rem", pl: 1 }}>
                                {t("MAITRISE_ACTIONS_FORMATIONS")}
                            </Typography>
                            <div className='actions-de-formations-boutons'>
                                {renderButton(getFirstButtonText(), getFirstButtonColor(), getFirstButtonLabel(),
                                    () => callBacks.openModalAvisCallBack(chef, structure.diff === 1 && allowRules.includes(chefFunction.fonction), avisChef, "1")
                                )}
                                {structure.type !== "District" && (
                                    <>
                                        {renderButton(getSecondButtonText(), getSecondButtonColor(), getSecondButtonLabel(),
                                            () => callBacks.openModalAvisCallBack(chef, structure.diff === 2 && allowRules.includes(chefFunction.fonction), avisChef2, "2")
                                        )}
                                    </>
                                )}
                                {renderButton("Avis de l'ACD", getThirdButtonColor(), getThirdButtonLabel(),
                                    () => callBacks.openModalAvisCallBack(chef, isPedaWrite, avisPeda, "peda")
                                )}
                            </div>
                        </div>
                        {chef.formationsRegister.length > 0 && (
                            <div style={{ width: 'fit-content' }}>
                                <button className='azimuth-btn' onClick={
                                    () => openInscription()
                                }>
                                    <Typography variant='body1'>{t('SEE_FORMARTION_INSCRIPTION_CHEFS')}</Typography>
                                </button>
                            </div>
                        )}
                    </>
                )}
                <Dialog
                    open={displayInscription}
                    onClose={closeInscription}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="xl"
                >
                    <DialogTitle component={'div'} id="form-dialog-title" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h5" align="left" sx={{ ml: 3}}>Détail des inscriptions de : {chef.firstname} {chef.lastname}</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={closeInscription}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ padding: 24 }}>
                        <VoeuxListPage user_id={chef.id} />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default StructureItemChef;
