import { TableCell } from "@mui/material";
import StagiareCardAdminMove from "./StagiareCardAdminMove";
import StagiareCardAdminDeletor from "./StagiareCardAdminDeletor";
import React from "react";
import { useConfirm } from "../../Provider/ConfirmProvider";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import StagiareCardAdminActionExcludeMoulinette from "./StagiareCardAdminActionExcludeMoulinette";
import StagiareCardAdminActionUnExcludeMoulinette from "./StagiareCardAdminActionUnExcludeMoulinette";
import StagiareCardAdminActionValidate from "./StagiareCardAdminActionValidate";
import StagiareCardAdminActionOfferPlace from "./StagiareCardAdminActionOfferPlace";


const StagiareCardAdminAction = ({ fetchFormations, refreshUser, user, isAdministrator, formation, formations }) => {
    const { requestConfirm } = useConfirm();
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();

    const validateStagiaire = async (type) => {
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE"), async () => {
            try {

                var data = {
                    type: type
                }
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/force`;
                await fetchWithToken(apiUrl, "POST", data);

                refreshUser();
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };
    const allowStagiaireMoulinette = async () => {
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE_MOULINETTE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/allowmoulinette`;
                await fetchWithToken(apiUrl);
                refreshUser();
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };

    const excludeStagiaireMoulinette = async () => {
        requestConfirm(t("CONFIRMATION_REMOVE_STAGIAIRE_MOULINETTE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/excludemoulinette`;
                await fetchWithToken(apiUrl);
                refreshUser();
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };

    return (

        <TableCell>
            <StagiareCardAdminActionOfferPlace user={user} formation={formation} isAdministrator={isAdministrator} validateStagiaire={validateStagiaire} />
            <StagiareCardAdminActionExcludeMoulinette user={user} formation={formation} isAdministrator={isAdministrator} excludeStagiaireMoulinette={excludeStagiaireMoulinette} />
            <StagiareCardAdminActionUnExcludeMoulinette user={user} formation={formation} isAdministrator={isAdministrator} allowStagiaireMoulinette={allowStagiaireMoulinette} />
            <StagiareCardAdminActionValidate user={user} formation={formation} isAdministrator={isAdministrator} validateStagiaire={validateStagiaire} />

            {isAdministrator && user.status === "VALIDATE" && (
                <>
                    <StagiareCardAdminMove
                        user={user}
                        refreshUser={refreshUser}
                        fetchFormations={fetchFormations}
                        formations={formations}
                    />
                    <StagiareCardAdminDeletor user={user} refreshUser={refreshUser} />
                </>
            )}

        </TableCell>
    )

};

export default StagiareCardAdminAction;
