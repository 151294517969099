import {Button, DialogActions} from "@mui/material";
import React from "react";


const MoulinetteModalAction = ({
                                   moulinettePlaces, setOpenMoulinetteDialog, selectedSpecialities, functionCodes,
                                   handleLaunchMoulinette, setLaunchAnimation, selectionType
                         }) => {

    const closeModalAfterAnimation = () => {
        setTimeout(() => {
            setLaunchAnimation(false);
            setOpenMoulinetteDialog(false);
        }, 2000); // Adjust the timeout duration to match your animation length
    };


    return (
        <DialogActions>
            <Button onClick={() => setOpenMoulinetteDialog(false)} color="primary">
                Annuler
            </Button>
            <Button
                onClick={() => {
                    handleLaunchMoulinette(moulinettePlaces, selectionType, selectedSpecialities, functionCodes);
                    setLaunchAnimation(true);
                    closeModalAfterAnimation();
                }}
                color="primary"
            >
                Lancer
            </Button>
        </DialogActions>
    )
};

export default MoulinetteModalAction;
