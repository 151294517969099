import React, { useState } from 'react';
import { Box, Typography, Grid2, Card, CardContent, Collapse } from '@mui/material';
import FeedbackFormModal from "../Feedback/FeedbackFormModal";
import FeedbackForm from "../Feedback/FeedbackForm";
import UserFunctionAvis from "./UserFunctionAvis";
import { ExpandMoreRounded } from '@mui/icons-material';

// Composant principal
const UserFunction = ({ u, loadChef, isPage }) => {
    const [selectedChefType, setSelectedChefType] = useState(null);
    const [openModalAvis, setOpenModalAvis] = useState(false);
    const [currentAvis, setCurrentAvis] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    // Gestion de l'ouverture/fermeture de la modal d'avis
    const handleCloseModalAvis = () => {
        setOpenModalAvis(false);
    };


    const openModalAvisCallBack = (type, current) => {
        setCurrentAvis(current);
        setSelectedChefType(type);
        setOpenModalAvis(true);
    };

    return (
        <Box id="mafiche-user-info" display="flex" flexDirection="column" gap={2} my={2} width="100%">
            <Box display="flex" alignItems="center" gap={1} onClick={() => setIsOpen(!isOpen)} mt={2}>
                {isOpen ? <ExpandMoreRounded /> : <ExpandMoreRounded sx={{ transform: 'rotate(180deg)' }} />}
                <Typography id="unite-title" variant="h6">{u.nom} - {u.fonction}</Typography>
            </Box>
            <Collapse in={isOpen} timeout="auto" orientation='vertical'>
                <UserFunctionAvis openModalAvisCallBack={openModalAvisCallBack} u={u} isPage={isPage} />
                <VoeuxList voeux={u.voeux} />
                <FeedbackFormModal open={openModalAvis} onClose={handleCloseModalAvis}>
                    <FeedbackForm
                        chef={u}
                        diff={selectedChefType}
                        isAdmin={1}
                        editable={1}
                        loadChef={loadChef}
                        currentAvis={currentAvis}
                        onClose={handleCloseModalAvis}
                    />
                </FeedbackFormModal>
            </Collapse>
        </Box>
    );
};

const VoeuxList = ({ voeux }) => (
    <Grid2 xs={12}>
        {voeux.map((wish) => {
            (
                <Card key={wish.voeu_id} sx={{ width: '100%', marginBottom: 2 }}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            gap: 2
                        }}
                    >
                        {/*<DerogationItem wish={wish} ficheMode={1}/>*/}
                    </CardContent>
                </Card>
            )
        })}
    </Grid2>
);

export default UserFunction;
