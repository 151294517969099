import React from "react";
import { useNavigate } from "react-router-dom";

export default function DashCard({ card, link, index, setOpenAvisRecap, setOpenFormationRecap }) {
    const navigate = useNavigate();
    if (link.charAt(0) === '/') {
        return (
            <div key={index} className='dashboard-card' onClick={() => navigate(card.link)}>
                <div className='dashboard-card-header'>
                    <span>{card.title}</span>
                </div>
                <div className='dashboard-card-content'>
                    <card.icon />
                    <p>{card.number}</p>
                </div>
            </div>
        );
    } else if (link === 'avis') {
        return (
            <div key={index} className='dashboard-card' onClick={() => setOpenAvisRecap(true)}>
                <div className='dashboard-card-header'>
                    <span>{card.title}</span>
                </div>
                <div className='dashboard-card-content'>
                    <card.icon />
                    <p>{card.number}</p>
                </div>
            </div>
        )
    } else if (link === 'formation') {
        return (
            <div key={index} className='dashboard-card' onClick={() => setOpenFormationRecap(true)}>
                <div className='dashboard-card-header'>
                    <span>{card.title}</span>
                </div>
                <div className='dashboard-card-content'>
                    <card.icon />
                    <p>{card.number}</p>
                </div>
            </div>
        )
    }
}
