import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";


const PreviewRadio= ({question})=>{
    return (
        <RadioGroup>
            {question.options?.map((option, optionIndex) => (
                <FormControlLabel
                    key={optionIndex}
                    value={option}
                    control={<Radio/>}
                    label={option || `Option ${optionIndex + 1}`}
                />
            ))}
        </RadioGroup>
    )
}
export default PreviewRadio