import React from 'react';
import {useAuth} from './Context/AuthContext';
import {useSystem} from "./Context/System";
import {Route, Routes} from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import AgendaPage from "./Page/AgendaPage";
import AppRoutes from './Routes';
import PrivateRoute from "./components/Core/PrivateRoute";
import LoaderWait from "./components/LoaderWait";

function Main() {
    const {token, currentFunction, functionOptions, authErrorNoFunction} = useAuth();
    const {textes} = useSystem();


    if (!authErrorNoFunction) {
        if (textes.length < 1) {
            return  <LoaderWait />;
        }
        if ((!currentFunction || !functionOptions || textes.length < 1 || functionOptions.length < 1) && token) {
            return  <LoaderWait />;
        }
    }
    return (
        <Routes>
            <Route
                path="/agenda"
                element={
                    token ? (
                        <DefaultLayout>
                            <PrivateRoute>
                                <AgendaPage />
                            </PrivateRoute>
                        </DefaultLayout>
                    ) : (
                        <AgendaPage />
                    )
                }
            />
            {AppRoutes}
            <Route path="*" element={<DefaultLayout><PrivateRoute><h1>404</h1></PrivateRoute></DefaultLayout>} /> {/* route exemple pr la 404 */}
        </Routes>
    )


}

export default Main;
