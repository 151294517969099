import React from 'react';

import {Button,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import {useConfirm} from "../../Provider/ConfirmProvider";
import useTexts from "../../hooks/useTexte";

const StagiareCardAdminDeletor = ({user, refreshUser}) => {
    const {requestConfirm} = useConfirm();
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();

    const deleteFromFormation = async () => {
        requestConfirm(t("CONFIRMATION_DELETE_STAGIAIRE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/delete`;
                await fetchWithToken(apiUrl, 'POST');
                refreshUser();
            } catch (error) {
                console.error('Erreur lors du déplacement du stagiaire:', error);
            }
        });
    };

    return (
        <>
            <Button
                className='azimuth-btn error outlined full-width'
                onClick={() => {
                    deleteFromFormation()
                }}
            >
                Supprimer
            </Button>


        </>

    );
};

export default StagiareCardAdminDeletor;
