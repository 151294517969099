import React, { useState } from 'react';
import { Button, Box, Card, CardContent, CardMedia, Snackbar, Alert } from '@mui/material';
import ListIcon from "@mui/icons-material/List";
import { branchOptions } from '../../Utils/BranchOption';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import useRoles from "../../hooks/useRoles";
import { useAuth } from "../../Context/AuthContext";
import './styles/Formation.css';
import FormationMangerAdminListUser from "./FormationAdminListUser";
import FormationMangerAdminListUserWait from "./FormationAdminListUserWait";
import FormationMangerAdminSelectQuestionaire from "./FormationAdminSelectQuestionaire";
import FormationMangerAdminEdit from "./FormationAdminEdit";
import FormationAdminItemHead from "./FormationAdminItemTableHead";
import FormationAdminItemMoulinette from "./FormationAdminItemMoulinette";

const FormationAdminItem = ({ formation, functionOptions, addFormation, questionnaires, noadmin, reloadFormations }) => {
    const fetchWithToken = useFetchWithToken();
    const { currentFunction } = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const isAdministrator = roles.includes('Administrator');
    const { t } = useTexts();
    const [updatedWish, setUpdatedWish] = useState({ ...formation });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackParams, setSnackParams] = useState({ severity: 'success', message: '' });

    const handleOpenFormation = async (formation) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/open`;
        try {
            await fetchWithToken(apiUrl);
            setUpdatedWish(prevWish => ({
                ...prevWish,
                status: "draft"
            }));
            setSnackParams({ severity: 'success', message: 'Formation ouverte avec succès !' });
            setOpenSnackbar(true);
        } catch (error) {
            setSnackParams({ severity: 'error', message: 'Erreur lors de l\'ouverture de la formation !' });
            console.error(error);
        } finally {
            reloadFormations();
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const branch = branchOptions.find((branch) => formation.branch === branch.key);
    return (
        <>
            <Card className='cep-item' sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{ width: '68px', height: '68px', margin: '-25px auto 10px', zIndex: 1, position: 'relative' }}
                    image={branch.logo}
                />
                <CardContent>
                    <FormationAdminItemHead formation={formation} />
                    {noadmin != 1 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', textAlign: "left" }}>
                            <FormationMangerAdminEdit
                                formation={formation}
                                isAdministrator={isAdministrator}
                                addFormation={addFormation}
                                setUpdatedWish={setUpdatedWish}
                                updatedWish={updatedWish}
                            />
                            <FormationMangerAdminListUser
                                formation={formation}
                                isAdministrator={isAdministrator}
                            />
                            <FormationMangerAdminListUserWait
                                formation={formation}
                                isAdministrator={isAdministrator}
                            />
                            <FormationMangerAdminSelectQuestionaire
                                formation={formation}
                                isAdministrator={isAdministrator}
                                questionnaires={questionnaires}
                            />
                            {isAdministrator && formation.status === "draft" && formation.close == 0 && (
                                <Button
                                    className='azimuth-btn full-width'
                                    variant="contained"
                                    onClick={() => handleOpenFormation(formation)}
                                    startIcon={<ListIcon />}
                                >
                                    {t("BUTTON_OPEN_FORMATION")}
                                </Button>
                            )}
                            <FormationAdminItemMoulinette
                                formation={formation}
                                isAdministrator={isAdministrator}
                                setUpdatedWish={setUpdatedWish}
                            />
                        </Box>
                    )}
                </CardContent>
            </Card>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                key={'bottomleft'}
            >
                <Alert severity={snackParams.severity} sx={{ width: '100%' }}>
                    {snackParams.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default FormationAdminItem;
