import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Typography} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import DocumentCard from "../Core/DocumentCard";
import {downloadDocument} from "../../Utils/downloadDocument";
import {useAuth} from "../../Context/AuthContext";
import './styles/WishItem.css';
// import {LevelOptions} from "../../Utils/LevelOption";
// import {branchOptions} from "../../Utils/BranchOption";

const WishItemStatusFormation = ({ wish, user}) => {
    const [openModalFormation, setOpenModalFormation] = useState(false);
    const [documents, setDocuments] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const {token,} = useAuth()
    const {t} = useTexts();
    // const level = LevelOptions.find((branch) => wish.level === branch.key);
    // const branch = branchOptions.find((branch) => wish.branch === branch.key);
    const [displaybtn, setDisplaybtn] = useState(false);

    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "cg", "formationId": wish.formation_id})
    };

    const getDocs = async () => {
        const body = {
            "iamHiarchie":1,
            "user_id":user.user_id,
        }
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/form/voeu/${wish.formation_id}/docs`;
        try {
            const data = await fetchWithToken(apiUrlDocuments, "POST", body);
            setDocuments(data);
            setDisplaybtn(true)
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    }

    useEffect(() => {
        getDocs();
    }, []);

    const handleDisplayFormData = async () => {
        setOpenModalFormation(true)
    };

    const handleCloseFormData = () => {
        setOpenModalFormation(false)
    };

    const renderFormationStatus = () => {
        return (
            <React.Fragment>
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                    {wish.nom}
                    <span style={{ color: 'green', margin: "5px" }}>
                        <span style={{ color: 'black !important'}}>-</span> {t("IS_IN_FORMATION")}
                    </span>
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, alignItems: 'center' }}>
                    <Typography variant="body2">
                        {t("FORMATION_PLACE")} : {wish.lieu}
                    </Typography>
                    {displaybtn == true && (
                        <Button className='azimuth-btn outlined' style={{ fontSize: '12px', margin: '0', borderRadius: '24px', padding: '0 4px'}} onClick={handleDisplayFormData}>
                            {t("SEE_FORMATION_DATA")}
                        </Button>
                    )}
                </Box>

                <Dialog
                    open={openModalFormation}
                    onClose={handleCloseFormData}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle component={'div'}>
                        <Typography variant="h5" align="center" gutterBottom>
                            {t("DATA_FORMATION")}
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                {t("DOC_IN_FORM")}
                            </Typography>

                            <Grid2 container spacing={2}>
                                {documents.length > 0 ? (
                                    documents.map((doc) => (
                                        <Grid2 item xs={12} sm={6} md={4} key={doc.id} className='doc-card'>
                                            <DocumentCard
                                                document={doc}
                                                onDownload={() => handleDownloadDocument(doc.path)}
                                            />
                                        </Grid2>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        {t("NO_DOC_FOR_THE_MOMMENT_IN_FORM")}

                                    </Typography>
                                )}
                            </Grid2>

                            {/* Details Section */}
                            <Box sx={{ mt: 4 }}>
                                <Typography variant="h6" gutterBottom>
                                    {t("FORMATION_DETAIL")}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        p: 2,
                                        borderRadius: 2,
                                        border: '1px solid #ccc',
                                    }}
                                >
                                    <Typography variant="body1">
                                        <strong> Lieu de la formation : </strong> {wish.lieu}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong> Prix de la formation : </strong> {typeof wish.price === 'number' ? wish.price.toLocaleString('fr-FR', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }) : wish.price}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleCloseFormData}>
                            {t("CLOSE")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
    return renderFormationStatus();
};

export default WishItemStatusFormation;
