import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

const EmailEditorVars = ({selectedTemplate, focusedField, quillRef, setSelectedTemplate}) => {
    const handleVariableInsert = (variableCode) => {
        if (selectedTemplate) {
            if (focusedField === 'template') {
                const quill = quillRef.current.getEditor(); // Obtenir l'instance de quill
                const selection = quill.getSelection();
                if (selection) {
                    const cursorPosition = selection.index;
                    quill.insertText(cursorPosition, `{{${variableCode}}}`);
                    quill.setSelection(cursorPosition + variableCode.length + 4); // Déplacer le curseur après le texte inséré
                }
            } else if (focusedField === 'subject') {
                const subjectField = document.getElementById('subject-field');
                const cursorPosition = subjectField.selectionStart;
                const text = selectedTemplate.subject;
                const newText = text.slice(0, cursorPosition) + `{{${variableCode}}}` + text.slice(cursorPosition);
                setSelectedTemplate((prevTemplate) => ({
                    ...prevTemplate,
                    subject: newText
                }));
            }
        }
    };
    return (
        <Box mt={2} display="flex" flexDirection="column">
            <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="variable-select-label">Insérer une Variable</InputLabel>
                <Select
                    labelId="variable-select-label"
                    onChange={(e) => handleVariableInsert(e.target.value)}
                    label="Insérer une Variable"
                >
                    {selectedTemplate?.vars.map((variable) => (
                        <MenuItem key={variable.code} value={variable.code}>
                            {variable.libelle}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

export default EmailEditorVars;
