import React, {useState} from 'react';
import { Button } from '@mui/material';
import useTexts from "../../hooks/useTexte";
import { RocketLaunchRounded } from "@mui/icons-material";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import MoulinetteModal from "./MoulinetteModal";

const FormationMangerAdminMoulinette = ({ wish, isAdministrator }) => {
    const fetchWithToken = useFetchWithToken();
    const [openMoulinetteDialog, setOpenMoulinetteDialog] = useState(false);
    const { t } = useTexts();

    const handleLaunchMoulinette = async (place, type, specialities, functionCodes) => {
        try {
            if ((place && type)) {
                var data = {
                    place,
                    type,
                    specialities,
                    functionCodes

                }
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/moulinette`;
                await fetchWithToken(apiUrl, "POST", data);
            }
        } catch (error) {
            console.error('Erreur lors du lancement de la moulinette:', error);
        }
    };
    const [launchAnimation, setLaunchAnimation] = useState(false);

    return isAdministrator && wish.level != "cameleon" && wish.close == 0 && (
        <>
            <Button
                className='azimuth-btn full-width'
                variant="contained"
                onClick={() => setOpenMoulinetteDialog(true)}
                startIcon={<RocketLaunchRounded />}
            >
                {t("BUTTON_START_MOULINETTE")}
            </Button>

            <MoulinetteModal
                openMoulinetteDialog={openMoulinetteDialog}
                setOpenMoulinetteDialog={setOpenMoulinetteDialog}
                handleLaunchMoulinette={handleLaunchMoulinette}
                launchAnimation={launchAnimation}
                setLaunchAnimation={setLaunchAnimation}
            />
        </>
    );
};

export default FormationMangerAdminMoulinette;
