import React from "react";
import { Grid2, FormControl, TextField, } from "@mui/material";

const GenericFilterText = ({ filters, handleFilterChange, target, label }) => {
    return (
        <>
            {target in filters && (
                <Grid2 xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label={label}
                            value={filters[target]}
                            onChange={(e) => handleFilterChange(target, e.target.value)}
                        />
                    </FormControl>
                </Grid2>
            )}
        </>
    );
};

export default GenericFilterText;
