/**
 * Load formations from the API and return them and the total number of pages
 * @async
 * @param {function} fetchWithToken - Function to fetch data from the API with a token
 * @param {Object} filters - Filters to apply to the formations
 * @param {number} page - Page number
 * @returns {Promise<{formations: Array, totalPages: number}>}
 */
async function loadFormation(fetchWithToken, filters, page) {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin`;
    const body = {
        branche: filters.selectedBranche,
        date: filters.selectedDate,
        level: filters.selectedType,
        nom: filters.selectedNom,
        itemsPerPage: filters.itemsPerPage,
        showClose: filters.showClose,
        showOpen: filters.showOpen,
        page
    }
    const data = await fetchWithToken(apiUrl, 'POST', body);
    const formations = data.formations;
    const totalPages = data.totalPages;
    return { formations, totalPages };
}

export { loadFormation };