import {Box, IconButton, TextField} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import React from "react";

const TestingDateItemDate = ({index, item, trainingDates,setTrainingDates }) => {
    const handleDateChange = (index, value) => {
        const newDates = [...trainingDates];
        newDates[index].date = value;
        setTrainingDates(newDates);
    };
    const handlePersonsChange = (index, value) => {
        const newDates = [...trainingDates];
        newDates[index].persons = value;
        setTrainingDates(newDates);
    };

    const handleAddDate = () => {
        setTrainingDates([...trainingDates, { date: '', persons: 0 }]);
    };

    const handleRemoveDate = (index) => {
        const newDates = [...trainingDates];
        newDates.splice(index, 1);
        setTrainingDates(newDates);
    };

    return (
       <Box key={index} display="flex" alignItems="center" sx={{mb: 2}}>
           <TextField
               type="date"
               label="Date"
               value={item.date}
               onChange={(e) => handleDateChange(index, e.target.value)}
               fullWidth
               sx={{mr: 1}}
           />
           <TextField
               type="number"
               label="Nombre de Personnes"
               value={item.persons}
               onChange={(e) => handlePersonsChange(index, e.target.value)}
               sx={{width: 150, mr: 1}}
           />
           <IconButton onClick={() => handleAddDate()} color="primary">
               <Add/>
           </IconButton>
           {index > 0 && (
               <IconButton onClick={() => handleRemoveDate(index)} color="secondary">
                   <Remove/>
               </IconButton>
           )}
       </Box>
   )
}
export default TestingDateItemDate