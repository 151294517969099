import { TextField} from "@mui/material";
import React from "react";

const  AzText= ({handleInputChange,sectionIndex , field, fieldValue, readOnly})=>{
    return (
        <TextField
            className="form-element"
            sx={{ mt: 3 }}
            label=' '
            title=' '
            placeholder=' '
            multiline={field.type === 'textarea'}
            rows={field.type === 'textarea' ? 4 : 1}
            variant="standard"
            fullWidth
            value={fieldValue}
            inputProps={{ readOnly: readOnly }}
            onChange={e => handleInputChange(sectionIndex, field.name, e.target.value)}
        />
    );
}

export default  AzText
