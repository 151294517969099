import React from "react";
import {Grid2, FormControl, TextField,} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";

const GenericFilterDateInterval = ({filters, handleFilterChange, interval, label}) => {
    return (
        <>
            {interval in filters && (
                <Grid2 xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <DatePicker
                            label={label}
                            value={filters[interval]}
                            onChange={(e) => handleFilterChange(interval, e)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid2>
            )}
        </>
    );
};

export default GenericFilterDateInterval;
