import React from "react";
import formatFirstName from "../../Utils/formatFirstName";
import VoeuxList from "./VoeuLIst";
import AdminDisplayFiche from "../Formation/AdminDisplayFiche";

const VoeuxListRow = ({usersWithWishes, displayFiche, user_id}) => {

    const formatLastName = (lastName) => {
        return lastName.toUpperCase();
    };

    const formatFunction = (structName, func_name) => {
        return structName + " " + func_name
    };

    return (
        <div className='wishesbyUser'>
            {usersWithWishes && usersWithWishes.map((userWithWish, index) => (
                <div key={index} className='user-wishes'>
                    <div className='user-wishes-header'>
                        {!user_id && (
                            <h3>
                                {formatFirstName(userWithWish.user.firstname)} {formatLastName(userWithWish.user.lastname)}{" - "}
                                {formatFunction(userWithWish.user.structure_nom, userWithWish.user.fonction)}
                            </h3>
                        )}
                        {displayFiche == 1 && (
                            <AdminDisplayFiche user={userWithWish.user}/>
                        )}
                    </div>
                    <VoeuxList isAdmin={0} userWithWish={userWithWish}/>
                </div>
            ))}
        </div>
    );
};

export default VoeuxListRow;
