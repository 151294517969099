import {Divider} from "@mui/material";
import React from "react";
import UserFunction from "./Search/UserFunction";
import useRoles from "../hooks/useRoles";
import {useAuth} from "../Context/AuthContext";

const UserListFunction= ({functions, loadUserFunction, })=>{
    const { currentFunction, functionOptions } = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const isAdministrator = roles.includes('Administrator');

    return functions.map((userf) => (
        <React.Fragment key={userf.id_function}>
            <Divider id="divider" sx={{my: 4}} />
            <UserFunction u={userf} loadChef={loadUserFunction} isPage={isAdministrator} />
        </React.Fragment>
    ));
}
export  default UserListFunction