import React, { useEffect, useState } from 'react';
import { Grid2, Container, Divider, Typography, Box, } from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { useAuth } from "../Context/AuthContext";
import UserListPageInfo from "../components/UserListPageInfo";
import UserListFunction from "../components/UserListFunction";
import UserListFormation from "../components/UserListFormation";
import UserListCamp from "../components/UserListCamp";
import LoaderWait from "../components/LoaderWait";
import UserListDocument from "../components/UserListDocument";
import './styles/MaFiche.css';
import { useMediaQuery } from '@mui/system';

const MafichePage = () => {
    const [functions, setFunctions] = useState([]);
    const [formations, setFormations] = useState([]);
    const [camps, setCamps] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [birthday, setBirthday] = useState("");
    const [email, setEmail] = useState("");
    const [codeSycomore, setCodeSycomore] = useState("");
    const [isFunctionsEmpty, setIsFunctionsEmpty] = useState(false); // Pour gérer l'état de fonctions vides
    const fetchWithToken = useFetchWithToken();
    const { isLoadingFunction } = useAuth();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const loadUserFunction = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user-info`;

            const data = await fetchWithToken(apiUrl, 'POST');
            setFunctions(data.functions);
            setFormations(data.formation);
            setDocuments(data.documents);

            setCamps(data.camps);
            setCodeSycomore(data.user.code_sycomore);
            setEmail(data.user.email);

            setBirthday(data.user.birthday);
            setCodeSycomore(data.functions[0].code_sycomore); // Récupérer l'email à partir de la première fonction
            if (data.functions && data.functions.length > 0) {
                setIsFunctionsEmpty(false); // Si des fonctions sont trouvées, fonctions non vides
            } else {
                setIsFunctionsEmpty(true); // Si la liste est vide, définir à true
            }

        } catch (error) {
            setFunctions([]);
            setFormations([]);
            setCamps([]);
            setCodeSycomore("");
            setEmail("");
            setBirthday("");
            setCodeSycomore(""); // Récupérer l'email à partir de la première fonction
            console.error('Erreur lors du chargement des fonctions:', error);
        }
    };

    useEffect(() => {

        loadUserFunction();

    }, []);

    if (!isLoadingFunction) {
        return (<LoaderWait />);
    }
    return (
        <Container className='mafichepage-container' sx={{ mt: 2, mb: 10 }}>
            <Grid2 item xs={12}>
                <Typography id='fiche-title' variant="h4" textAlign={'center'}>Ma fiche adhérent</Typography>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <UserListPageInfo birthday={birthday} codeSycomore={codeSycomore} email={email} isFunctionsEmpty={isFunctionsEmpty} />
                    <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
                    <UserListDocument documents={documents} isOwner={1} setDocuments={setDocuments} />
                </Box>
                <UserListFunction functions={functions} loadUserFunction={loadUserFunction} />
                <UserListFormation formations={formations} />
                <UserListCamp camps={camps} />
            </Grid2>
        </Container>
    )
}
export default MafichePage;
