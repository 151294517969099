import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Modal, Select, Typography, } from "@mui/material";

import ChangeConfigForm from "./ChangeConfigForm";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import { useAuth } from "../../Context/AuthContext";
import updateWishFunction from "../../Utils/updateFunction";

const ChangeConfigWishDisplayFormModalAction = ({ wish, openChangeFunction, setOpenChangeFunction, realodWish, setSnackbar }) => {
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const { functionOptions } = useAuth();

    const [availableFormations, setAvailableFormations] = useState([]);
    const [newFunction, setNewFunction] = useState('');
    const [specificFormation, setSpecificFormation] = useState(false);
    const [specialtyOption, setSpecialtyOption] = useState(false);
    const [selectedFormations, setSelectedFormations] = useState([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState([]);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const handleChangeFunctionClose = () => setOpenChangeFunction(false);

    const loadAvailableFormations = async () => {
        try {
            if (wish.level && wish.date && wish.branch) {
                const body = {
                    level: wish.level,
                    start_date: wish.date,
                    branche: wish.branch,

                };
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationdate`;
                const data = await fetchWithToken(apiUrl, "POST", body);
                setAvailableFormations(data || []);
            }
        } catch (error) {
            console.error("Erreur lors du chargement des formations :", error);
        }
    };

    const handleChangeFunctionOpen = () => {
        setSpecificFormation(wish.formations.length > 0);
        setSelectedFormations(wish.formations.map((formation) => formation.id));
        setSpecialtyOption(!wish.types.includes("no_preference"));
        setSelectedSpecialties(
            wish.types.includes("no_preference") ? [] : wish.types
        );
        setOpenChangeFunction(true);
    };

    useEffect(() => {
        if (openChangeFunction == true) {
            handleChangeFunctionOpen();
        }
    }, [openChangeFunction]);

    useEffect(() => {
        loadAvailableFormations();
    }, [wish.level, wish.date, wish.branch]);

    const handleSave = async () => {
        try {
            const updateFunctionResult = await updateWishFunction(wish.id, newFunction, fetchWithToken);
            if (!updateFunctionResult.success) {
                console.error(updateFunctionResult.message);
                setSnackBarMessage(updateFunctionResult.message);
                return;
            }

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wish/${wish.id}/update`;
            const requestBody = {
                formation: selectedFormations,
                cepBranchType: selectedSpecialties,
            };

            const response = await fetchWithToken(apiUrl, "POST", requestBody);

            if (response) {
                handleChangeFunctionClose();
                realodWish();
                setSnackBarMessage("Le voeu a été modifié avec succès.");
            } else {
                console.error("Erreur : Aucun résultat retourné par l'API.");
                setSnackBarMessage("Une erreur s'est produite lors de la modification du voeu.");
            }
        } catch (error) {
            console.error("Erreur lors de l'appel à l'API :", error);
            if (error.message) {
                setSnackBarMessage(error.message);
            } else {
                setSnackBarMessage("Une erreur s'est produite lors de la modification du voeu.");
            }
        }
    };

    useEffect(() => {
        if (snackBarMessage) {
            setSnackbar({ open: true, message: snackBarMessage });
        }
    }, [snackBarMessage]);

    return (
        <>
            <Modal
                open={openChangeFunction}
                onClose={handleChangeFunctionClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80vw", // Largeur par défaut pour petits écrans
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        overflow: "auto", // Pour gérer le contenu qui déborde
                        maxHeight: "90vh", // Hauteur maximale pour éviter de sortir de l'écran
                        borderRadius: 2,
                    }}
                >
                    <Box className='change-function'>
                        <h2 id="modal-modal-title">{t("FUNCTION_WISH_CHANGE")}</h2>
                        <p id="modal-modal-description">
                            {t("FUNCTION_WISH_CHANGE_TEXT")}
                        </p>
                        <FormControl
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            <Select
                                value={newFunction}
                                displayEmpty
                                onChange={(e) => setNewFunction(e.target.value)}
                                fullWidth
                            >
                                {functionOptions.map(({ fonction, id, nom }) => (
                                    <MenuItem key={id} value={id}>
                                        {nom} - {fonction}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <ChangeConfigForm
                        availableFormations={availableFormations}
                        specificFormation={specificFormation}
                        setSpecificFormation={setSpecificFormation}
                        selectedFormations={selectedFormations}
                        specialtyOption={specialtyOption}
                        setSpecialtyOption={setSpecialtyOption}
                        selectedSpecialties={selectedSpecialties}
                        setSelectedFormations={setSelectedFormations}
                        setSelectedSpecialties={setSelectedSpecialties}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 2,
                        }}
                    >
                        <button
                            className="azimuth-btn error"
                            onClick={handleChangeFunctionClose}
                        >
                            <Typography variant="body1">FERMER</Typography>
                        </button>
                        <button
                            className="azimuth-btn success"
                            onClick={handleSave}
                        >
                            <Typography variant="body1">VALIDER</Typography>
                        </button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ChangeConfigWishDisplayFormModalAction;
