import {DialogContent, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {branchOptions} from "../Utils/BranchOption";
import {LevelOptions} from "../Utils/LevelOption";
import TestingDateItemDate from "./TestingDateItemDate";
import React from "react";

const TestingDateSelector = ({selectedBranch, setSelectedBranch, selectedLevel, setSelectedLevel, trainingDates, setTrainingDates}) => {
    const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);
    };
   return (
       <DialogContent>
           <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
               <InputLabel id='label-choix-branche'>Choix de la Branche</InputLabel>
               <Select
                   labelId="label-choix-branche"
                   label="Choix de la Branche"
                   value={selectedBranch}
                   onChange={handleBranchChange}
               >
                   {branchOptions.map((option, index) => (
                       <MenuItem key={index} value={option.key}>
                           {option.label}
                       </MenuItem>
                   ))}
               </Select>
           </FormControl>

           <FormControl variant="outlined" fullWidth sx={{mb: 2}}>
               <InputLabel id="label-choix-niveau">Choix du Niveau</InputLabel>
               <Select
                   labelId="label-choix-niveau"
                   label="Choix du Niveau"
                   value={selectedLevel}
                   onChange={(event) => setSelectedLevel(event.target.value)}
                   disabled={!selectedBranch} // Désactiver tant qu'aucune branche n'est sélectionnée
               >
                   {LevelOptions.filter(option => option.section.includes(selectedBranch)).map((option, index) => (
                       <MenuItem key={index} value={option.key}>
                           {option.label}
                       </MenuItem>
                   ))}
               </Select>
           </FormControl>
           {trainingDates.map((item, index) => (
               <TestingDateItemDate key={index} item={item} index={index} setTrainingDates={setTrainingDates} trainingDates={trainingDates}/>
           ))}
       </DialogContent>
    )
}
export default TestingDateSelector