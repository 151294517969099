import FormationMangerAdminMoulinette from "./FormationAdminMoulinette";
import { Button } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import React from "react";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";


const FormationAdminItemMoulinette = ({ formation, isAdministrator, setUpdatedWish }) => {
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts()

    const handleCloseMoulinetteFormation = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/closemoulinette`;
        fetchWithToken(apiUrl).then(() => {
            setUpdatedWish(prevWish => ({
                ...prevWish,
                status: "selected"
            }));
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
            <FormationMangerAdminMoulinette wish={formation} isAdministrator={isAdministrator} />
            {isAdministrator && formation.status === "selecting" && (
                <Button
                    className='azimuth-btn full-width'
                    variant="contained"
                    onClick={() => handleCloseMoulinetteFormation(formation)}
                    startIcon={<CloseRounded />}
                >
                    {t("BUTTON_CLOSE_FORMATION_MOULINETTE")}
                </Button>
            )}
        </div>
    );
};

export default FormationAdminItemMoulinette;
