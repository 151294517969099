import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Container, Typography, Box, Alert } from "@mui/material";
import { useSystem } from "../Context/System";
import AgendaItem from "../components/AgendaItem";
import LoaderWait from "../components/LoaderWait";
import './styles/Agenda.css';
import GenericFilter from '../components/Filter/GenericFilter';

const AgendaPage = () => {
    const [formations, setFormations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { currentYear } = useSystem();

    const [filters, setFilters] = useState({
        selectedBranche: '',
    });

    function removePassedFormations(formations) {
        const today = new Date();
        return formations.filter(formation => new Date(formation.end_date) >= today);
    }

    const fetchFormations = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pubformation`);
            if (!response.ok) throw new Error("Failed to fetch formations");
            const data = await response.json();
            const filteredData = removePassedFormations(data);
            setFormations(filteredData);
        } catch (err) {
            console.error("Erreur lors du chargement des formations :", err);
            setError("Impossible de charger les formations. Veuillez réessayer.");
        }
    }, []);

    const groupedFormations = useMemo(() => {
        return formations.reduce((groups, formation) => {
            const period = formation.nom;
            if (!groups[period]) {
                groups[period] = [];
            }
            groups[period].push(formation);
            groups[period].sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
            return groups;
        }, {});
    }, [formations, filters]);

    if (!currentYear) {
        return null;
    }

    useEffect(() => {
        if (groupedFormations) {
            setLoading(false);
        }
    }, [groupedFormations]);

    useEffect(() => {
        fetchFormations();
    }, [fetchFormations]);

    return (
        <Container className='agenda-page-wrapper'>
            <GenericFilter filters={filters} setFilters={setFilters} />

            {loading && <LoaderWait />}

            {error && <Alert severity="error">{error}</Alert>}

            {!loading && !error && formations.length === 0 && (
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 3 }}>
                    Aucune formation disponible pour le moment.
                </Typography>
            )}

            {!loading && !error && formations.length > 0 && (
                <div className='agenda-container'>
                    {Object.keys(groupedFormations).map(period => (
                        <Box key={period} className='agenda-month-container'>
                            <Typography variant="body2" gutterBottom sx={{ ml: 1, fontWeight: 'bold', fontSize: '24px', color: '#3f51b5' }}>
                                {period} {groupedFormations[period][0].start_date.split('-')[0]}
                            </Typography>
                            <div className='agenda-events-container'>
                                {groupedFormations[period].map((formation, index) => (
                                    <AgendaItem key={index} formation={formation} index={index} filters={filters} />
                                ))}
                            </div>
                        </Box>
                    ))}
                </div>
            )}
        </Container>
    );
};

export default AgendaPage;
