import React from 'react';
import { Box, Checkbox, Collapse, FormControlLabel, Typography } from '@mui/material';
import { CloseRounded, CheckBoxOutlineBlankRounded, WarningRounded, ArrowDropDownRounded } from '@mui/icons-material';
import useTexts from "../../hooks/useTexte";
import './styles/ChangeConfirmForm.css';

const ChangeConfigForm = ({
    wish,
    specificFormation,
    setSpecialtyOption,
    trainingSessions,
    selectedFormations,
    handleFormationChange,
}) => {
    const { t } = useTexts();
    const availableFormations = trainingSessions?.filter(session => session.date === wish.date);
    const eventmode = 1;
    const [openCollapse, setOpenCollapse] = React.useState(false);

    const handleFormationToggle = (id) => {
        if (eventmode === 1) {
            handleFormationChange(id);
        } else {
            handleFormationChange((prev) =>
                prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
            );
        }
        if (!specificFormation) setSpecialtyOption(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {availableFormations?.length > 1 && (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}>
                        <Typography variant="body1">Je souhaite exclure une ou plusieurs formations</Typography>
                        <ArrowDropDownRounded onClick={() => setOpenCollapse(!openCollapse)} sx={{ cursor: 'pointer', transform: openCollapse ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </div>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        {availableFormations?.length > 0 && (
                            <Box sx={{ marginBottom: 2 }}>
                                {/* <Typography variant="body1">{t("CHOOSE_FORMATION")}</Typography> */}
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                    <WarningRounded sx={{ color: 'red', p: 0, m: 0, fontSize: '18px' }} />
                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'red' }}>{t("EXCLUDE_CEP_DISCLAIMER")}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {availableFormations.map((formation) => (
                                        <FormControlLabel
                                            key={formation.id}
                                            control={
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankRounded />}
                                                    checkedIcon={<CloseRounded sx={{ color: 'red' }} />}
                                                    checked={selectedFormations.includes(formation.id)}
                                                    onChange={() => handleFormationToggle(formation.id)}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    className={`line-through-animation ${selectedFormations.includes(formation.id) ? 'line-through' : ''}`}
                                                    sx={{ textDecoration: selectedFormations.includes(formation.id) ? 'line-through' : 'none' }}
                                                >
                                                    {formation.nom} - {formation.lieu}
                                                </Typography>
                                            }
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Collapse>
                </>
            )}
        </Box>
    );
};

export default ChangeConfigForm;