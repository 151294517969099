import { CardContent, Grid2, Typography } from "@mui/material";
import React from "react";
import UserCamp from "./Search/UserCamp";

const UserListCamp = ({ camps }) => {
    return (Array.isArray(camps) && camps.length > 0 &&
        <>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Camp effectué par le chef
            </Typography>

            <Grid2 container spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                {camps.length > 0 && camps.map((userf) => (
                    <Grid2 size={{ xs: 12, md: 6, lg: 4 }} key={userf.id}>
                        <CardContent sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <UserCamp f={userf} />
                        </CardContent>
                    </Grid2>
                ))}
            </Grid2>
        </>
    );
}

export default UserListCamp;