import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, } from '@mui/material';

import "./styles/Formation.css"
import useTexts from "../../hooks/useTexte";
import QuestionnaireIcon from "@mui/icons-material/ListAlt";
import MaFiche from "../Fiche/Mafiche";
import { useConfirm } from "../../Provider/ConfirmProvider";

const FormationMangerItemQuestionaire = ({ formation }) => {
    const [openFormModal, setOpenFormModal] = useState(false);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const { requestConfirm } = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation
    const { t } = useTexts();
    const handleCloseFormModal = () => {
        if (hasUnsavedChangesFiche) {
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false);
            });
        } else {
            setOpenFormModal(false);
        }
        setHasUnsavedChangesFiche(false)
    };

    const handleOpenFormModal = () => {
        setOpenFormModal(true);
    };

    return formation.open_questionaire == 1 && (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'center', cursor: 'pointer' }} onClick={handleOpenFormModal}>
                <QuestionnaireIcon />
                {t("QUESTIONAIRE_STAGIAIRE")}
            </Box>
            <Dialog open={openFormModal} onClose={handleCloseFormModal} className='ficheDialog' fullWidth maxWidth='md'>
                <DialogTitle>Modifier la Fiche</DialogTitle>
                <DialogContent id='ficheDialogContent' sx={{ p: { xs: 0, sm: 2 } }}>
                    <MaFiche id={formation.id} onClose={handleCloseFormModal} />
                </DialogContent>
            </Dialog>
        </>

    )

};

export default FormationMangerItemQuestionaire;
