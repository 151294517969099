import React from "react";
import { Grid2 } from "@mui/material";
import StructureAutocomplete from "../StructureAutocomplete";

const GenericFilterType = ({ filters, handleFilterChange, source, label, target }) => {
    return (
        <>
            {target in filters && (
                <Grid2 xs={12} md={4}>
                    <StructureAutocomplete
                        data={source}
                        selectedData={filters[target]}
                        handleChange={(e) => handleFilterChange(target, e)}
                        label={label}
                    />
                </Grid2>
            )}
        </>
    );
};

export default GenericFilterType;
