import {Card, CardContent, FormControl, Grid, MenuItem, Select, Typography} from "@mui/material";
import React from "react";

const  QuestinaireSectionForm = ({branch, selectedQuestionnaires, handleQuestionnaireChange, questionnaires}) => {

    return (
        <Grid item xs={12} md={6} lg={4} key={branch.id}>
            <Card variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" color="primary">{branch.label}</Typography>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <Select
                            value={selectedQuestionnaires[branch.id] || ""}
                            onChange={(e) => handleQuestionnaireChange(branch.id, e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            {questionnaires.map(questionnaire => (
                                <MenuItem key={questionnaire.id} value={questionnaire.id}>
                                    {questionnaire.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
            </Card>
        </Grid>
    )
}


export default QuestinaireSectionForm