import React from 'react';
import {Box, Tooltip, Typography} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import InfoIcon from '@mui/icons-material/HelpOutlineRounded';
import './styles/WishItem.css';

const WishItemStatusWishDisplay = ({idx, formation}) => {
    const {t} = useTexts();

    let text = "";
    let subtext = "";
    let color = "";

    if (formation.status === "open") {
        text = t("WAIT_ATTRIBUTION");
        color = "black"
    } else if (formation.status === "selecting") {
        text = t("WAIT_LIST_FORMATION_RUN");
        color = "black"
    } else if (formation.status === "selected") {
        text = t("WAIT_LIST_FORMATION");
        subtext = t("WAIT_LIST_FORMATION_SUB_TEXT");
        color = "orange"
    }

    if (formation.close) {
        text = t("WAIT_LIST_FORMATION_CLOSE");
        subtext = t("WAIT_LIST_FORMATION_SUB_TEXT_CLOSE");
        color = "red"
    }

    return (
        <Box key={idx} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: "bold" }}>{formation.nom}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {subtext && (
                    <Tooltip arrow title={
                        <Typography variant='body2'>{subtext}</Typography>
                    }>
                        <InfoIcon fontSize="small" sx={{ ml: 1, cursor: "help" }} />
                    </Tooltip>
                )}
                <Typography variant='body2' sx={{ color: color, margin: "5px" }}>{text}</Typography>
            </Box>
        </Box>
    );
};

export default WishItemStatusWishDisplay;
