import React, { useState } from 'react';
import {Box, Button, Dialog, DialogContent, DialogTitle,} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import DocumentCard from "../Core/DocumentCard";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularIcon from "@mui/icons-material/Description";
import {downloadDocument} from "../../Utils/downloadDocument";
import {useAuth} from "../../Context/AuthContext";


const FormationMangerItemDocument = ({formation}) => {
    const fetchWithToken = useFetchWithToken();
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const {t} = useTexts();
    const {token,} = useAuth()




    const handleOpenDocumentsModal = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/documents`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setDocuments(data);
            setOpenDocumentsModal(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };
    const handleDeleteDocument = async (documentId) => {
        const apiUrlDelete = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/document/${documentId}`;
        try {
            await fetchWithToken(apiUrlDelete, 'DELETE', {});
            handleOpenDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error deleting document:", error);
        }
    };
    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const apiUrlUpload = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/document`;
        try {
            await fetchWithToken(apiUrlUpload, 'POST', formData, 1);
            handleOpenDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error uploading document:", error);
        }
    };
    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "mestre", "formationId": formation.id})
    };

    return (
        <>
            <Button className='azimuth-btn full-width'
                variant="contained"
                startIcon={<CircularIcon />}
                onClick={handleOpenDocumentsModal}
            >
                {t("DOC_STAGIAIRE")}
            </Button>
            <Dialog maxWidth='md' fullWidth open={openDocumentsModal} onClose={handleCloseDocumentsModal}>
                <DialogTitle className='dialog-title'>Documents</DialogTitle>
                <DialogContent className='dialog-content'>
                    <div className='documents-container'>
                        {documents.map((doc, key) => (
                            <DocumentCard
                                key={key}
                                document={doc}
                                onDelete={() => handleDeleteDocument(doc.id)}
                                onDownload={() => handleDownloadDocument(doc.path)}
                            />
                        ))}
                    </div>
                    <Box className='upload-document-container' sx={{marginTop: 2}}>
                        <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])}/>
                        <Button id='send-upload-document' variant="contained" startIcon={<CloudUploadIcon/>}
                                onClick={handleFileUpload}
                                disabled={!selectedFile}>
                            Upload
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default FormationMangerItemDocument;
