import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../Context/AuthContext";
import {useSystem} from "../../../Context/System";
import useRoles from "../../../hooks/useRoles";
import useTexts from "../../../hooks/useTexte";
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {Collapse, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, MenuItem} from "@mui/material";
import HomeIcon from '@mui/icons-material/HomeRounded';
import ExpandLess from '@mui/icons-material/ExpandLessRounded';
import ExpandMore from '@mui/icons-material/ExpandMoreRounded';
import NavbarBlock from "./NavbarBlock";
import NavbarMobile from './NavbarMobile';
import SearchModal from "../../Search/SearchModal";
import './Navbar.css';
import useMenuConfig from "../../../hooks/useMenuConfig";
import { ExpandMoreRounded } from '@mui/icons-material';

function Navbar() {
    const {token, currentFunction, functionOptions, handeRunas, isRunAsFrom, handeRunasEnd} = useAuth();
    const {myFormationAsChef} = useSystem();
    const navigate = useNavigate();
    const theme = useTheme();

    const mediaquery = useMediaQuery(theme.breakpoints.down('md'));
    const {t} = useTexts();

    const [isScrolled, setIsScrolled] = useState(false);
    const [dialogOpenSearch, setDialogOpenSearch] = useState(false)

    // Menu states
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const ChefRoles = useMemo(() => ["CH", "COM", "ASCOM", "COMAD", "CADJ"], []);

    const ChefRolesWidthPeda = useMemo(() => ["CH", "COM", "ASCOM", "ASCBJ", "ASCBV", "ASCBR", "COMAD", "CADJ"], []);
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const {baseMenuItems} = useMenuConfig(setDialogOpenSearch);
    const redirectHome = ()=>{
        navigate("/");
    }

    const runhasend = ()=>{
        handeRunasEnd(redirectHome);
    }

    useEffect(() => {
        if (!mediaquery) {
            setIsNavCollapsed(true);
        }
    }, [mediaquery]);
    useEffect(() => {
        if (isNavCollapsed) {
            setOpenDropdown(null);
        }
    }, [isNavCollapsed]);

    const userCanSeeSection = useCallback((sectionPermissions) => {
        if (!token) {
            return sectionPermissions.includes('guest');
        }
        return sectionPermissions.some(permission => roles.includes(permission));
    }, [token, roles]);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsScrolled(offset > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const mesmaitriseItemVoeux = useMemo(() => ({text: t("MENU_MAITRISES_VOEUX"), to: "/voir-voeux"}), [t]);
    const cepinprovince = useMemo(() => ({text: t("CEP_IN_PROVINCE"), to: "/voir-ceps"}), [t]);

    const mesmaitriseItem = useMemo(() => ({text: t("MENU_MAITRISES"), to: "/mes-maitrises"}), [t]);
    const mesmaitriseFormationItem = useMemo(() => ({text: t("MENU_FOMRATION_MESTRES"), to: "/gerer-mes-ceps"}), [t]);
    const menuItems = useMemo(() => {



        if (myFormationAsChef && myFormationAsChef.length > 0) {

            baseMenuItems.push({
                text: t('MENU_GESTION_FORMATIONS'),
                subItems: [
                    {text: t("MENU_FOMRATION_MESTRES"),to: mesmaitriseFormationItem.to , permissions: ["User"]},
                    {text: t("MENU_MANAGE_MY_QUESTIONAIRES"),to: "/my-template" , permissions: ["User"]},

                ],
                permissions: ["User"]
            });


        }
        let label = "LABEL_MENU_" + chefFunction?.type;
        const myUniteItem = {
                text: t(label),
                subItems: [

                ],
                permissions: ["User"]
            };

        if (chefFunction && ChefRolesWidthPeda.includes(chefFunction.fonction)) {
            myUniteItem.subItems.push({
                text: t('MENU_MAITRISES'),
                to: mesmaitriseItem.to,
                permissions: ["User"],
            });
            myUniteItem.subItems.push({
                text: t('MENU_MAITRISES_VOEUX'),
                to: mesmaitriseItemVoeux.to,
                permissions: ["User"],
            });
        }
        if (chefFunction && ChefRoles.includes(chefFunction.fonction) && chefFunction?.type == "Province") {
            myUniteItem.subItems.push({
                text: t('CEP_IN_PROVINCE'),
                to: cepinprovince.to,
                permissions: ["User"],
            });
        }
        if (myUniteItem.subItems.length){
            baseMenuItems.push(myUniteItem)
        }
        if (userCanSeeSection(["User"]) && isRunAsFrom) {
            baseMenuItems.push({
                text: t('SWITCH_USER_END'),
                to: "#",
                permissions: ["User"],
                onClick: () => runhasend(),
            });
        }

        return baseMenuItems;
    }, [t, userCanSeeSection, isRunAsFrom, setDialogOpenSearch]);

    const handleNavCollapse = useCallback(() => setIsNavCollapsed(!isNavCollapsed), [isNavCollapsed]);

    const handleMouseEnter = (index) => {
        if (index >= 0) {
            setOpenDropdown(index);
        }
    };
    const handleMouseLeave = () => {
        setOpenDropdown(null);
    };

    const renderMenuItems = useCallback((items) => {
        if (!items || !items.length) {
            return null;
        }
        return items.filter(({ permissions }) => userCanSeeSection(permissions)).map((item, index) => {
            const isOpen = openDropdown === index;
            return (
                <div
                    key={item.to || index}
                    className={`dropdown ${item.subItems ? 'dropdown-menu-container' : ''} ${isOpen ? 'open' : ''}`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    {item.subItems ? (
                        <div className='dropdown-subitems'>
                            <p className="dropdown-toggle">
                                {item.text}
                                <ExpandMoreRounded className={`dropdown-caret ${isOpen ? 'rotate' : ''}`} />
                            </p>
                            <div className={`dropdown-menu ${isOpen ? 'open' : 'closed'}`}>
                                {item.subItems.filter(({ permissions }) => userCanSeeSection(permissions)).map((subItem, subIndex) => (
                                    <NavbarBlock
                                        key={subItem.to || `${index}-${subIndex}`}
                                        item={subItem}
                                        setIsNavCollapsed={setIsNavCollapsed}
                                        setOpenDropdown={setOpenDropdown}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <NavbarBlock key={item.to || index} item={item} />
                    )}
                </div>
            );
        });
    }, [userCanSeeSection, openDropdown, handleMouseEnter, handleMouseLeave]);

    function handleMenuItemClick(item, handleClose) {
        if (item.onClick) {
            handleClose();
            if (item.onClick == "setDialogOpenSearch"){
                setDialogOpenSearch(true)
            }else {
                item.onClick();
            }
        } else {
            handleClose();
            setOpenSubMenu(null)
            navigate(item.to);
        }
    }

    function renderMenuItemsMobile(items, handleClose) {
        if (!items) {
            return null;
        } else if (!items.length) {
            return null;
        }
        return items.filter(({permissions}) => userCanSeeSection(permissions)).map((item, index) => {
            const handleSubMenuToggle = () => {
                setOpenSubMenu(openSubMenu === index ? null : index);
            };
            return (
                <div key={item.to || index} className='menu-burger'>
                    <MenuItem onClick={item.subItems ? handleSubMenuToggle : () => {
                        handleMenuItemClick(item, handleClose)
                    }}>
                        {item.text}
                        {item.subItems ? (
                            openSubMenu === index ? <ExpandLess/> : <ExpandMore/>
                        ) : null}
                    </MenuItem>
                    {item.subItems && (
                        <Collapse in={openSubMenu === index} timeout="auto" unmountOnExit
                                  className='menu-burger-subitems'>
                            <List component="div" disablePadding>
                                {item.subItems.filter(({permissions}) => userCanSeeSection(permissions)).map((subItem, subIndex) => (
                                    <ListItem key={subItem.to || `${index}-${subIndex}`}
                                              onClick={() => handleMenuItemClick(subItem, handleClose)}>
                                        <ListItemText primary={subItem.text} className='mb-subitem-text'/>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </div>
            );
        });
    }

    function DesktopNavbar({menuItems}) {
        return (
            <div className="navbar-classic">
                <div className='dropdownMenuIconPetit' onClick={() => navigate("/")}>
                    <HomeIcon style={{cursor: 'pointer'}}/>
                </div>
                {renderMenuItems(menuItems)}
            </div>
        );
    }

    return (
        <div className="navbar-container">
            <nav className={`navbar-default navbar-fixed-width ${isScrolled ? 'navbar-fixed-top' : ''}`}>
                {mediaquery ? (
                    <NavbarMobile
                        isNavCollapsed={isNavCollapsed}
                        handleNavCollapse={handleNavCollapse}
                        menuItems={menuItems}
                        renderMenuItemsMobile={renderMenuItemsMobile}
                    />
                ) : (
                    <DesktopNavbar
                        menuItems={menuItems}
                        chefFunction={chefFunction}
                        ChefRoles={ChefRoles}
                        mesmaitriseItem={mesmaitriseItem}
                        mesmaitriseFormationItem={mesmaitriseFormationItem}
                        myFormationAsChef={myFormationAsChef}
                        isRunAsFrom={isRunAsFrom}
                    />
                )}
            </nav>
            <Dialog
                open={dialogOpenSearch}
                onClose={() => setDialogOpenSearch(false)}
                aria-labelledby="search-dialog-title"
            >
                <DialogTitle id="search-dialog-title">{t("FIELD_MESTRE_CAMP")}</DialogTitle>
                <DialogContent>
                    <SearchModal onClick={(data) => handeRunas(data, setDialogOpenSearch, redirectHome)} functionMode={null}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Navbar;
