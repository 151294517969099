import React, {useEffect, useState} from 'react';
import {Container,} from '@mui/material';
import {useFetchWithToken} from '../hooks/useFetchWithToken';
import './styles/VoeuxListPage.css';
import GenericFilter from "../components/Filter/GenericFilter";
import VoeuxListRow from "../components/Wish/VoeuLIstRow";
import { useParams } from 'react-router-dom';

const VoeuxListPage = ({user_id}) => {
    const fetchWithToken = useFetchWithToken();
    const { id } = useParams();

    const [filters, setFilters] = useState({
        selectedType: "",
        selectedBranche: "",
        selectedPersonne: "",
        selectedStruct: null,
        itemsPerPage: 10,
    });
    const [usersWithWishes, setUsersWithWishes] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);


    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/geetmystructWish`;
            const body = {
                branche: filters.selectedBranche,
                level: filters.selectedType,
                itemsPerPage: filters.itemsPerPage,
                user: user_id || (filters.selectedPersonne ? filters.selectedPersonne.id : null),

                structure: filters.selectedStruct,
                page
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setUsersWithWishes(data.users);
            setTotalPages(data.totalPages);


        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
            setUsersWithWishes([]);
        }
    };

    useEffect(() => {
        if (filters.init === 1 || user_id) {
            loadWishes();
        }
    }, [page, filters]);

    useEffect(() => {
        if (id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                selectedPersonne: {id: id}
            }));
        }
    }, [id]);

    return (
        <Container
            maxWidth="xl"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }} >
            {!user_id && (
                <GenericFilter filters={filters} setPage={setPage} page={page} setFilters={setFilters} totalPages={totalPages}/>
            )}
            <VoeuxListRow user_id={user_id} displayFiche={0} usersWithWishes={usersWithWishes}  />
        </Container>
    );
};

export default VoeuxListPage;
