import { Button } from "@mui/material";
import React from "react";


const StagiareCardAdminActionOfferPlace = ({ user, isAdministrator, formation, validateStagiaire }) => {
    return (isAdministrator && user.status === "WAIT" && formation.close == 0) && (
        <Button
            className="azimuth-btn full-width outlined"
            onClick={() => validateStagiaire("withoutValidate")}
        >
            Proposer une place
        </Button>
    )
};

export default StagiareCardAdminActionOfferPlace;
