import React, { useState } from "react";
import useTexts from "../../hooks/useTexte";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import MyFormsDuplicator from "./MyFormsDuplicator";
import MyFormsPreview from "./MyFormsPreview";

const MyFormsLibrary = ({ loadForms }) => {
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const [templates, setTemplates] = useState([]);
    const [open, setOpen] = useState(false);

    const loadTemplates = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/mytemplates`;
            const data = await fetchWithToken(apiUrl);
            setTemplates(data);
            setOpen(true)
        } catch (error) {
            console.error('Error loading templates:', error);
        }
    };

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <button
                className="azimuth-btn"
                onClick={loadTemplates}
            >
                {t("ADD_QUESTIONNAIRE_FROM_TEMPLATE")}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="template-modal-title"
                aria-describedby="template-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        backgroundColor: 'white',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        borderRadius: '8px'
                    }}
                >
                    <Typography id="template-modal-title" variant="h6" component="h2" gutterBottom>
                        {t("TEMPLATES_LIST")}
                    </Typography>
                    {templates.length > 0 ? (
                        templates.map((template) => (
                            <Box key={template.id} sx={{ marginBottom: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1" gutterBottom>{template.name}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <MyFormsDuplicator loadForms={loadForms} type="ext" selectedFormId={template.id} />
                                    <MyFormsPreview selectedFormId={template.id} />
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body1">{t("NO_TEMPLATES_AVAILABLE")}</Typography>
                    )}
                </Box>
            </Modal>
        </>
    )
}

export default MyFormsLibrary;