import React, { useState } from "react";
import { Divider, Typography } from "@mui/material";
import useTexts from "../../hooks/useTexte";
import ValidateWish from "./ValidateWish";
import WishItemStatusWishDisplay from "./WishItemStatusWishDisplay";
import WishItemDisplayError from "./WishItemDisplayError";
import WishBodyValidate from "./WishBodyValidate";

const WishBody = ({ wish, type, close, formationData, isRequireValidate, onValidate }) => {
    const { t } = useTexts();
    const [openValidation, setOpenValidation] = useState(false);
    const [wishValidation, setWishValidation] = useState(0);

    const renderFormationStatus = () => {
        if (wish.valide) {
            if (!formationData.length) {
                return (
                    <Typography
                        variant="body1"
                        className={"noformation"}
                    >
                        {t("TEXT_NO_FORMATION")}
                    </Typography>
                );
            }
            if (!isRequireValidate) {
                return formationData.map((formation, idx) => {
                    return (<WishItemStatusWishDisplay formation={formation} index={idx} key={idx} idx={idx} />);
                });
            } else {
                let userHasValide = false;
                formationData.map((formation) => {
                    if (formation.statusValidation) {
                        if (formation.statusValidation.find(f => f.role === 1)) {
                            userHasValide = true;
                        }
                    }
                });
                if (!userHasValide) {
                    return (<WishBodyValidate setWishValidation={setWishValidation} wish={wish} setOpenValidation={setOpenValidation} />);
                } else {
                    return (
                        <Typography
                            variant="body1"
                            className={"noformation"}
                        >
                            {t("WAIT_CHEF_VALIDATION")}
                        </Typography>
                    );
                }
            }
        } else {
            return <WishItemDisplayError wish={wish} />
        }
    };

    return (
        <>
            <Divider id='mobile-divider' />
            {close == 0 ? (
                <>
                    {type !== "delete" && (
                        <div>
                            {renderFormationStatus()}
                        </div>
                    )}
                </>
            ) : (
                <div className='wish-formation-status'>
                    {t("VOEU_CLOSE")}
                </div>
            )}
            <ValidateWish openValidation={openValidation} setOpenValidation={setOpenValidation} wishValidation={wishValidation} wish={wish} onValidate={onValidate} />
        </>
    );
}

export default WishBody;
