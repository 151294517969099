import React from "react";
import { calculateEndDate, formatDate } from "../../Utils/functionTransforms";
import { LevelOptions } from "../../Utils/LevelOption";
import { branchOptions } from "../../Utils/BranchOption";

const WishDate = ({ wish }) => {
    const level = LevelOptions.find((branch) => wish.level === branch.key);
    const branch = branchOptions.find((branch) => wish.branch === branch.key);

    return (
        <div className='wish-content-date' style={{ marginBottom: { xs: 2, sm: 0 } }}>
            <div id='date-from-to'>
                <p>du <span id='chip'>{formatDate(wish.date)}</span></p>
                <p>au <span id='chip'>{calculateEndDate(wish.date, branch.key, level.key)}</span></p>
            </div>
        </div>
    );
}

export default WishDate;