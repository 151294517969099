import React from 'react';
import { Typography} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import './styles/WishItem.css';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import WishItemStatusWishDisplay from "./WishItemStatusWishDisplay";
import WishItemStatusWishDisplayWait from "./WishItemStatusWishDisplayWait";
import WishItemDisplayError from "./WishItemDisplayError";

const WishItemStatusWish = ({ wish, formationData, isRequireValidate, formation, loadWishesData, isAdmin }) => {
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const handleClickValidate = async (distance) => {
        if (formation.statusInscription) {
            const body = {
                formation_id: formation.statusInscription.formation_id,
                formation_user_id: formation.statusInscription.formation_user_id,
                distance: distance
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/admin/validate`;
            await fetchWithToken(apiUrl, "POST", body);
            loadWishesData();
        }
    }

    const renderWishStatus = () => {
        if (wish.valide) {
            if (!formationData.length) {
                return (
                    <Typography variant="body1">
                        {t("TEXT_NO_FORMATION")}
                    </Typography>
                );
            }
            if (!isRequireValidate) {
                return formationData.map((formation, idx) => {
                    return (<WishItemStatusWishDisplay idx={idx} key={idx} formation={formation} />);
                });
            } else {
                return <WishItemStatusWishDisplayWait formationData={formationData} formation={formation} isAdmin={isAdmin} handleClickValidate={handleClickValidate}/>
            }
        } else {
            return (<WishItemDisplayError wish={wish} /> );
        }
    };
    return renderWishStatus();

};

export default WishItemStatusWish;
