import {Button, MenuItem, Select, TextField, Typography} from "@mui/material";
import React from "react";
import {cepBranchType} from "../../Utils/CepBranchType";
import useTexts from "../../hooks/useTexte";

const MoulinetteModalRestriction = ({
                                        showRestrictions,
                                        setShowRestrictions,
                                        setFunctionCodes,
                                        functionCodes,
                                        setSelectedSpecialities,
                                        selectedSpecialities
                                    }) => {

    const {t} = useTexts()

    const removeFunctionCodeField = (index) => {
        const newCodes = [...functionCodes];
        newCodes.splice(index, 1);
        setFunctionCodes(newCodes);
    };
    const handleFunctionCodeChange = (value, index) => {
        const newCodes = [...functionCodes];
        newCodes[index] = value;
        setFunctionCodes(newCodes);
    };
    const addFunctionCodeField = () => {
        setFunctionCodes([...functionCodes, ""]);
    };
    const toggleRestrictions = () => {
        if (showRestrictions) {
            // Réinitialise les valeurs des filtres lorsqu'on cache les restrictions
            setFunctionCodes(['']);
            setSelectedSpecialities([]);
        }
        setShowRestrictions(!showRestrictions);
    };
    return (
        <>
            <Button onClick={toggleRestrictions} color="primary" style={{marginTop: 16}}>
                {showRestrictions ? t("HIDE_RESTRICTIONS") : t("SHOW_RESTRICTIONS")}
            </Button>

            {showRestrictions && (
                <div style={{marginTop: 16}}>
                    {/* Ajout des codes fonction */}
                    <Typography variant="subtitle1">{t("MOULINETTE_FUNCTION_CODES")}</Typography>
                    {functionCodes.map((code, index) => (
                        <div key={index} style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                            <TextField
                                margin="dense"
                                label={t("MOULINETTE_FUNCTION_CODE", {number: index + 1})}
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={code}
                                onChange={(e) => handleFunctionCodeChange(e.target.value, index)}
                            />
                            <Button onClick={() => removeFunctionCodeField(index)} color="secondary"
                                    style={{marginLeft: 8}}>
                                {t("REMOVE")}
                            </Button>
                        </div>
                    ))}
                    <Button onClick={addFunctionCodeField} color="primary" variant="contained" style={{marginTop: 8}}>
                        {t("MOULINETTE_ADD_FUNCTION_CODE")}
                    </Button>

                    <Typography variant="subtitle1"
                                style={{marginTop: 16}}>{t("MOULINETTE_SELECT_SPECIALITY")}</Typography>
                    <Select
                        fullWidth
                        multiple
                        value={selectedSpecialities}
                        onChange={(e) => setSelectedSpecialities(e.target.value)}
                        variant="outlined"
                        margin="dense"
                        renderValue={(selected) => selected.map(value => cepBranchType[value]).join(', ')}
                    >
                        <MenuItem value="">
                            <em>{t("NO_SPECIALITY")}</em>
                        </MenuItem>
                        {Object.keys(cepBranchType).map((key) => (
                            <MenuItem key={key} value={key}>
                                {cepBranchType[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
        </>
    )
};

export default MoulinetteModalRestriction;
