import React, {useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle,} from '@mui/material';
import CustomFormBuilder from "../customFormBuilder/CustomFormBuilder";
import QuestionnaireIcon from "@mui/icons-material/ListAlt";
import {useConfirm} from "../../Provider/ConfirmProvider";
import useTexts from "../../hooks/useTexte";


const FormationMangerItemEditQuestionaire = ({formation}) => {
    const [keyModal, setKeyModal] = useState(0); // Utilisé pour forcer la recréation de FormBuilder
    const [initialFormData, setInitialFormData] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation
    const {t} = useTexts();


    const handleOpenFormModal = (id) => {
        setKeyModal(keyModal + 1)
        setInitialFormData(id);
        setOpenFormModal(true);
    };
    const handleCloseFormModal = () => {
        if (hasUnsavedChangesFiche) {
            // Demander la confirmation si des modifications non sauvegardées sont présentes
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false); // Fermer le modal si l'utilisateur confirme
            });
        } else {
            // Fermer le modal directement si aucune modification n'est détectée
            setOpenFormModal(false);
        }
        setHasUnsavedChangesFiche(false)
    };
    return (
        <>

            {formation.questionaire_id != 0 && (
                <Button
                    className='azimuth-btn full-width'
                    variant="contained"
                    onClick={() => handleOpenFormModal(formation.questionaire_id)}
                    startIcon={<QuestionnaireIcon />}
                >
                    {t("QUESTIONAIRE_STAGIAIRE")}
                </Button>
            )}
            <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="lg" fullWidth>
                <DialogTitle>Modifier la Fiche</DialogTitle>
                <DialogContent>
                    <CustomFormBuilder key={keyModal} initialData={initialFormData} isModal={true}
                        closeModal={handleCloseFormModal}
                        setHasUnsavedChangesParent={setHasUnsavedChangesFiche} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default FormationMangerItemEditQuestionaire;
