import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import FeedbackFormHead from "./FeedbackFormHead";

const FeedbackForm = ({ chef, editable, currentAvis, loadChef, structure, selectedChefType, isAdmin, diff, onClose }) => {
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();

    const defaultText = currentAvis?.avis_avis || '';
    const defaultCheckboxState = {
        idontknow: currentAvis?.idontknow || false,
        validateByAnticipation: currentAvis?.validateByAnticipation || false,
    };

    const [feedback, setFeedback] = useState(defaultText);
    const [checkboxState, setCheckboxState] = useState(defaultCheckboxState);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleChange = (event) => setFeedback(event.target.value);

    const handleCheckboxChange = (name) => (event) => {
        setCheckboxState({
            ...checkboxState,
            [name]: event.target.checked,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const cleanFeedback = feedback.trim();

        if (cleanFeedback || Object.values(checkboxState).some((checked) => checked)) {
            const apiUrl = isAdmin
                ? `${process.env.REACT_APP_API_URL}/api/admin/saveAvis`
                : `${process.env.REACT_APP_API_URL}/api/unite/saveAvis`;

            const body = {
                feedback,
                ...checkboxState,
                diff,
                user_id: chef?.chef_id || chef?.id,
                type: selectedChefType,
                user_fonction_id: chef?.id_function || chef?.fonction_id,
            };

            fetchWithToken(apiUrl, 'POST', body).then(() => {
                loadChef();
                onClose();
            });
        } else {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    return (
        <form onSubmit={handleSubmit}>
            <FeedbackFormHead
                feedback={feedback}
                editable={editable}
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                checkboxState={checkboxState}
                structure={structure}
            />
            {editable && (
                <Button type="submit" variant="contained" color="primary">
                    {t("BUTTON_SOUMETTRE")}
                </Button>
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={t("ERROR_AVIS_VIDE")}
            />
        </form>
    );
};

export default FeedbackForm;
