import React, { useEffect, useState } from 'react';
import {Container, Typography, TableContainer, Snackbar,} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import withAdminRole from "../hoc/withAdminRole";
import { Table } from 'react-bootstrap';
import useTexts from '../hooks/useTexte';
import ConfigListHead from "../components/editor/ConfigListHead";
import ConfigListBody from "../components/editor/ConfigListBody";
import ConfigEditorModal from "../components/editor/ConfigEditorModal";

const ConfigEditor = () => {
    const [texts, setTexts] = useState([]);
    const [editText, setEditText] = useState({ id: '', key: '', text: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const fetchTexts = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/configuration`;
            const data = await fetchWithToken(apiUrl, "GET");
            setTexts(data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateText = async (id, key, text) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/configuration/`;
            const updatedTextData = await fetchWithToken(apiUrl, 'PUT', { key, text });
            setTexts(texts.map(t => t.key === key ? updatedTextData : t));
            setIsDialogOpen(false);
            setSnackbarOpen(true);
            setSnackMessage('Texte modifié avec succès');
        } catch (error) {
            console.error(error);
            setSnackbarOpen(true);
            setSnackMessage('Erreur lors de la modification du texte');
        }
    };

    const handleDialogOpen = (text) => {
        setEditText(text);
        setIsDialogOpen(true);
    };

    useEffect(() => {
        fetchTexts();
    }, []);

    return (
        <Container>
            <Typography variant="h4" align='center' gutterBottom >{t('TITRE_CONFIGURATION_SITE')}</Typography>
            <TableContainer sx={{ maxHeight: '100%' }} className='desktop-restrict'>
                <Table size='small'>
                    <ConfigListHead />
                    <ConfigListBody configs={texts} handleDialogOpen={handleDialogOpen} />
                </Table>
            </TableContainer>
            <ConfigEditorModal isDialogOpen={isDialogOpen} editText={editText} setEditText={setEditText} setIsDialogOpen={setIsDialogOpen} updateText={updateText} />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackMessage}
            />
        </Container>
    );
};

export default withAdminRole(ConfigEditor);
