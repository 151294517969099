import React from "react";
import {Grid2, FormControl, InputLabel, Select, MenuItem,} from "@mui/material";
import useTexts from "../../hooks/useTexte";
import {useSystem} from "../../Context/System";

const GenericFilterDate = ({filters, handleFilterChange}) => {
    const {t} = useTexts();
    const {datesGroupe} = useSystem();

    return (
        <>
            {"selectedDate" in filters && (
                <Grid2 xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>

                        <InputLabel id='label-dates'>Dates</InputLabel>
                        <Select
                            labelId="label-dates"
                            label="Dates"
                            value={filters.selectedDate}
                            onChange={(e) => handleFilterChange("selectedDate", e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="">
                                <em>{t("INPUT_SELECT_ALL")}</em>
                            </MenuItem>
                            {datesGroupe.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.nom}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid2>
            )}
        </>
    );
};

export default GenericFilterDate;
