import {Dialog, DialogContent, DialogTitle, Pagination, Table,Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import UserlogFilter from "../components/UserlogFilter";
import UserlogBody from "./UserlogBody";
import UserLogHead from "./UserlogHead";

const UserLog = ({selectedPersonne, dialogOpenLogs, setDialogOpenLogs}) => {
    const [filter, setFilter] = useState("");
    const fetchWithToken = useFetchWithToken();
    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]); // Logs utilisateur
    const [totalLogPages, setTotalLogPages] = useState(1); // Total des pages de logs


    const loadUserLogs = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/user/log?page=${page}`;
            const body = {
                user: selectedPersonne.id,
                dateFrom: filter?.dateFrom || null,
                dateTo: filter?.dateTo || null,
                eventType: filter?.eventType || null,
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setLogs(data.logs);
            setTotalLogPages(data.totalPages);
        } catch (error) {
            console.error('Erreur lors du chargement des logs:', error);
        }
    };
    useEffect(() => {
        if (dialogOpenLogs) {
            loadUserLogs();
        }
    }, [dialogOpenLogs,  filter]);

    return (
        <Dialog open={dialogOpenLogs} onClose={() => setDialogOpenLogs(false)} fullWidth maxWidth="md" >
            <DialogTitle>Journal de l'utilisateur</DialogTitle>

            <DialogContent sx={{ overflowY: 'visible'}}>
                <>
                    <UserlogFilter setFilter={setFilter}/>
                    {logs.length > 0 ? (

                        <>
                            <Table>
                                <UserLogHead />
                                <UserlogBody  logs={logs} selectedPersonne={selectedPersonne} />
                            </Table>
                            <Pagination
                                count={totalLogPages}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                className="pagination-container"
                                style={{margin: '20px 0', display: 'flex', justifyContent: 'center'}}
                            />
                        </>
                    ) : (
                        <Typography>Aucun log trouvé.</Typography>
                    )}

                </>
            </DialogContent>
        </Dialog>

    )

}

export default UserLog;
