import React from 'react';
import {Typography, Tooltip} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import './styles/WishItem.css';
import {formatErrorsArray} from "../../Utils/formatErrors";
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';

export default function WishItemDisplayError({wish}) {
    const {t} = useTexts();
    return (
        <Typography
            sx={{fontWeight: 'bold'}}
            variant="body1"
            color='warning'
            className="requirevalidation"
        >
            <Tooltip
                className="tooltip"
                arrow
                title={
                    wish.invalide_data && wish.invalide_data.length > 0 && (
                        formatErrorsArray(wish.invalide_data, t, true).map((error, index) => (
                            <Typography variant='body2' key={index}>{error}</Typography>
                        ))
                    )
                }
            >
                <HelpIcon sx={{ cursor: 'help'}} />
            </Tooltip>
            {wish.is_derogation_status == 2 ? (
                t("TEXT_INSCRIPTON_NEED_DEROGATION_LIST_REFUSE")
            ) : (
                t("TEXT_INSCRIPTON_NEED_DEROGATION_LIST")
            )}
        </Typography>
    );
}
