import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, CardMedia,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken"
import {branchOptions} from "../../Utils/BranchOption";
import './styles/FormationManagerItem.css'
import FormationMangerItemTeam from "./FormationManagerItemTeam";
import FormationMangerItemEditQuestionaire from "./FormationManagerItemEditQuestionaire";
import FormationMangerItemOpenQuestionaire from "./FormationManagerItemOpenQuestionaire";
import FormationMangerItemSelectQuestionaire from "./FormationManagerItemSelectQuestionaire";
import FormationMangerItemListUser from "./FormationManagerItemListUser";
import FormationMangerItemDocument from "./FormationManagerItemDocument";
import FormationMangerItemInfo from "./FormationManagerItemInfo";

const FormationMangerItem = ({formation, questionnaires, callBack}) => {
    const fetchWithToken = useFetchWithToken();
    const [teams, setTeams] = useState([]); // Équipes liées à la formation
    const fetchTeams = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/teams`;
            const data = await fetchWithToken(apiUrl);
            setTeams(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des équipes :", error);
        }
    };
    const [nbrStagiaire, setNbrStagiaire] = useState(false);
    const fetchData = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/data`;
            const data = await fetchWithToken(apiUrl, "POST", {});
            setNbrStagiaire(data.nbrStgaire);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image :', error);
        }
    };
    useEffect(() => {
        fetchData()
        fetchTeams();
    }, []);

    const branch = branchOptions.find((branch) => formation.section_id === branch.id)

    return (
        <>
            <Card className='cep-item' sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{width: '60px', height: '60px', margin: '-25px auto 10px', zIndex: 1, position: 'relative'}}
                    image={branch.logo}

                />
                <CardContent>
                    <FormationMangerItemInfo formation={formation} nbrStagiaire={nbrStagiaire} />
                    <Box className='cep-action-button'>
                        <FormationMangerItemDocument formation={formation} />
                        <FormationMangerItemListUser formation={formation} teams={teams} />
                        <FormationMangerItemEditQuestionaire formation={formation} />
                        <FormationMangerItemOpenQuestionaire formation={formation} callBack={callBack} />
                        <FormationMangerItemSelectQuestionaire formation={formation} callBack={callBack} questionnaires={questionnaires} />
                        <FormationMangerItemTeam formation={formation} teams={teams} fetchTeams={fetchTeams} />
                    </Box>
                </CardContent>
            </Card>


        </>
    );
};

export default FormationMangerItem;
