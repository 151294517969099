import React, {useState} from 'react';
import {Button,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import CircularIcon from "@mui/icons-material/Description";
import FormationForm from "./FormationForm";

const FormationMangerAdminEdit = ({formation, isAdministrator, addFormation, updatedWish, setUpdatedWish}) => {
    const fetchWithToken = useFetchWithToken();
    const [editMode, setEditMode] = useState(false);

    const handleOpenModalEdit = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}`;
        const data = await fetchWithToken(apiUrl);
        setUpdatedWish(data);
        setEditMode(true)
    };

    return isAdministrator && formation.close == 0 && (
        <>
            <Button
                className='azimuth-btn full-width'
                startIcon={<CircularIcon />}
                sx={{ width: '100%', textAlign: 'left' }}
                onClick={handleOpenModalEdit}
            >
                Modifier la formation
            </Button>

            <FormationForm
                open={editMode}
                onClose={() => setEditMode(false)}
                onAdd={addFormation}
                initialData={updatedWish}
            />
        </>
    );
};

export default FormationMangerAdminEdit;
