import React from 'react';
import {Button, Typography} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import './styles/WishItem.css';
import {CloseRounded} from "@mui/icons-material";

const WishItemStatusWishDisplayWaitHasToValidate = ({label, isAdmin, handleClickValidate, level}) => {
    const {t} = useTexts();

    return (

        <Typography
            sx={{
                display: {xs: 'block', sm: 'flex'},
                fontFamily: "ubuntu",
                alignItems: 'center',
                gap: 1
            }}
        >
                        <span
                            style={{
                                color: 'orange',
                                marginRight: '4px',
                                alignItems: 'center',
                                borderRadius: '5px',
                                display: 'flex',
                                gap: '2px'
                            }}
                        >
                            <CloseRounded color="warning" sx={{marginRight: '8px'}}/>
                            {t(label)}
                            {isAdmin && (
                                <Button variant="outlined" color="primary" fullWidth
                                        onClick={() => handleClickValidate(level)}
                                >
                                    {t("VALIDATE_A_LA_PLACE")}
                                </Button>

                            )}
                        </span>

        </Typography>
    )

};

export default WishItemStatusWishDisplayWaitHasToValidate;
