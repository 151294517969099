import React, {useEffect, useState, useCallback} from 'react';
import { Container, Typography,} from '@mui/material';
import {useAuth} from '../Context/AuthContext';
import {useFetchWithToken} from "../hooks/useFetchWithToken"
import useTexts from "../hooks/useTexte";
import './styles/FormationAdminListPage.css';
import GenericFilter from "../components/Filter/GenericFilter";
import TestingDate from "../components/TestingDate";
import FormationAdminListBody from "../components/Formation/FormationAdminListBody";
import LoaderWait from "../components/LoaderWait";

const FormationProvinceListPage = () => {
    const { isLoadingFunction } = useAuth();
    const [filters, setFilters] = useState({
        selectedType: "",
        selectedDate: "",
        selectedNom: "",
        showClose: false,
        showOpen: true,
        itemsPerPage: 10, // Afficher ce filtre
    });
    const { t } = useTexts();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [wishes, setWishes] = useState([]);
    const fetchWithToken = useFetchWithToken();

    const loadWishes = useCallback(async () => {
        try {
            const body = {
                branche: filters.selectedBranche,
                date: filters.selectedDate,
                level: filters.selectedType,
                nom: filters.selectedNom,
                itemsPerPage: filters.itemsPerPage,
                showClose: filters.showClose,
                showOpen: filters.showOpen,
                page
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsinprovince`;
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setWishes(data.formations);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Erreur lors du chargement des formations:', error);
        }

    }, [filters, page, fetchWithToken]); // Ajout des dépendances


    useEffect(() => {
        if (filters.init === 1) {
            loadWishes();
        }
    }, [page, filters]);

    if (!isLoadingFunction ) {
        return (<LoaderWait/>);
    }

    return (
        <Container
            maxWidth="xl"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <TestingDate/>
            <Typography variant='h4' gutterBottom>{t('TITRE_PAGE_GERER_CEPS')}</Typography>
            <GenericFilter filters={filters} setPage={setPage} page={page} setFilters={setFilters} totalPages={totalPages} />
            <FormationAdminListBody noadmin={1} loadWishes={loadWishes} wishes={wishes}  />
        </Container>
    );
};

export default FormationProvinceListPage;
