import { Box, Button, Divider, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, {useState} from "react";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import './styles/FormationMangerItemMyDocBtn.css';
import useTexts from "../../hooks/useTexte";

const FormationMangerItemMyDocBtn = ({ selectedFile, setSelectedFile, formation, handleUploadDocumentsModal }) => {
    const fetchWithToken = useFetchWithToken();
    const [confirmCheckbox, setConfirmCheckbox] = useState(false); // État de la checkbox
    const [error, setError] = useState(null);
    const { t } = useTexts();

    const handleFileUpload = async () => {
        if (!confirmCheckbox) {
            alert("Veuillez confirmer que le document ne contient pas d'information de santé.");
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        let apiUrlUpload = "";

        if (formation) {
            apiUrlUpload = `${process.env.REACT_APP_API_URL}/api/form/voeu/${formation.id}/addDoc`;
        } else {
            apiUrlUpload = `${process.env.REACT_APP_API_URL}/api/user/savedoc`
        }

        try {
            await fetchWithToken(apiUrlUpload, 'POST', formData, 1);
            handleUploadDocumentsModal();
        } catch (error) {
            setError(error);
            console.error("Error uploading document:", error);
        }
    };

    return (
        <Box sx={{ marginTop: 2 }} className="FormationMangerItemMyDocBtn">
            <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
            <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={handleFileUpload}
                disabled={!selectedFile}
            >
                Importer
            </Button>
            {error && (
                <Typography variant="body2">
                    Erreur lors de l'importation du document. Veuillez réessayer.
                </Typography>
            )}
            <Divider id="divider" orientation="horizontal" flexItem sx={{ my: 2}} />
            <Box id='sanity-confirm' sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <input
                    type="checkbox"
                    checked={confirmCheckbox}
                    onChange={(e) => setConfirmCheckbox(e.target.checked)}
                />
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'red' }}>
                    {t("WARNING_UPLOAD_SANTE")}
                </Typography>
            </Box>
        </Box>
    );
};

export default FormationMangerItemMyDocBtn;
