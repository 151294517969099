import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import CompetencesType from "./types/CompetencesType";
import DefaultType from "./types/DefaultType";

const Question = ({
                      question,
                      sectionIndex,
                      questionIndex,
                      updateQuestion,
                      updateOption,
                      removeOption,
                      addOption,
                      removeQuestion,
                      questionTypes,
                      addCompetence,
                      updateCompetence,
                      removeCompetence,
                      addLevel,
                      updateLevel,
                      removeLevel,
                      moveUpOption,
                      moveDownOption,
                  }) => {
    return (
        <Box sx={{marginBottom: 2}}>
            <TextField
                label="Question"
                fullWidth
                value={question.label || ''}
                onChange={(e) =>
                    updateQuestion(sectionIndex, questionIndex, {
                        ...question,
                        label: e.target.value,
                    })
                }
                sx={{marginBottom: 2}}
                variant='filled'
            />

            <TextField
                select
                label="Type de question"
                value={question.type}
                onChange={(e) =>
                    updateQuestion(sectionIndex, questionIndex, {
                        ...question,
                        type: e.target.value,
                    })
                }
                fullWidth
                sx={{marginBottom: 2}}
            >
                {questionTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <span id='questionTypeLabel'>{option.icon}</span>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            {['radio', 'checkbox', 'select'].includes(question.type) && (
              <DefaultType questionIndex={questionIndex} question={question}  sectionIndex={sectionIndex} addOption={addOption} moveDownOption={moveDownOption} moveUpOption={moveUpOption}
              removeOption={removeOption} updateOption={updateOption}
              />
            )}

            {question.type === 'competences' && (
                <CompetencesType sectionIndex={sectionIndex}
                                 questionIndex={questionIndex}
                                 question={question}
                                 addCompetence={addCompetence}
                                 addLevel={addLevel}
                                 removeLevel={removeLevel}
                                 removeCompetence={removeCompetence}
                                 updateCompetence={updateCompetence}
                                 updateLevel={updateLevel}
                />
            )}

            <Button
                variant="outlined"
                color="error"
                onClick={() => removeQuestion(sectionIndex, questionIndex)}
                sx={{mt: 2}}
                startIcon={<DeleteIcon/>}
            >
                Supprimer la question
            </Button>
        </Box>
    );
};

export default Question;
