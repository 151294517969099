import { Box, Checkbox, FormControlLabel, FormGroup, Grid2, TextField } from "@mui/material";
import { statuses } from "../../Utils/Status";
import React from "react";

import FormationAdminListUserFormAction from "./FormationAdminListUserFormAction";


const FormationAdminListUserForm = ({ filters, setFilters, wish, isAdministrator, handleListUser }) => {

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            status: {
                ...prevFilters.status,
                [name]: checked,
            },
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleCodeSycomoreChange = (e) => {
        setFilters({
            ...filters,
            codeSycomore: e.target.value,
        });
    };

    const handleFilterByname = (e) => {
        setFilters({
            ...filters,
            selectedName: e.target.value,
        });
    };

    const handleChangeHide = (e) => {
        const { checked } = e.target;
        setFilters({
            ...filters,
            hideInWait: checked,
        });
    };

    return (
        <form onSubmit={handleSubmit} className="formation-admin-list-user-form">
            <Grid2 container spacing={2}>
                <Grid2 size={'grow'}>
                    <FormGroup>
                        {statuses.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={filters.status[option.value] || false}
                                        onChange={handleChange}
                                        name={option.value}
                                    />
                                }
                                label={option.label}
                            />
                        ))}
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            key="hideInWait"
                            control={
                                <Checkbox
                                    checked={filters.hideInWait ? true : false} // initially 1 or 0 so we need to convert to boolean
                                    onChange={handleChangeHide}
                                    name={"hideInWait"}
                                />
                            }
                            label={"Cacher en attente sur une autre formation"}
                        />
                    </FormGroup>
                </Grid2>
                <Grid2 size={'grow'}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <TextField
                            fullWidth
                            label="Filtre par code Sycomore"
                            name="codeSycomore"
                            value={filters.codeSycomore}
                            onChange={handleCodeSycomoreChange}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Filtre par NOM"
                            name="selectedName"
                            value={filters.selectedName}
                            onChange={(e) => handleFilterByname(e)}
                            variant="outlined"
                        />
                    </Box>
                </Grid2>
                <Grid2 size={'grow'}>
                    {isAdministrator && wish.close == 0 && (
                        <FormationAdminListUserFormAction wish={wish} handleListUser={handleListUser} />
                    )}
                </Grid2>
            </Grid2>
        </form>
    )
};

export default FormationAdminListUserForm;
