import React from "react";
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {branchOptions} from "../../Utils/BranchOption";
import {formatDate} from "../../Utils/functionTransforms";


const UserFormations = ({f}) => {
    const branch = branchOptions.find((option) => f.section_id === option.id);

    const getRoleText = (roleValue) => (roleValue ? "Oui" : "Non");

    return (
        <Card sx={{boxShadow: 3, borderRadius: 3, width: '100%'}}>
            <Box display="flex" justifyContent="center" p={2}>
                <CardMedia
                    component="img"
                    sx={{width: 64, height: 64, objectFit: 'contain'}}
                    image={branch.logo}
                    alt={branch.name}
                />
            </Box>
            <CardContent sx={{textAlign: 'center'}}>
                <Typography variant="body2" color="textSecondary">
                    {f.nom}
                </Typography>

                <Typography variant="body2" color="textSecondary">
                    Date début : {formatDate(f.date_debut)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Date fin : {formatDate(f.date_fin)}
                </Typography>

                {/* Display the roles */}
                <Typography variant="body2" color="textSecondary">
                    Est directeur de camp : {getRoleText(f.is_directeur)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Est intendant : {getRoleText(f.est_intendant)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Est responsable sanitaire : {getRoleText(f.est_responsable_sanitaire)}
                </Typography>


            </CardContent>
        </Card>
    );
};

export default UserFormations;
