import React, {useState} from 'react';
import {Button, Grid, Dialog, DialogTitle, DialogContent} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import DocumentCard from "../Core/DocumentCard";
import {downloadDocument} from "../../Utils/downloadDocument";
import {useAuth} from "../../Context/AuthContext";
import './styles/StagiaireCard.css';


const StagiareCardDocument = ({user}) => {
    const fetchWithToken = useFetchWithToken();
    const [documents, setDocuments] = useState(false);
    const {token,} = useAuth()

    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);

    async function loadDocuments() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_id}/${user.formation_user_id}/documents`;
            const data = await fetchWithToken(apiUrl, "POST");
            setDocuments(data);
            setOpenDocumentsModal(true)
        } catch (error) {
            console.error('Erreur lors de la récupération des documents :', error);
        }
    }

    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "mestre2", "formationId": user.formation_id});
    };
    return (
        <>
            <Button variant="outlined" color="primary" onClick={() => loadDocuments()}>
                Voir les documents
            </Button>
            <Dialog open={openDocumentsModal} onClose={handleCloseDocumentsModal} maxWidth="lg" fullWidth>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {documents && documents.map((doc) => (
                            <Grid item xs={12} sm={6} md={4} key={doc.id}>
                                <DocumentCard document={doc}
                                              onDownload={() => handleDownloadDocument(doc.path)}/>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );

};
export default StagiareCardDocument;
