import React, {useState} from 'react';
import {useAuth} from "../../Context/AuthContext";
import {useNavigate} from 'react-router-dom';
import {transformFunctionOption} from "../../Utils/functionTransforms";
import {useSystem} from "../../Context/System";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import './styles/HeaderProfile.css';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HeaderProfileFunctionSelector from "./HeaderProfileFunctionSelector";

function HeaderProfile() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const {
        logout,
        currentFunction,
        functionOptions,
        runAsStart
    } = useAuth();
    const {userData} = useSystem();
    const navigate = useNavigate();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);

    if (runAsStart === true) {
        return <></>
    }
    if (!chefFunction && functionOptions.length && runAsStart != true) {
        logout(); // Appeler la fonction de logout
        navigate('/login', {state: null}); // Rediriger en purgant location.state
    }

    const handleMenu = (event) => {
        if (isMenuOpen)
            setAnchorEl(null);
        else
            setAnchorEl(event.currentTarget);
        setIsMenuOpen(!isMenuOpen);
    };

    if (!chefFunction) {
        return <></>;
    }

    return (
        <div className='headerProfile-container'>
            <div className='headerProfile-name' onClick={handleMenu}>
                {userData.firstname} {userData.lastname}
                <div id="_divLoginRole" className="row profil-login-role">
                    {transformFunctionOption(chefFunction)}
                </div>
                {isMenuOpen ?
                    <ArrowUpIcon className='headerProfile-arrowUp'/>
                    :
                    <ArrowDownIcon className='headerProfile-arrowDown'/>
                }
            </div>

            <div className='headerMenu-responsive'>
                <AccountCircleRoundedIcon onClick={handleMenu}/>


            </div>
            <HeaderProfileFunctionSelector anchorEl={anchorEl} handleMenu={handleMenu} isMenuOpen={isMenuOpen}/>

        </div>
    );

}

export default HeaderProfile;
