import React  from 'react';

import { IconButton} from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import { FaLinkedin } from 'react-icons/fa';
import {useLocation} from "react-router-dom";
import {useSystem} from "../../Context/System";
import useTexts from "../../hooks/useTexte";


const ListProvider = () => {
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';
    const { getConfiguration } = useSystem();
    const aideUrl = getConfiguration('URL_AIDE');
    const { t } = useTexts();

    const oauthProviders = [
        { name: 'Google ', url: `${process.env.REACT_APP_API_URL}/auth/google?redirect=${from}`, icon: <GoogleIcon color='#FFF' /> },
        { name: 'LinkedIn', url: `${process.env.REACT_APP_API_URL}/auth/linkedin?redirect=${from}`, icon: <FaLinkedin /> },
        { name: 'Yahoo', url: `${process.env.REACT_APP_API_URL}/auth/yahoo?redirect=${from}` },
        { name: 'Amazon', url: `${process.env.REACT_APP_API_URL}/auth/amazon?redirect=${from}` },
    ];


    const handleOAuthLogin = (provider) => {
        const providerSearch = oauthProviders.find(p => p.name === provider);
        if (providerSearch.url) {
            window.location.href = providerSearch.url;
        }
        if (providerSearch.callBack) {
            providerSearch.callBack();
        }
    };

    return (
        <>
            <p style={{ paddingBottom: '0px', marginBottom: '4px', fontSize: '18px' }}>S'identifier via :</p>
            {oauthProviders.map(provider => (
                <div className={`provider-item ${provider.name}`} onClick={() => handleOAuthLogin(provider.name)} key={provider.name}>
                    <IconButton
                        key={provider.name}
                        sx={{ margin: 1 }}
                        color='inherit'
                        onClick={() => handleOAuthLogin(provider.name)}
                    >
                        {provider.icon}
                    </IconButton>
                    <p className={`provider-label`}>{provider.name}</p>
                </div>
            ))}
            <a href={aideUrl} target='_blank'>
                {t('AIDE_CONNEXION')}
            </a>
        </>
    )
};

export default ListProvider;
