import React from 'react';
import WishListTable from "./WishListTable";
import { Box, Typography } from '@mui/material';

const WishList = ({ filteredWishes, loadWishes, setModalOpenValidateRemoveId, setModalOpenValidateRemove }) => {
    const onDeleteWish = async (voeuId) => {
        setModalOpenValidateRemoveId(voeuId);
        setModalOpenValidateRemove(true);
    };

    return (
        <Box id='wishList' sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {filteredWishes.length > 0 ? (
                filteredWishes.map((wish, index) => (
                    <WishListTable
                        wish={wish}
                        key={wish.voeu_id}
                        index={index}
                        close={wish.close}
                        realodWish={loadWishes}
                        onDelete={onDeleteWish}
                    />
                ))
            ) : (
                <Typography variant="h5" sx={{ textAlign: 'center', width: '100%' }}>
                    Aucun vœu trouvé
                </Typography>
            )}
        </Box>
    )
};

export default WishList;
