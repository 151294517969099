import React, {useEffect, useState} from 'react';
import {Typography, Box} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import {useParams} from "react-router-dom";
import QuestionairePostFormationWelcome from "./QuestionairePostFormationWelcome";
import QuestionairePostFormationForm from "./QuestionairePostFormationForm";
import LoaderWait from "../LoaderWait";

function QuestionairePostFormation() {
    const [user, setUser] = useState(null);
    const [formation, setFormation] = useState(null);

    const fetchWithToken = useFetchWithToken();
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const {link, user_id, formation_id} = useParams();
    const {t} = useTexts();

    useEffect(() => {
            setSubmissionError("")
    }, [formState]);

    const loadWishesDetail = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/getformeval/${link}/${user_id}/${formation_id}`;
            const data = await fetchWithToken(apiUrl);

            const newForm = {sections: []};
            for (const key in data.data) {
                const section = data.data[key];
                newForm.sections.push(section);
            }
            setFormation(data.formation)
            setUser(data.user)
            setFormData(newForm);
            setFormState(data.answer || {});
            setIsLoadingDetail(false);
        } catch {
            setHasError(true);
            setIsLoadingDetail(false);
        }
    };

    useEffect(() => {
        loadWishesDetail();
    }, []);


    if ((!formData || isLoadingDetail ) && !hasError) {
        return <LoaderWait/>;
    }

    return (
        <div className='defaultLayout-container'>
            <div className='defaultLayout-mainContent'>
                <div className="row content">

                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: 600, // Limite la largeur maximale
                            padding: 4, // Padding interne
                            boxShadow: 3, // Ombre pour démarquer
                            borderRadius: 2, // Bords arrondis
                            backgroundColor: "white", // Fond blanc
                            alignItems: "center",
                            mt: 2,
                        }}
                    >

                        {hasError ? (
                            <Box sx={{ textAlign: 'center', p: 3 }}>
                                <Typography>{t('SORRY_PAGE_NOT_AVAILABLE')}</Typography>
                            </Box>
                        ) : (
                            <>
                                <QuestionairePostFormationWelcome
                                    formation={formation}
                                    submissionError={submissionError}
                                    user={user}
                                />

                                {isSubmitted ? (
                                    <Box sx={{ textAlign: 'center', p: 3 }}>
                                        <Typography variant="h6" color="primary">{t('THANK_YOU')}</Typography>
                                        <Typography>{t('YOUR_RESPONSE_HAS_BEEN_SAVED')}</Typography>
                                    </Box>
                                ) : (
                                    <QuestionairePostFormationForm
                                        formState={formState}
                                        formation_id={formation_id}
                                        formData={formData}
                                        setFormState={setFormState}
                                        link={link}
                                        setIsSubmitted={setIsSubmitted}
                                        setSubmissionError={setSubmissionError}
                                        user_id={user_id}
                                    />
                                )}
                            </>
                        )}

                    </Box>
                </div>
            </div>
        </div>
    )
        ;
}

export default QuestionairePostFormation;
