import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";


const PreviewCheckbox = ({question})=>{
    return (
        <Box>
            {question.options?.map((option, optionIndex) => (
                <FormControlLabel
                    key={optionIndex}
                    control={<Checkbox/>}
                    label={option || `Option ${optionIndex + 1}`}
                />
            ))}
        </Box>
    )
}
export default PreviewCheckbox