import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import useTexts from "../../hooks/useTexte";
import { DeleteForeverRounded } from '@mui/icons-material';
import './styles/ConfirmModal.css';

const ConfirmModal = ({ open, message, onClose, onConfirm }) => {
    const { t } = useTexts()

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box className='confirm-modal-box'>
                <Box id="modal-message">
                    {message}
                </Box>
                <Box sx={{ mt: 2 }} className='button-box'>
                    <Button variant='outlined' onClick={onClose}>{t("BUTTON_ANNULER")}</Button>
                    <Button type="submit" variant='contained' color='error' onClick={onConfirm} startIcon={<DeleteForeverRounded />}>{t("BUTTON_CONFIRM_DELETE")}</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmModal;
