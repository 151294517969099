import {Box, Divider, Modal, Typography} from "@mui/material";
import React, {useRef} from "react";
import EmailEditorVars from "./EmailEditorVars";
import EmailEditorAction from "./EmailEditorAction";
import EmailEditorInputs from "./EmailEditorInputs";

const EmailEditor = ({ selectedTemplate,  setSelectedTemplate, setFocusedField, focusedField, handleSave}) => {
    const quillRef = useRef(null);
    const handleTemplateChange = (e) => {
        const { name, value } = e.target;
        setSelectedTemplate((prevTemplate) => ({
            ...prevTemplate,
            [name]: value,
        }));
    };



    return (

        <Modal
            open={selectedTemplate !== null}
            onClose={() => setSelectedTemplate(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '95vh', // Set maximum height
                    overflowY: 'auto', // Enable scrolling if content exceeds max height
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Éditer le Modèle
                </Typography>
                <Divider />
                <Box mt={2}>
                   <EmailEditorInputs selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} setFocusedField={setFocusedField} quillRef={quillRef} handleTemplateChange={handleTemplateChange}/>
                    <EmailEditorVars selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} focusedField={focusedField} quillRef={quillRef} />
                    <EmailEditorAction  setSelectedTemplate={setSelectedTemplate} handleSave={handleSave} />
                </Box>
            </Box>
        </Modal>

    )
}

export default EmailEditor;
