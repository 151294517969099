import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useFetchWithToken } from '../../hooks/useFetchWithToken';

const FormationManagerItemListUserAction = ({ formation }) => {
    const fetchWithToken = useFetchWithToken();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExportExcel = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/answer?format=pdf`;

            // Exemple de données à envoyer au backend (formData)
            const formData = {};
            const blob = await fetchWithToken(apiUrl, "POST", formData, false, "BLOB");

            // Téléchargement du fichier PDF
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };

    const handleExportTrombi = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/trombi`;

            const response = await fetchWithToken(apiUrl, "POST", {}, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = ''; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);

                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };

    const handleExportPDF = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/pdf`;

            const response = await fetchWithToken(apiUrl, "POST", {}, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = ''; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);

                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };

    return (
        <Box className='formation-manager-user-action'>
            <Button
                startIcon={<MoreVert />}
                id='export-button'
                onClick={handleClick}
                color="primary"
            >
                Tout Exporter
            </Button>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleExportExcel(); handleClose(); }}>Excel</MenuItem>
                <MenuItem onClick={() => { handleExportPDF(); handleClose(); }}>PDF</MenuItem>
                <MenuItem onClick={() => { handleExportTrombi(); handleClose(); }}>Trombinoscope</MenuItem>
            </Menu>
        </Box>
    );
};

export default FormationManagerItemListUserAction;
