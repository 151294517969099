import React, {useState} from 'react';
import {Typography, Button, Grid, Dialog, DialogTitle, DialogContent, Box, DialogActions,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import FormFieldView from "../Fiche/FormFieldView";
import {makeStyles} from "tss-react/mui";
import './styles/StagiaireCard.css';
import StagiareCardFichePdf from "./StagiareCardFichePdf";

const useStyles = makeStyles((theme) => ({
    listItem: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    listItemText: {
        fontWeight: 'bold',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
}));

const StagiareCardFiche = ({user}) => {
    const fetchWithToken = useFetchWithToken();
    const [openFiche, setOpenfiche] = useState(false);
    const classes = useStyles();
    const [avis, setAvis] = useState([]);
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});

    const loadWishesDetail = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_user_id}/${user.formation_id}/answer`;
            const data = await fetchWithToken(apiUrl);

            var newForm = {
                "sections": []
            };
            let key;
            for (key in data.data) {
                const section = data.data[key]
                newForm.sections.push(section)
            }
            setFormData(newForm);
            setAvis(data.avis);
            setFormState(data.answer);

            for (key in data.answer) {
                const splits = key.split("_");
                const id_section = splits[0];
                const key_input = splits[1];

                if (newForm.sections[id_section] && newForm.sections[id_section].fields) {
                    let input_map = newForm.sections[id_section].fields.filter(function (f) {
                        return f.name == key_input;
                    });
                    if (input_map.length > 0) {
                        input_map = input_map[0];
                    }

                }

            }

            setOpenfiche(true);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    return (
        <>
            <Button variant="outlined" color="primary" onClick={() => loadWishesDetail()}>
                Voir la fiche
            </Button>
            <Dialog open={openFiche} onClose={() => setOpenfiche(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Fiche stagiaire</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {formData &&
                        formData.sections &&
                        formData.sections.map((section, sectionIndex) => (
                            <Box key={sectionIndex} mb={2}>
                                <Typography variant="h6">{section.title}</Typography>
                                <Grid container spacing={2}>
                                    {section.fields.map((field, fieldIndex) => (
                                        <Grid item xs={12} key={fieldIndex}>
                                            <FormFieldView
                                                field={field}
                                                sectionIndex={sectionIndex}
                                                formState={formState}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    <Box mt={4}>
                        <Typography variant="h6">Avis</Typography>
                        {avis.map((avisItem, index) => (
                            <Box mt={2} key={index}>
                                <Typography variant="subtitle1">{avisItem.avis_level}</Typography>
                                <Typography variant="body1">{avisItem.avis_avis || 'Pas d\'avis'}</Typography>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <StagiareCardFichePdf user={user}/>
                    <Button onClick={() => setOpenfiche(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );

};
export default StagiareCardFiche;
