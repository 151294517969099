import React from 'react';
import './styles/HeaderProfile.css';
import {Menu, MenuItem, Select} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {useAuth} from "../../Context/AuthContext";
import {useNavigate} from "react-router-dom";

function HeaderProfileFunctionSelector({anchorEl,isMenuOpen , handleMenu}) {
    const navigate = useNavigate();

    const {
        logout,
        currentFunction,
        functionOptions,
        setcurrentFUnctionHanlder

    } = useAuth();
    const handleLogout = () => {

        logout(); // Appeler la fonction de logout
        navigate('/login', {state: null}); // Rediriger en purgant location.state
    };
    const changeFunction = (e) => {
        setcurrentFUnctionHanlder(e);
        navigate('/', {state: null}); // Rediriger en purgant location.state
    };
    return (
        <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenu}
        >
            <MenuItem>
                <Select
                    value={currentFunction}
                    onChange={(event) => {
                        changeFunction(event.target.value);
                        handleMenu();
                    }}
                    displayEmpty
                    fullWidth
                >
                    {functionOptions.map(({fonction, id, nom}) => (
                        <MenuItem key={id} value={id}>
                            {nom} - {fonction}
                        </MenuItem>
                    ))}
                </Select>
            </MenuItem>
            <MenuItem>
                <button className='azimuth-btn full-width' onClick={() => {
                    navigate('/ma-fiche', {state: null});
                    handleMenu();
                }}>Ma fiche adhérent</button>
            </MenuItem>
            <MenuItem onClick={() => {
                handleLogout();
                handleMenu();
            }}>
                <LogoutIcon/>
                <span className="text-profil-link">Déconnexion</span>
            </MenuItem>
        </Menu>
    )

}

export default HeaderProfileFunctionSelector;
