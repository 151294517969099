import React, { useState, useEffect } from 'react';
import { branchOptions } from "../Utils/BranchOption";
import {Button, Box, Grid,} from '@mui/material';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import withAdminRole from "../hoc/withAdminRole";
import QuestinaireSectionForm from "../components/QuestinaireSectionForm";
import LoaderWait from "../components/LoaderWait";

function QuestinaireSectionPage() {
    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState({});
    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formeval`;
            const data = await fetchWithToken(apiUrl);

            // Préparer les sélections initiales à partir des données
            const initialSelections = {};
            data.forEach(questionnaire => {
                questionnaire.sections.forEach(sectionId => {
                    initialSelections[sectionId] = questionnaire.id;
                });
            });

            setQuestionnaires(data);
            setSelectedQuestionnaires(initialSelections);
            setLoading(false);
        };

        fetchQuestionnaires();
    }, []);

    const handleQuestionnaireChange = (sectionId, questionnaireId) => {
        setSelectedQuestionnaires(prevSelected => ({
            ...prevSelected,
            [sectionId]: questionnaireId
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Préparer les données pour l'envoi
        const sectionsWithQuestionnaires = Object.keys(selectedQuestionnaires).map(sectionId => ({
            section_id: parseInt(sectionId),
            questionnaire_id: selectedQuestionnaires[sectionId] || null // ou null si aucun questionnaire sélectionné
        }));

        // Envoyer les données
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/save-form-eval`;
            const response = await fetchWithToken(apiUrl, "POST",
                {data:sectionsWithQuestionnaires},
            );

            if (!response.ok) {
                throw new Error('Erreur lors de la sauvegarde des données');
            }

        } catch (error) {
            console.error("Erreur lors de la sauvegarde :", error);
        }
    };


    if (loading) {
        return  <LoaderWait />;
    }

    return (
        <Box sx={{ mt: 4, mx: 2 }}>
            <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={3}>
                    {branchOptions.map((branch, index) => (
                        <QuestinaireSectionForm key={index} branch={branch} questionnaires={questionnaires} handleQuestionnaireChange={handleQuestionnaireChange} selectedQuestionnaires={selectedQuestionnaires}/>
                    ))}
                </Grid>
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Sauvegarder
                </Button>
            </Box>
        </Box>
    );
}

export default withAdminRole(QuestinaireSectionPage);
