import { WarningRounded } from '@mui/icons-material';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import useTexts from "../../hooks/useTexte";
import { cepBranchType } from '../../Utils/CepBranchType';

export default function Speciality({ wish, handleSpecialtyChange, choiceCep, canSelectDate}) {
    const {t} = useTexts();

    const specialty = Object.keys(cepBranchType).map((key) => {
        return {
            value: key,
            label: cepBranchType[key]
        }
    });

    const handleSelectChange = (value) => {
        switch (value) {
            case 'terrestre':
                handleSpecialtyChange('terrestre');
                break;
            case 'marin':
                handleSpecialtyChange('marin');
                break;
            case 'montagne':
                handleSpecialtyChange('montagne');
                break;
            case 'nospecialite':
                handleSpecialtyChange('nospecialite');
                break;
            default:
                handleSpecialtyChange('no_preference');
                break;
        }
    }

    useEffect(() => {
        if (!wish.specialty) {
            handleSpecialtyChange('nospecialite');
        }
    }, [wish]);

    if (wish.branch && wish.level && choiceCep && canSelectDate) {
        const selectLabel = "Je souhaite une spécialité de type";
        return (
            <FormControl fullWidth>
                <InputLabel id="specialty">{selectLabel}</InputLabel>
                <Select
                    labelId="specialty"
                    id="specialty"
                    value={wish.specialty}
                    label={selectLabel}
                    onChange={(e) => handleSelectChange(e.target.value)}
                >
                    <MenuItem value="no_preference">
                        <em>{t("Aucune préférence")}</em>
                    </MenuItem>
                    {specialty.map((spe, index) => (
                        <MenuItem key={index} value={spe.value}>
                            {spe.value === 'no_preference' ? <em>{spe.label}</em> : spe.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText component={'div'}>
                    <Typography variant="body2" color="error" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <WarningRounded fontSize="small" />
                        <em>{t("CEP_FORM_DISCLAIMER")}</em>
                    </Typography>
                </FormHelperText>
            </FormControl>
        )
    }
}
