import { MenuItem, TextField, Typography } from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";
import { calculateEndDate, formatDate } from "../../Utils/functionTransforms";
import { months } from "../../Utils/month";
import { findEndDate, getAdditionalInfo, getAllRelevantDates } from "../../Utils/Date";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";

const DateSelect = ({
    wish,
    classes,
    trainingSessionsDates,
    openDates,
    canSelectDate,
    forceSelectMonth,
    selectedMonth,
    handleMonthChange,
    openMonth,
    handleDateChange,
    saturdays,
    trainingSessions
}) => {
    const { t } = useTexts();

    // Guard condition to ensure months is defined and is an array
    const updatedCanSelectDate = (wish.specialty === 'marin' || wish.specialty === 'montagne') ? false : canSelectDate;

    const validMonths = Array.isArray(months) ? months : [];

    return wish.branch && wish.level && (
        <>
            {updatedCanSelectDate && (
                <TextField
                    select
                    label={t("TEXT_LABEL_MOIS")}
                    key={forceSelectMonth ? 1 : 0}
                    value={selectedMonth !== undefined ? selectedMonth : ''}
                    className={classes.select}
                    onChange={(e) => handleMonthChange(parseInt(e.target.value, 10))}
                    fullWidth
                >
                    {validMonths
                        .filter(month => openMonth && openMonth.includes(month.value.toString().padStart(2, '0'))) // Filter by open months
                        .map((month) => (
                            <MenuItem key={month.value} className={classes.menuItem} value={month.value}>
                                {month.label}
                            </MenuItem>
                        ))
                    }
                </TextField>
            )}

            {(typeof selectedMonth === 'number' && updatedCanSelectDate) && (
                <TextField
                    select
                    label={t("TEXT_COL_DATE")}
                    value={wish.date}
                    className={classes.select}
                    onChange={e => handleDateChange(e.target.value)}
                    fullWidth
                >
                    {getAllRelevantDates(saturdays, openDates, trainingSessions).map((saturday, key) => {
                        const info = getAdditionalInfo(saturday, trainingSessions, t);
                        return (
                            <MenuItem
                                value={saturday}
                                key={key}
                                className={`${classes.menuItem} ${info.status === 'open' ? classes.openSession : classes.noSession}`}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {info.status === 'open' ? <EventAvailableIcon /> :
                                            <EventBusyIcon />}
                                        {t("TEXT_COL_DATE_FULL", formatDate(saturday), findEndDate(saturday, trainingSessionsDates) || calculateEndDate(saturday))}
                                    </div>
                                    <Typography variant="body2" color="textSecondary">
                                        {info.text}
                                    </Typography>
                                </div>
                            </MenuItem>);
                    })}
                </TextField>
            )}

            {(!updatedCanSelectDate) && (
                <TextField
                    select
                    label={t("TEXT_COL_DATE")}
                    value={wish.date}
                    className={classes.select}
                    onChange={e => handleDateChange(e.target.value)}
                    fullWidth
                >
                    {saturdays.map((saturday, key) => {
                        const info = getAdditionalInfo(saturday, trainingSessions, t);
                        return (
                            <MenuItem
                                value={saturday}
                                key={key}
                                className={`${classes.menuItem} ${info.status === 'open' ? classes.openSession : classes.noSession}`}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {info.status === 'open' ? <EventAvailableIcon /> :
                                            <EventBusyIcon />}
                                        {t("TEXT_COL_DATE_FULL", formatDate(saturday), findEndDate(saturday, trainingSessionsDates) || calculateEndDate(saturday))}
                                    </div>
                                    <Typography variant="body2" color="textSecondary">
                                        {info.text}
                                    </Typography>
                                </div>
                            </MenuItem>);
                    })}
                </TextField>
            )}
        </>
    );
};

export default DateSelect;


