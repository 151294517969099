import React, { useEffect, useState } from 'react';
import { Container, Box, } from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { useAuth } from "../Context/AuthContext";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import GenericFilter from "../components/Filter/GenericFilter";
import DeorgationListHead from "../components/DeorgationListHead";
import DeorgationList from "../components/DeorgationList";
import LoaderWait from "../components/LoaderWait";

const DerogationListTable = () => {
    const [filters, setFilters] = useState({
        selectedType: "",
        selectedBranche: "",
        selectedstartDate: null,
        selectedendDate: null,
        selectedPersonne: "",
        itemsPerPage: 10,
        showClose: false,
        showOpen: true,
        _displayOneRadio: true,
    });

    const [page, setPage] = useState(1);
    const [selectedWishes, setSelectedWishes] = useState([]);
    const [wishes, setWishes] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const fetchWithToken = useFetchWithToken();
    const {isLoadingFunction} = useAuth();

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/derogations`;
            const body = {
                branche: filters.selectedBranche,
                startDate: filters.selectedstartDate,
                endDate: filters.selectedendDate,
                itemsPerPage: filters.itemsPerPage,
                level: filters.selectedType,
                showClose: filters.showClose,
                showOpen: filters.showOpen,
                user: (filters.selectedPersonne ? filters.selectedPersonne.id : null),
                page
            }
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setWishes(data.wish);
            setTotalPages(data.totalPages);

        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {
        if (filters.init === 1) {
            loadWishes();
        }
    }, [page, filters]);

    if (!isLoadingFunction) {
        return (<LoaderWait/>);
    }

    return (
        <Container
            maxWidth="xl"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <GenericFilter
                filters={filters}
                totalPages={totalPages}
                setFilters={setFilters}
                page={page}
                setPage={setPage}
            />

            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}>

                <DeorgationListHead
                    loadWishes={loadWishes}
                    selectedWishes={selectedWishes}
                    setSelectedWishes={setSelectedWishes}
                    filters={filters}
                    page={page}
                />
                <DeorgationList
                    loadWishes={loadWishes}
                    selectedWishes={selectedWishes}
                    setSelectedWishes={setSelectedWishes}
                    wishes={wishes}
                    showClose={filters.showClose}
                />
            </Box>
        </Container>
    );
};

export default withAdminOrFormationRole(DerogationListTable);
