export const formatErrorsAdmin = (invalideData) => {
    const errorCodeMessages = {
        "rules_cep2_have_cep1": "Cette formation nécessite d'avoir un CEP1.",
        "rules_cep3_have_cep2": "Cette formation nécessite d'avoir un CEP2.",
        "min_age_requirement": "L'âge minimum requis n'est pas atteint.",
        "max_participants": "Le nombre maximum de participants a été atteint.",
    };

    // Decode JSON if it's a JSON string
    let errors = invalideData;
    if (typeof invalideData === 'string') {
        try {
            errors = JSON.parse(invalideData);
        } catch (e) {
            console.error("Error parsing invalide_data:", e);
            return "Erreur lors de l'interprétation des données d'invalidité.";
        }
    }

    // Map the error codes to messages, if any are unrecognized, log them or handle them as you see fit
    const messages = errors.map(code => {
        return errorCodeMessages[code] || `Code d'erreur non reconnu: ${code}`;
    });

    // Join all messages with a space or other separator as appropriate
    return messages.join(' ');
};

export const formatErrorsArray = (invalideData, t, isList) => {


    // Decode JSON if it's a JSON string
    let errors = invalideData;
    if (typeof invalideData === 'string') {
        try {
            errors = JSON.parse(invalideData);
        } catch (e) {
            console.error("Error parsing invalide_data:", e);
            return "Erreur lors de l'interprétation des données d'invalidité.";
        }
    }

    const messages = errors.map(code => {
        const codeList =  code + "_list"
        if (isList == 1){
            const listrad = t(codeList);
            if (listrad != codeList)
                return  listrad
            return  t(code)
        }else {
            return t(code)
        }
    });

    // Join all messages with a space or other separator as appropriate
    return  messages
};
export const formatErrors = (invalideData, t) => {


    // Decode JSON if it's a JSON string
    let errors = invalideData;
    if (typeof invalideData === 'string') {
        try {
            errors = JSON.parse(invalideData);
        } catch (e) {
            console.error("Error parsing invalide_data:", e);
            return "Erreur lors de l'interprétation des données d'invalidité.";
        }
    }

    // Map the error codes to messages, if any are unrecognized, log them or handle them as you see fit
    const messages = errors.map(code => {
        return t(code)
    });

    // Join all messages with a space or other separator as appropriate
    return messages.join(' ');
};
