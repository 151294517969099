import React, {useEffect, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import {useFetchWithToken} from '../../hooks/useFetchWithToken';
import {useAuth} from '../../Context/AuthContext';
import ChangeFunctionWish from './ChangeFunctionWish';
import WishHeader from './WishHeader';
import WishBody from './WishBody';
import './styles/WishListTable.css';
import './styles/WishCard.css';
import ChangeConfigWish from "./ChangeConfigWish";

const WishListTable = ({wish, onDelete, type, realodWish, close}) => {
    const fetchWithToken = useFetchWithToken();
    const [formationData, setFormationData] = useState([]);
    const [isRequireValidate, setIsRequireValidate] = useState(false);
    const [formation, setFormation] = useState(0);
    const {functionOptions} = useAuth();
    const [saveFunction, setSaveFunction] = useState(false);
    const [openChangeFunction, setOpenChangeFunction] = useState(false);

    const onValidate = async (setOpenValidation) => {
        try {
            const body = {
                formation_user_id: formation.statusInscription.id,
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/validate`;
            await fetchWithToken(apiUrl, "POST", body);
            setOpenValidation(false);
            loadWishesData();
            realodWish();
        } catch (error) {
            console.error(error);
        }
    };

    const loadWishesData = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/data`;
            const data = await fetchWithToken(apiUrl);
            setFormationData(data.formations);
            data.formations.map((formation) => {
                if (formation.statusInscription) {
                    if (formation.statusInscription.status === 'WAIT_VALIDATION') {
                        setIsRequireValidate(true);
                        setFormation(formation);
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const chefFunction = functionOptions.find(func => func.id === wish.function_id);

    useEffect(() => {
        loadWishesData();
    }, []);

    return (
        <div className='wish-card-container'>
            <Card className='wish-card'>
                <CardContent className='wish-card-content'>
                    <WishHeader wish={wish} setOpenChangeFunction={setOpenChangeFunction} type={type} onDelete={onDelete} isRequireValidate={isRequireValidate} />
                    <ChangeFunctionWish
                        saveFunction={saveFunction}
                        setSaveFunction={setSaveFunction}
                        wish={wish}
                        chefFunction={chefFunction} close={close}
                    />
                    {isRequireValidate !== true && (
                        <ChangeConfigWish
                            openChangeFunction={openChangeFunction}
                            setOpenChangeFunction={setOpenChangeFunction}
                            realodWish={realodWish}
                            wish={wish} close={close}
                        />
                    )}
                    <WishBody wish={wish} type={type} close={close} onValidate={onValidate} isRequireValidate={isRequireValidate} formationData={formationData}/>
                </CardContent>
            </Card>
        </div>
    );
};

export default WishListTable;
