import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";


const UserLogHead = () => {

    return (
        <TableHead>
            <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Événement</TableCell>
                <TableCell>Détails</TableCell>
                <TableCell>Auteur</TableCell>
            </TableRow>
        </TableHead>
    )

}

export default UserLogHead;
