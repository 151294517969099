import React  from 'react';
// import  { urlFor } from '../sanityClient';
import { PortableText } from '@portabletext/react';
import { Box, Grid, Typography } from '@mui/material';
import './styles/CMSTop.css';

const ContentBlockRenderer = React.lazy(() => import('../components/ContentBlockRenderer'));

function CMSTop({ cmsData }) {
    return (
        <div className="cmspageWrap">
            {cmsData.imageHero ? (
                <div className="hero">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <div>
                                <Typography sx={{ fontFamily: "ubuntu", textAlign: 'center' }} variant="h1" component="h1" gutterBottom className="cmsHerotitle">
                                    {cmsData.title}
                                </Typography>
                            </div>
                            <div className='cmsHeroDesctitle'>
                                <PortableText value={cmsData.description} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div className="hero">
                    <Grid container spacing={0}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                p: 1,
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant="h1"
                                component="h1"
                                gutterBottom
                                className="cmsHerotitle"
                            >
                                {cmsData.title}
                            </Typography>
                            <div className='cmsHeroDesctitle'>
                                <PortableText value={cmsData.description}/>
                            </div>
                        </Box>
                    </Grid>
                </div>
            )}
            {cmsData.content?.map((block, index) => (
                <ContentBlockRenderer key={index} block={block} />
            ))}
        </div>
    );
}

export default CMSTop;
