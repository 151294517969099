import React, {useEffect, useState} from 'react';
import {Box,} from '@mui/material';
import {useSystem} from "../Context/System";
import FormationMangerItem from "../components/Formation/FormationManagerItem";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import './styles/FormationAdminListPage.css'
import LoaderWait from "../components/LoaderWait";

const FormationManagerListPage = () => {
    const [mycep, setMycep]  = useState([]);

    const {myFormationAsChef} = useSystem()
    const fetchWithToken = useFetchWithToken();
    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchQuestionnaires = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/mytemplates`;
        const data = await fetchWithToken(apiUrl);
        setQuestionnaires(data);
        setLoading(false);
    };
    const getMyForm = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/mycep`;
        const data = await fetchWithToken(apiUrl);
        setMycep(data.mycep);
        // setLoading(false);
    };
    useEffect(() => {

        fetchQuestionnaires();
        setMycep(myFormationAsChef)
    }, []);



    if (loading) {
        return  <LoaderWait />;
    }

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <div className='cep-container'>
                {mycep && mycep.map((formation, index) => (
                    <FormationMangerItem key={index} formation={formation} index={index} questionnaires={questionnaires} callBack={getMyForm}/>
                ))}
            </div>
        </Box>
    );
};

export default FormationManagerListPage;
