import React from "react";
import StructureItemChef from "./StructureItemChef";

const StructureItemListChef = ({ structure, sortedChefs, loadChefs, showChef, setModalAvisEdit, setCurrentAvis, setSelectedChefAvis, setSelectedChefType }) => {
    const openModalAvisCallBack = (chef, editable, current, type) => {
        if (current || editable) {
            setModalAvisEdit(editable);
            setCurrentAvis(current);
            setSelectedChefAvis(chef);
            setSelectedChefType(type)
        }
    };
    return showChef && (
        sortedChefs.map((chef, index) => (
            <StructureItemChef
                key={index}
                structure={structure}
                chef={chef}
                index={index}
                callBacks={{ openModalAvisCallBack, loadChefs }}
            />
        ))
    )
};

export default StructureItemListChef;
