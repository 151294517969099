import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const ConfigListHead = React.memo(() => {
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Value</TableCell>
                <TableCell style={{ fontWeight: 'bold', width: '100px' }}>Description</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
        </TableHead>
    )
});

export default ConfigListHead;