import {MenuItem, Select, Typography} from "@mui/material";
import React from "react";
import {useAuth} from "../../Context/AuthContext";
import useTexts from "../../hooks/useTexte";


const FunctionSelect  = ({selectedFunction, changeFunction})=>{
    const { functionOptions} = useAuth();
    const {t} = useTexts();
    return functionOptions && functionOptions.length > 1 && (
        <>
            <Select
                value={selectedFunction}
                onChange={(event) => changeFunction(event.target.value)}
                displayEmpty
                fullWidth
            >
                {functionOptions.map(({fonction, id, nom}) => (
                    <MenuItem key={id} value={id}>
                        {nom} - {fonction}
                    </MenuItem>
                ))}
            </Select>
            <Typography variant="body2">{t("FUNCTION_ADD_WISH_WARNING")}</Typography>
        </>
    )
}

export default FunctionSelect