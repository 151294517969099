import React from "react";
import {Grid2, FormControl, InputLabel, Select, MenuItem,} from "@mui/material";
import {LevelOptions} from "../../Utils/LevelOption";
import useTexts from "../../hooks/useTexte";


const GenericFilterType = ({filters, handleFilterChange}) => {
    const {t} = useTexts();

    return (
        <>
            {"selectedType" in filters && (
                <Grid2 xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="label-texte-type-formation">{t("TEXT_TYPE_FORMATION")}</InputLabel>
                        <Select
                            labelId="label-texte-type-formation"
                            value={filters.selectedType || ""}
                            fullWidth
                            onChange={(e) =>
                                handleFilterChange(
                                    "selectedType",
                                    LevelOptions.find(option => option.key === e.target.value)?.key
                                )
                            }
                            label={t("TEXT_TYPE_FORMATION")}
                        >
                            <MenuItem value="">
                                <em>{t("INPUT_SELECT_ALL")}</em>
                            </MenuItem>
                            {LevelOptions.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid2>
            )}
        </>
    );
};

export default GenericFilterType;
