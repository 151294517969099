import React, { useState } from 'react';
import Modal from "react-modal";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../Context/AuthContext";

const MultiCompte = ({isModalOpen, setIsModalOpen, users, tempToken}) => {
    const [isLoading, setIsLoading] = useState(false); // Pour indiquer qu'un compte est en cours de traitement
    const location = useLocation();
    let navigate = useNavigate();
    const { login } = useAuth();

    const handleUserSelection = async (userId) => {
        setIsLoading(true); // Indiquer que le processus de connexion est en cours
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/generate-token`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${tempToken}`, // Utiliser le token temporaire ici
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }) // Envoyer l'ID de l'utilisateur sélectionné
            });
            const data = await response.json();
            if (response.ok) {
                // Connexion avec le nouveau token
                login(data.token);
                if (location.state) {
                    navigate(location.state, { replace: true });
                } else {
                    navigate('/');
                }
            } else {
                console.error('Error generating final token:', data.message);
                setIsLoading(false); // Arrêter le chargement en cas d'erreur
            }
        } catch (error) {
            console.error('Error during user selection:', error);
            setIsLoading(false); // Arrêter le chargement en cas d'erreur
        }
    };


    return (

        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="Select Account"
            className="Modal"
            overlayClassName="Overlay"
        >
            <h2 className="modal-title">Plusieurs comptes associés à cet email</h2>
            <p className="modal-description">
                Il semble que plusieurs comptes soient associés à cet email. Veuillez sélectionner le compte avec lequel vous souhaitez vous connecter.
            </p>
            <ul className="user-list">
                {users.map((user) => (
                    <li key={user.id} className="user-list-item">
                        <button
                            className="user-select-button"
                            onClick={() => handleUserSelection(user.id)}
                            disabled={isLoading} // Désactiver les boutons pendant le chargement
                        >
                            {user.firstname} {user.lastname} (Code Sycomore: {user.codeSycomore})
                        </button>
                    </li>
                ))}
            </ul>
            {isLoading && <p className="loading-message">Connexion en cours...</p>}
        </Modal>

    )
};

export default MultiCompte;
