import React from "react";
import { Typography, Button, Grid2, IconButton, Box } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import MyDoc from "./Formation/MyDoc";
import { useAuth } from "../Context/AuthContext";
import { downloadDocument } from "../Utils/downloadDocument";
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import { useMediaQuery } from "@mui/system";
import { DeleteForeverRounded, DownloadRounded } from "@mui/icons-material";

const UserListDocuments = ({ documents, isOwner, setDocuments }) => {
    const { token } = useAuth();
    const fetchWithToken = useFetchWithToken();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, { "type": "admin" });
    };

    const uploadDocuement = async (c) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/doc`;

        const data = await fetchWithToken(apiUrl, 'POST');
        setDocuments(data);
        c();
    };

    const onDeleteDocument = async (id) => {
        try {
            // URL de l'API pour supprimer un document
            const deleteUrl = `${process.env.REACT_APP_API_URL}/api/user/deletedoc/${id}`;

            await fetchWithToken(deleteUrl, 'DELETE');
            const fetchUrl = `${process.env.REACT_APP_API_URL}/api/user/doc`;
            const updatedDocuments = await fetchWithToken(fetchUrl, 'POST');
            setDocuments(updatedDocuments);
        } catch (error) {
            console.error('Erreur :', error);
        }
    };

    const confirmDeletion = (id) => {
        if (window.confirm("Voulez-vous vraiment supprimer ce document ?")) {
            onDeleteDocument(id);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }} id='doc-container'>
            <Typography variant="h6" sx={{ my: 2, textAlign: "center" }}>
                Vos documents
            </Typography>
            {isOwner == 1 && (
                <MyDoc handleDownloadDocument={uploadDocuement} />
            )}
            <Grid2 container spacing={1} sx={{ my: 1, p: 2, gap: 1, width: '100%' }}>
                {documents.length > 0 ? (
                    documents.map((doc) => (
                        <Grid2 item xs={12} md={6} lg={4} key={doc.id} className="doc-card">
                            <DescriptionIcon color="primary" fontSize="large" />
                            <Typography variant="body1" sx={{ fontWeight: "bold" }} textOverflow={"ellipsis"} noWrap>
                                {doc.nom}
                            </Typography>
                            <div id="doc-buttons">
                                {isOwner == 1 && (
                                    isMobile ? (
                                        <>
                                            <IconButton size="small" color="error" onClick={() => confirmDeletion(doc.id)}>
                                                <DeleteForeverRounded />
                                            </IconButton>
                                            <IconButton size="small" color="primary" onClick={() => handleDownloadDocument(doc.path)}>
                                                <DownloadRounded />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                onClick={() => confirmDeletion(doc.id)}
                                            >
                                                Supprimer
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary" // Couleur adaptée pour une action comme le téléchargement
                                                size="small"
                                                onClick={() => handleDownloadDocument(doc.path)}
                                            >
                                                Télécharger
                                            </Button>
                                        </>
                                    )
                                )}
                            </div>
                        </Grid2>
                    ))
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', width: '100%' }} gutterBottom>
                        Aucun document disponible.
                    </Typography>
                )}
            </Grid2>
        </Box>
    );
};

export default UserListDocuments;
