import {MenuItem, TextField} from "@mui/material";
import React from "react";


const BranchSelect = ({wish, handleBranchChange, classes, branchOptions}) => {
    return (
        <>

            <TextField
                select
                label="Branche"
                value={wish.branch}
                className={classes.select}
                onChange={(e) => handleBranchChange(e.target.value)}
                variant="outlined"
                fullWidth
            >
                {branchOptions.map((option, index) => (
                    <MenuItem key={index} className={classes.menuItem}
                              value={option.key}>{option.label}</MenuItem>
                ))}
            </TextField>
        </>
    )
}

export default BranchSelect