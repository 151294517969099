import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, Paper, Table, TableContainer } from '@mui/material';
import useTexts from "../../hooks/useTexte";
import { PeopleAltRounded } from "@mui/icons-material";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import FormationAdminListUserForm from "./FormationAdminListUserForm";
import FormationAdminListUserTableHead from "./FormationAdminListUserTableHead";
import FormationAdminListUserTableBody from "./FormationAdminListUserTableBody";

const FormationMangerAdminListUser = ({ formation, isAdministrator }) => {
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();
    const [openModalList, setOpenModalList] = useState(false);
    const [users, setUsers] = useState([]);

    const [filters, setFilters] = React.useState({
        status: {
            'WAIT': false,
            'WAIT_VALIDATION': false,
            'VALIDATE': false,

        },
        'hideInWait': 1,
        codeSycomore: '',
        selectedName: '',
    });

    const handleListUser = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${formation.id}`;
            const data = await fetchWithToken(apiUrl);

            setUsers(data);
            setOpenModalList(true)
        } catch (error) {
            console.error('erreur avec handleListUser', error);
        }
    };

    return (
        <>
            <Button
                className='azimuth-btn full-width'
                onClick={() => handleListUser()}
                startIcon={<PeopleAltRounded />}
            >
                {t('BUTTON_SEE_REGISTER')}
            </Button>
            <Dialog open={openModalList} onClose={() => setOpenModalList(false)} sx={{ my: 4 }} fullWidth maxWidth='lg'>
                <DialogContent>
                    <FormationAdminListUserForm filters={filters} handleListUser={handleListUser} wish={formation}
                        isAdministrator={isAdministrator} setFilters={setFilters} />
                    <Box sx={{ my: 2 }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <FormationAdminListUserTableHead />
                                <FormationAdminListUserTableBody
                                    wish={formation}
                                    isAdministrator={isAdministrator}
                                    handleListUser={handleListUser}
                                    users={users}
                                    filters={filters}
                                />
                            </Table>
                        </TableContainer>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default FormationMangerAdminListUser;
