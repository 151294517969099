import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const ForeignTableHead = React.memo(() => {

    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Code</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Nom</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Prénom</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Téléphone</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
        </TableHead>
    )
})
export default ForeignTableHead