import React, {useEffect, useState} from "react";
import { Grid2, TextField, Dialog, DialogContent, Button } from "@mui/material";
import useTexts from "../../hooks/useTexte";
import SearchModal from "../Search/SearchModal";


const GenericFilterPersonne = ({filters, handleFilterChange, initialSelectedPersonne}) => {
    const {t} = useTexts();
    const [dialogOpenSearch, setDialogOpenSearch] = useState(false);

    useEffect(() => {
        if (initialSelectedPersonne) {
            handleFilterChange("selectedPersonne", initialSelectedPersonne);
        }
    }, [initialSelectedPersonne, handleFilterChange]);

    const handleOpenSearchDialog = () => {
        setDialogOpenSearch(true);
    };
    const HandlePersonneSearch = (newuser) => {
        handleFilterChange("selectedPersonne", newuser)
        setDialogOpenSearch(false);
    };


    const formatSearchUser = (user) => {
        return user.firstname + " " + user.lastname
    };

    const handleResetPersonne = () => {
        handleFilterChange("selectedPersonne", null)
        setDialogOpenSearch(false)
    };

    // useEffect(() => {
    //     if (filters.selectedPersonne && !filters.selectedPersonne.lastname && !filters.selectedPersonne.firstname) {
            
    //     }
    // } , [filters.selectedPersonne, handleFilterChange]);

    return "selectedPersonne" in filters && (
        <>
            <Grid2 xs={12} md={4}>
                <TextField
                    label={t("SEARCH_USER")}
                    value={(filters.selectedPersonne ? formatSearchUser(filters.selectedPersonne) : "")}
                    onClick={handleOpenSearchDialog}
                    variant="outlined"
                    sx={{ flex: 1 }}
                    fullWidth
                />
            </Grid2>

            <Dialog
                open={dialogOpenSearch}
                onClose={() => setDialogOpenSearch(false)}
                aria-labelledby="search-dialog-title"
            >
                <DialogContent>
                    <SearchModal onClick={HandlePersonneSearch} functionMode={0} />
                    <Button variant="contained" color="secondary" onClick={handleResetPersonne}>
                        {t("RESET_SEARCH_PERSONNE")}
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GenericFilterPersonne;
