import React, {useState} from 'react';
import { Box, Button, Dialog, DialogContent, Grid2, Paper, Table, TableContainer, TextField } from '@mui/material';
import useTexts from "../../hooks/useTexte";
import {PauseCircleOutlineRounded} from "@mui/icons-material";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import FormationMangerAdminListUserWaitTableHead from "./FormationAdminListUserWaitTableHead";
import FormationMangerAdminListUserWaitExport from "./FormationAdminListUserWaitExport";
import FormationMangerAdminListUserWaitTableBody from "./FormationAdminListUserWaitTableBody";

const FormationMangerAdminListUserWait = ({formation, isAdministrator}) => {
    const fetchWithToken = useFetchWithToken();
    const [openModalListWait, setOpenModalListWait] = useState(false);
    const [usersWait, setUsersWait] = useState([]);
    const handleListUserWait = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/waitlist/${formation.id}`;
            const data = await fetchWithToken(apiUrl);

            setUsersWait(data);
            setOpenModalListWait(true)
        } catch (error) {
            console.error('erreur avec handleListUserWait', error);
        }

    };
    const handleCodeSycomoreChange = (e) => {
        setFilters({
            ...filters,
            codeSycomore: e.target.value,
        });
    };
    const [filters, setFilters] = React.useState({
        status: {
            'WAIT': false,
            'WAIT_VALIDATION': false,
            'VALIDATE': false,
        },
        'hideInWait': 1,
        codeSycomore: '',
    });


    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const {t} = useTexts();

    return formation.level != "cameleon" && (
        <>

            <Button
                className='azimuth-btn full-width'
                onClick={() => handleListUserWait()}
                startIcon={<PauseCircleOutlineRounded />}
            >
                {t('BUTTON_WAIT_LIST')}
            </Button>

            <Dialog open={openModalListWait} onClose={() => setOpenModalListWait(false)} maxWidth="lg" fullWidth>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Box>
                            <TextField
                                fullWidth
                                label="Filtre par code Sycomore"
                                name="codeSycomore"
                                value={filters.codeSycomore}
                                onChange={handleCodeSycomoreChange}
                                variant="outlined"
                                sx={{ mb: 1 }}
                            />
                            <FormationMangerAdminListUserWaitExport wish={formation} isAdministrator={isAdministrator}/>
                        </Box>
                    </form>
                    <Grid2 container spacing={2} sx={{ mt: 1 }}>
                        <Grid2 size="grow">
                            <TableContainer component={Paper}>
                                <Table>
                                    <FormationMangerAdminListUserWaitTableHead />
                                    <FormationMangerAdminListUserWaitTableBody
                                        isAdministrator={isAdministrator}
                                        wish={formation} filters={filters}
                                        handleListUserWait={handleListUserWait}
                                        usersWait={usersWait}
                                    />
                                </Table>
                            </TableContainer>
                        </Grid2>
                    </Grid2>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default FormationMangerAdminListUserWait;
