import React, {useEffect, useState} from 'react';
import {Container,} from '@mui/material';
import {useFetchWithToken} from '../hooks/useFetchWithToken';
import {useAuth} from "../Context/AuthContext";
import withAdminRole from "../hoc/withAdminRole";
import GenericFilter from "../components/Filter/GenericFilter";
import VoeuxListRow from "../components/Wish/VoeuLIstRow";
import LoaderWait from "../components/LoaderWait";

const AdminVoeuxListPage = () => {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        selectedType: "",
        selectedBranche: "",
        selectedstartDate: null,
        selectedendDate: null,
        selectedProvince: [],
        selectedDistrict: [],
        selectedPersonne: "",
        itemsPerPage: 10,
    });

    const [usersWithWishes, setUsersWithWishes] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const fetchWithToken = useFetchWithToken();
    const {isLoadingFunction} = useAuth();


    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/wishes/all`;

            const body = {
                branche: filters.selectedBranche,
                province: filters.selectedProvince,
                district: filters.selectedDistrict,
                startDate: filters.selectedstartDate,
                itemsPerPage: filters.itemsPerPage,
                endDate: filters.selectedendDate,
                level: filters.selectedType,
                user: (filters.selectedPersonne ? filters.selectedPersonne.id : null),
                page
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setUsersWithWishes(data.users);
            setTotalPages(data.totalPages);

        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
            setUsersWithWishes([]);
        }
    };

    useEffect(() => {
        if (filters.init === 1) {
            loadWishes();
        }
    }, [page, filters]);

    if (!isLoadingFunction) {
        return (<LoaderWait/>);
    }


    return (
        <Container
            maxWidth="xl"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <GenericFilter filters={filters} setPage={setPage} page={page} setFilters={setFilters}
                             totalPages={totalPages}/>
            <VoeuxListRow displayFiche={1} usersWithWishes={usersWithWishes}/>


        </Container>
    );
};
export default withAdminRole(AdminVoeuxListPage);
