import React, {useState} from 'react';
import {Box, Button, Dialog, DialogContent, DialogTitle, Typography,} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import CircularIcon from '@mui/icons-material/Description';
import FormationMangerItemMyDocBtn from "./FormationItemMyDocBtn";
import "./styles/Formation.css"

const MyDoc = ({ handleDownloadDocument }) => {
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const {t} = useTexts();

    const handleUploadDocumentsModal = async () => {
        setOpenDocumentsModal(true);
    };

    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
                className='azimuth-btn'
                sx={{width: 'fit-content !important'}}
                startIcon={<CircularIcon />}
                onClick={handleUploadDocumentsModal}
            >
                {t("UPLOAD_MES_DOCUMENTS")}
            </Button>

            <Dialog open={openDocumentsModal} onClose={handleCloseDocumentsModal} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <Typography variant="h5" textAlign={'center'}>Importer un document</Typography>
                </DialogTitle>
                <DialogContent>
                    <FormationMangerItemMyDocBtn
                        handleUploadDocumentsModal={() => handleDownloadDocument(handleCloseDocumentsModal)}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
};

export default MyDoc;
