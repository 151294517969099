import React, { useEffect, useState } from 'react';
import { Select, MenuItem, TextField, Dialog, DialogTitle, DialogContent, Modal, } from '@mui/material';

import useTexts from "../../hooks/useTexte";
import AnimationSuccess from '../Core/AnimationSuccess/AnimationSuccess';
import MoulinetteModalRestriction from "./MoulinetteModalRestriction";
import MoulinetteModalAction from "./MoulinetteModalAction";

const MoulinetteModal = ({
    openMoulinetteDialog,
    setOpenMoulinetteDialog,
    handleLaunchMoulinette,
    launchAnimation,
    setLaunchAnimation
}) => {
    const [moulinettePlaces, setMoulinettePlaces] = useState(0);
    const [selectionType, setSelectionType] = useState('');
    const [functionCodes, setFunctionCodes] = useState([""]); // Pour les codes fonction
    const [selectedSpecialities, setSelectedSpecialities] = useState([]); // Pour les spécialités

    const [showRestrictions, setShowRestrictions] = useState(false);


    const { t } = useTexts()
    useEffect(() => {
        setMoulinettePlaces(0)
        setFunctionCodes(['']);
        setSelectedSpecialities([]);
        setSelectionType('')
        setShowRestrictions(false)
    }, []);



    return (
        <Dialog fullWidth open={openMoulinetteDialog} onClose={() => setOpenMoulinetteDialog(false)}>
            <DialogTitle textAlign={'center'} color={'#29224f'}>{t("BUTTON_START_MOULINETTE")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="moulinettePlaces"
                    label={t("FIELD_NBR_PLACE_MOULINETTE")}
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={moulinettePlaces || 0}
                    onChange={(e) => setMoulinettePlaces(e.target.value)}
                />

                <Select
                    fullWidth
                    value={selectionType}
                    displayEmpty // Ajout pour afficher un texte par défaut quand rien n'est sélectionné

                    onChange={(e) => setSelectionType(e.target.value)}
                    variant="outlined"
                    margin="dense"
                >
                    <MenuItem value="">Sélectionner un type</MenuItem>
                    <MenuItem value="selection">{t("MOULINETTE_SECTION")}</MenuItem>
                    <MenuItem value="selectionAttribution">{t("MOULINETTE_ATTRIBUTION")}</MenuItem>
                </Select>
                <MoulinetteModalRestriction selectedSpecialities={selectedSpecialities} functionCodes={functionCodes}
                    setFunctionCodes={setFunctionCodes}
                    setSelectedSpecialities={setSelectedSpecialities}
                    showRestrictions={showRestrictions}
                    setShowRestrictions={setShowRestrictions}
                />
            </DialogContent>
            <MoulinetteModalAction functionCodes={functionCodes} setOpenMoulinetteDialog={setOpenMoulinetteDialog}
                selectedSpecialities={selectedSpecialities} handleLaunchMoulinette={handleLaunchMoulinette} moulinettePlaces={moulinettePlaces}
                selectionType={selectionType} setLaunchAnimation={setLaunchAnimation}

            />
            <Modal open={launchAnimation} onClose={() => setLaunchAnimation(false)} >
                <>
                    <AnimationSuccess />
                </>
            </Modal>
        </Dialog>
    )
};

export default MoulinetteModal;
