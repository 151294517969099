import {Button} from "@mui/material";
import React from "react";
import {allowAlgoStatus} from "../../Utils/AllowStatus";


const StagiareCardAdminActionUnExcludeMoulinette = ({user, isAdministrator, formation, allowStagiaireMoulinette}) => {
   return (isAdministrator && allowAlgoStatus.includes(user.status) && user.exclude_moulinette == 1 && formation.close == 0) && (
       <Button
           className="azimuth-btn full-width error"
           onClick={() => allowStagiaireMoulinette(user)}
       >
           Replacer dans la moulinette
       </Button>
       )
};

export default StagiareCardAdminActionUnExcludeMoulinette;
