import React, {useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle,} from '@mui/material';
import UserListPage from "../../Page/UserListPage";


const AdminDisplayFiche = ({user}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCodeSycomore, setSelectedCodeSycomore] = useState('');
    const handleCloseUserModal = () => {
        setDialogOpen(false);
        setSelectedCodeSycomore('');
    };
    const handleOpenUserModal = (codeSycomore) => {
        setSelectedCodeSycomore(codeSycomore);
        setDialogOpen(true);
    };


    return (
        <>
            <div><a className="voir-fiche" onClick={() => handleOpenUserModal(user.user_code_sycomore)}>
                Voir la fiche
            </a></div>

            <Dialog
                open={dialogOpen}
                onClose={handleCloseUserModal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>Informations Utilisateur</DialogTitle>
                <DialogContent>
                    <UserListPage codeSycomoreData={selectedCodeSycomore}/>
                </DialogContent>
                <Button onClick={handleCloseUserModal}>Fermer</Button>
            </Dialog>
        </>

    );
};

export default AdminDisplayFiche;
