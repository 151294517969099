import {MenuItem, TextField} from "@mui/material";
import React from "react";
import {LevelOptions} from "../../Utils/LevelOption";


const LevelSelect  = ({wish, classes, handleLevelChange})=>{
    return wish.branch && (
            <TextField
                select
                label="Niveau"
                value={wish.level}
                className={classes.select}
                onChange={(e) => handleLevelChange(e.target.value)}
                variant="outlined"
                fullWidth
            >
                {LevelOptions.filter(option => option.section.includes(wish.branch)).map((option, index) => (
                    <MenuItem key={index} className={classes.menuItem}
                              value={option.key}>{option.label}</MenuItem>
                ))}
            </TextField>
    )
}

export default LevelSelect