import React, { useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DragHandle} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ArrowDropDownCircle from '@mui/icons-material/ArrowDropDownCircleOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import TableChart from '@mui/icons-material/TableChartOutlined';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {positions} from '../../Utils/Positions';
import {useFetchWithToken} from '../../hooks/useFetchWithToken';
import Question from './Question';
import PreviewModal from './PreviewModal';
import './styles/CustomFormBuilder.css';
import {Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, SnackbarContent} from '@mui/material';
import useTexts from '../../hooks/useTexte';
import LoaderWait from "../LoaderWait";


const CustomFormBuilder = ({initialData, isModal, closeModal, user}) => {
    const [editSectionTitle, setEditSectionTitle] = useState(false);

    const [formId, setFormId] = useState(null);
    const [formName, setFormName] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [sections, setSections] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const {id: urlId} = useParams();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const loadFormSections = async () => {
        try {
            let apiUrl = '';

            if (user) {
                apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/template/${formId}`;
            } else {
                apiUrl = `${process.env.REACT_APP_API_URL}/api/form/template/${formId}`;
            }

            const data = await fetchWithToken(apiUrl);
            const newForm = data.data.map((section) => ({
                ...section,
                fields: section.fields || [],
            }));
            setSections(newForm);
            setFormName(data.name);
        } catch (error) {
            console.error('Erreur lors du chargement des sections:', error);
        } finally {
            // collapse les sections par défaut
            setSections((prevSections) => prevSections.map((section) => ({...section, expanded: false})));
        }
    };

    const getFormData = async () => {
        setFormName("");
        await loadFormSections();
        setIsLoading(false);
    };

    useEffect(() => {
        if (urlId) {
            setFormId(parseInt(urlId));
        } else if (initialData) {
            setFormId(parseInt(initialData));
        } else {
            setSections([
                {
                    id: Date.now(),
                    title: '',
                    fields: [],
                    expanded: true,
                },
            ]);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (formId) {
            getFormData(formId);
        }
    }, [formId]);

    const handleTitleClick = (index) => {
        setEditSectionTitle(index);
    };
    const handleTitleBlur = (index, value) => {
        updateSectionTitle(index, value);
        setEditSectionTitle(null);
    };

    const questionTypes = [
        {value: 'text', label: 'Réponse courte', icon: <ShortTextIcon/>},
        {value: 'textarea', label: 'Paragraphe', icon: <SubjectIcon/>},
        {value: 'radio', label: 'Choix unique', icon: <RadioButtonCheckedIcon/>},
        {value: 'checkbox', label: 'Choix multiple', icon: <CheckBoxIcon/>},
        {value: 'competences', label: 'Tableau', icon: <TableChart/>},
        {value: 'select', label: 'Liste déroulante', icon: <ArrowDropDownCircle/>},
    ];

    const addSection = () => {
        const newSection = {
            id: Date.now(),
            title: `Section ${sections.length + 1}`,
            fields: [],
            expanded: true,
        };
        setSections([...sections, newSection]);
    };
    const toggleSection = (sectionIndex) => {
        const updatedSections = sections.map((section, index) =>
            index === sectionIndex ? {...section, expanded: !section.expanded} : section
        );
        setSections(updatedSections);
    };
    const updateSectionTitle = (index, newTitle) => {
        const newSections = [...sections];
        newSections[index].title = newTitle;
        setSections(newSections);
    };
    const updateSection = (index, key, value) => {
        const updatedSections = [...sections];
        updatedSections[index][key] = value;
        setSections(updatedSections);
    };
    const removeSection = (sectionIndex) => {
        setSections(sections.filter((_, i) => i !== sectionIndex));
    };

    const addQuestion = (sectionIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex]) {
                console.error(`Section introuvable à l'index ${sectionIndex}`);
                return prevSections;
            }
            const newQuestion = {
                id: Date.now(),
                label: '',
                name: `new-question-${Date.now()}`,
                required: false,
                type: 'text',
                options: [],
                competences: [],
                levels: [],
            };
            const updatedFields = [...newSections[sectionIndex].fields, newQuestion];
            newSections[sectionIndex] = {
                ...newSections[sectionIndex],
                fields: updatedFields,
            };
            return newSections;
        });
    };
    const updateQuestion = (sectionIndex, questionIndex, updatedQuestion) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const fields = [...newSections[sectionIndex].fields];
            fields[questionIndex] = {
                ...fields[questionIndex],
                ...updatedQuestion,
            };
            newSections[sectionIndex] = {
                ...newSections[sectionIndex],
                fields,
            };
            return newSections;
        });
    };
    const removeQuestion = (sectionIndex, questionIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const updatedFields = newSections[sectionIndex].fields.filter((_, index) => index !== questionIndex);
            newSections[sectionIndex] = {
                ...newSections[sectionIndex],
                fields: updatedFields,
            };
            return newSections;
        });
    };

    const addOption = (sectionIndex, questionIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];

            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }

            const updatedOptions = newSections[sectionIndex].fields[questionIndex].options
                ? [...newSections[sectionIndex].fields[questionIndex].options]
                : [];

            updatedOptions.push('');
            newSections[sectionIndex].fields[questionIndex] = {
                ...newSections[sectionIndex].fields[questionIndex],
                options: updatedOptions,
            };

            return newSections;
        });
    };
    const updateOption = (sectionIndex, questionIndex, optionIndex, newOptionValue) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields) {
                console.error(`Section ou fields introuvables à l'index ${sectionIndex}`);
                return prevSections;
            }
            const updatedFields = [...newSections[sectionIndex].fields];
            if (!updatedFields[questionIndex] || !updatedFields[questionIndex].options) {
                console.error(`Question ou options introuvables à l'index ${questionIndex}`);
                return prevSections;
            }
            const updatedOptions = [...updatedFields[questionIndex].options];
            if (updatedOptions[optionIndex] === undefined) {
                console.error(`Option introuvable à l'index ${optionIndex}`);
                return prevSections;
            }
            updatedOptions[optionIndex] = newOptionValue;
            updatedFields[questionIndex] = {
                ...updatedFields[questionIndex],
                options: updatedOptions,
            };
            newSections[sectionIndex] = {
                ...newSections[sectionIndex],
                fields: updatedFields,
            };
            return newSections;
        });
    };
    const removeOption = (sectionIndex, questionIndex, optionIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const updatedOptions = newSections[sectionIndex].fields[questionIndex].options.filter(
                (_, index) => index !== optionIndex
            );
            newSections[sectionIndex].fields[questionIndex] = {
                ...newSections[sectionIndex].fields[questionIndex],
                options: updatedOptions,
            };
            return newSections;
        });
    };
    const moveUpOption = (sectionIndex, questionIndex, optionIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const updatedOptions = newSections[sectionIndex].fields[questionIndex].options;
            if (optionIndex === 0) {
                return prevSections;
            }
            const temp = updatedOptions[optionIndex];
            updatedOptions[optionIndex] = updatedOptions[optionIndex - 1];
            updatedOptions[optionIndex - 1] = temp;
            newSections[sectionIndex].fields[questionIndex] = {
                ...newSections[sectionIndex].fields[questionIndex],
                options: updatedOptions,
            };
            return newSections;
        });
    };
    const moveDownOption = (sectionIndex, questionIndex, optionIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const updatedOptions = newSections[sectionIndex].fields[questionIndex].options;
            if (optionIndex === updatedOptions.length - 1) {
                return prevSections;
            }
            const temp = updatedOptions[optionIndex];
            updatedOptions[optionIndex] = updatedOptions[optionIndex + 1];
            updatedOptions[optionIndex + 1] = temp;
            newSections[sectionIndex].fields[questionIndex] = {
                ...newSections[sectionIndex].fields[questionIndex],
                options: updatedOptions,
            };
            return newSections;
        });
    };

    const addLevel = (sectionIndex, questionIndex, newLevel = '') => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const question = newSections[sectionIndex].fields[questionIndex];
            if (question.type !== "competences") {
                console.error(`La question à l'index ${questionIndex} n'est pas de type "competences"`);
                return prevSections;
            }
            const updatedOptions = question.options ? [...question.options] : [];
            updatedOptions.push(newLevel);
            newSections[sectionIndex].fields[questionIndex] = {
                ...question,
                options: updatedOptions,
            };
            return newSections;
        });
    };
    const updateLevel = (sectionIndex, questionIndex, levelIndex, newLevelValue) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const question = newSections[sectionIndex].fields[questionIndex];
            if (!Array.isArray(question.options)) {
                console.error(`Options n'est pas un tableau à l'index de la question ${questionIndex}`);
                return prevSections;
            }
            if (levelIndex < 0 || levelIndex >= question.options.length) {
                console.error(`Index de niveau invalide: ${levelIndex}`);
                return prevSections;
            }
            const updatedOptions = [...question.options];
            updatedOptions[levelIndex] = newLevelValue;
            newSections[sectionIndex].fields[questionIndex] = {
                ...question,
                options: updatedOptions,
            };
            return newSections;
        });
    };
    const removeLevel = (sectionIndex, questionIndex, levelIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const question = newSections[sectionIndex].fields[questionIndex];
            if (!Array.isArray(question.options)) {
                console.error(`Options n'est pas un tableau à l'index de la question ${questionIndex}`);
                return prevSections;
            }
            if (levelIndex < 0 || levelIndex >= question.options.length) {
                console.error(`Index de niveau invalide: ${levelIndex}`);
                return prevSections;
            }
            const updatedOptions = question.options.filter((_, index) => index !== levelIndex);
            newSections[sectionIndex].fields[questionIndex] = {
                ...question,
                options: updatedOptions,
            };
            return newSections;
        });
    };

    const addCompetence = (sectionIndex, questionIndex, newCompetence = '') => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const question = newSections[sectionIndex].fields[questionIndex];
            if (question.type !== "competences") {
                console.error(`La question à l'index ${questionIndex} n'est pas de type "competences"`);
                return prevSections;
            }
            const updatedCompetences = question.competences ? [...question.competences] : [];
            updatedCompetences.push(newCompetence);
            newSections[sectionIndex].fields[questionIndex] = {
                ...question,
                competences: updatedCompetences,
            };
            return newSections;
        });
    };
    const updateCompetence = (sectionIndex, questionIndex, competenceIndex, updatedCompetence) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const updatedCompetences = (newSections[sectionIndex].fields[questionIndex].competences || []).map(
                (competence, index) => (index === competenceIndex ? updatedCompetence : competence)
            );
            newSections[sectionIndex].fields[questionIndex] = {
                ...newSections[sectionIndex].fields[questionIndex],
                competences: updatedCompetences,
            };
            return newSections;
        });
    };
    const removeCompetence = (sectionIndex, questionIndex, competenceIndex) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            if (!newSections[sectionIndex] || !newSections[sectionIndex].fields[questionIndex]) {
                console.error(`Section ou question introuvable à l'index ${sectionIndex}/${questionIndex}`);
                return prevSections;
            }
            const updatedCompetences = (newSections[sectionIndex].fields[questionIndex].competences || []).filter(
                (_, index) => index !== competenceIndex
            );
            newSections[sectionIndex].fields[questionIndex] = {
                ...newSections[sectionIndex].fields[questionIndex],
                competences: updatedCompetences,
            };
            return newSections;
        });
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }
        const newSections = Array.from(sections);
        const [removed] = newSections.splice(source.index, 1);
        newSections.splice(destination.index, 0, removed);
        setSections(newSections);
    };
    const onDragEnd2 = (result) => {
        const {source, destination} = result;
        if (!destination) return;
        if (source.droppableId === destination.droppableId) {
            const section = sections.find(s => `fields-${s.id}` === source.droppableId);
            const updatedFields = Array.from(section.fields);
            const [reorderedItem] = updatedFields.splice(source.index, 1);
            updatedFields.splice(destination.index, 0, reorderedItem);
            const updatedSections = sections.map(s => {
                if (s.id === section.id) {
                    return {...s, fields: updatedFields};
                }
                return s;
            });
            setSections(updatedSections);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Gestion d'erreurs
        if (!formName || sections.length === 0) {
            alert("Veuillez entrer un nom de formulaire et au moins une section.");
            return;
        }
        for (const section of sections) {
            if (!section.title) {
                alert(`Veuillez entrer un titre pour la section ${section.title || 'sans titre'}`);
                return;
            }
            if (!section.fields || section.fields.length === 0) {
                alert(`La section "${section.title}" doit contenir au moins un champ.`);
                return;
            }
            for (const field of section.fields) {
                if (!field.label || !field.type) {
                    alert(`Chaque question doit avoir un libellé et un type. Problème rencontré dans la section "${section.title}".`);
                    return;
                }
                if (['radio', 'checkbox', 'select'].includes(field.type) && (!field.options || field.options.length === 0)) {
                    alert(`Le champ "${field.label}" de la section "${section.title}" doit avoir des options.`);
                    return;
                }
            }
        }
        try {
            let apiUrl = "";
            let method = "";

            if (!formId) {
                if (user) {
                    apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/add`;
                } else {
                    apiUrl = `${process.env.REACT_APP_API_URL}/api/form/add`;
                }

                method = "POST";
            } else {
                if (user) {
                    apiUrl = `${process.env.REACT_APP_API_URL}/api/myform/${formId}`;
                } else {
                    apiUrl = `${process.env.REACT_APP_API_URL}/api/form/${formId}`;
                }
                method = "PUT";
            }
            const body = {
                name: formName,
                sections: sections.map((section) => ({
                    ...section,
                    fields: section.fields.map((field) => ({
                        ...field,
                        options: field.options || [],
                        competences: field.competences || [],
                    }))
                }))
            };

            const result = await fetchWithToken(apiUrl, method, body);
            if (!isModal) {
                if (formId) {
                    navigate(`/creer-template/${formId}`);
                } else {
                    navigate(`/creer-template/${result.id}`);
                }
            } else {
                if (closeModal) {
                    closeModal()
                }
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
            alert('Une erreur est survenue lors de la soumission du formulaire. Veuillez réessayer.');
        }
    };

    const openPreview = () => setPreviewOpen(true);
    const closePreview = () => setPreviewOpen(false);

    return (
        <Box className="form-editor-container">
            <TextField
                fullWidth
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                label='Nom du formulaire'
                variant='standard'
                inputProps={{style: {fontSize: 32}}}
                InputLabelProps={{style: {fontSize: 18}}}
                sx={{marginBottom: 2}}
            />
            {isLoading && <LoaderWait />}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppableSections">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {sections.map((section, sectionIndex) => (
                                <Draggable
                                    key={section.id}
                                    draggableId={`section-${section.id}`}
                                    index={sectionIndex}
                                >
                                    {(provided) => (
                                        <Box
                                            className="section-box"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <Box className="section-header">
                                                <Box {...provided.dragHandleProps} className='drag-handle'
                                                     style={{
                                                         position: 'absolute',
                                                         top: '0',
                                                         left: '50%',
                                                         transform: 'translateX(-50%)',
                                                         marginTop: '10px',
                                                         zIndex: 1000
                                                     }}
                                                     id='dragHandle'
                                                >
                                                    <DragHandle/>
                                                </Box>
                                                {editSectionTitle === sectionIndex ? (
                                                    <TextField
                                                        fullWidth
                                                        value={section.title}
                                                        onChange={(e) => updateSectionTitle(sectionIndex, e.target.value)}
                                                        onBlur={(e) => handleTitleBlur(sectionIndex, e.target.value)}
                                                        autoFocus
                                                        onMouseEnter={(e) => e.target.focus()}
                                                        label='Titre de section'
                                                        variant='standard'
                                                        inputProps={{style: {fontSize: 18}}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        value={section.title}
                                                        onClick={() => handleTitleClick(sectionIndex)}
                                                        variant='standard'
                                                        label='Titre de section'
                                                        inputProps={{style: {fontSize: 18}}}
                                                    />
                                                )}
                                                <IconButton
                                                    onClick={() => toggleSection(sectionIndex)}
                                                    aria-label="toggle section"
                                                >
                                                    {section.expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                                </IconButton>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => removeSection(sectionIndex)}
                                                    aria-label="delete section"
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Box>
                                            {section.expanded && (
                                                <Box className="section-content">
                                                    <Divider sx={{margin: '10px 0'}}/>
                                                    <FormControl fullWidth style={{margin: '10px 0'}}>
                                                        <InputLabel id="restriction-label">Restriction</InputLabel>
                                                        <Select
                                                            labelId="restriction-label"
                                                            multiple
                                                            value={section.rules ? section.rules : []}
                                                            onChange={(e) => updateSection(sectionIndex, 'rules', e.target.value)}
                                                            input={<OutlinedInput label="Restriction"/>}
                                                            renderValue={(selected) => (
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: '4px'
                                                                }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value}
                                                                              label={positions.find(pos => pos.code === value).label}/>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            {positions.map((pos) => (
                                                                <MenuItem key={pos.code} value={pos.code}>
                                                                    {pos.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <DragDropContext onDragEnd={onDragEnd2}>
                                                        <Droppable droppableId={`fields-${section.id}`} type='field'>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef} {...provided.droppableProps} >
                                                                    {section.fields?.map((field, fieldIndex) => (
                                                                        <Draggable
                                                                            key={field.id}
                                                                            draggableId={`field-${field.id}`}
                                                                            index={fieldIndex}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    className="question-box"
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                >
                                                                                    <div
                                                                                        id='dragHandle' {...provided.dragHandleProps}
                                                                                        style={{alignSelf: 'center'}}>
                                                                                        <DragHandle/>
                                                                                    </div>
                                                                                    <Question
                                                                                        key={field.id}
                                                                                        question={field}
                                                                                        questionIndex={fieldIndex}
                                                                                        sectionIndex={sectionIndex}
                                                                                        questionTypes={questionTypes}
                                                                                        updateQuestion={updateQuestion}
                                                                                        updateOption={updateOption}
                                                                                        removeOption={removeOption}
                                                                                        addOption={addOption}
                                                                                        removeQuestion={removeQuestion}
                                                                                        addCompetence={addCompetence}
                                                                                        updateCompetence={updateCompetence}
                                                                                        removeCompetence={removeCompetence}
                                                                                        addLevel={addLevel}
                                                                                        updateLevel={updateLevel}
                                                                                        removeLevel={removeLevel}
                                                                                        moveUpOption={moveUpOption}
                                                                                        moveDownOption={moveDownOption}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => addQuestion(sectionIndex)}
                                                        sx={{marginBottom: 2}}
                                                    >
                                                        Ajouter une question
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className='forms-buttons'>
                <Button variant="contained" color="primary" onClick={addSection} startIcon={<AddIcon/>}>
                    Ajouter une section
                </Button>
                <Button variant="outlined" color="primary" onClick={openPreview} startIcon={<PreviewIcon/>}>
                    Previsualiser le form
                </Button>
            </div>
            <Divider sx={{margin: '20px 0'}}/>
            <div className='forms-navButtons'>
                <Button variant="contained" color='success' onClick={handleSubmit} startIcon={<SaveIcon/>}>
                    {t('BUTTON_SAVE')}
                </Button>
                <Button variant='contained' color='error'
                        onClick={() => {
                            if (!isModal) {
                                navigate('/template')
                            } else {
                                if (closeModal) {
                                    closeModal()
                                }
                            }
                        }
                        }>
                    {t('BUTTON_QUITTER')}
                </Button>
                <Snackbar
                    open={snackbarOpen}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <SnackbarContent message="Formulaire enregistré avec succès !"/>
                </Snackbar>
            </div>

            <PreviewModal
                open={previewOpen}
                onClose={closePreview}
                sections={sections}
            />
        </Box>
    );
};

export default CustomFormBuilder;
