import { useSystem } from "../Context/System";
import { sprintf } from 'sprintf-js';

const useTexts = () => {
    const { textes } = useSystem();

    const t = (key , ...args) => {
        try {
            const foundText = textes.find((text) => text.key === key);
            if (foundText) {
                return sprintf(foundText.text, ...args);
            }else{
                return  key
            }
        } catch {

            return key;
        }
    };

    return {
        t
    };
};

export default useTexts;
