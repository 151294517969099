import React, { useState, useEffect, useCallback } from 'react';
import {Container, Typography, Divider, Table, TableContainer,} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import withAdminRole from "../hoc/withAdminRole";
import './styles/TextEditor.css';
import useTexts from '../hooks/useTexte';
import ForeignPageEditor from "../components/ForeignPageEditor";
import ForeignTableHead from "../components/ForeignTableHead";
import ForeignTableBody from "../components/ForeignTableBody";

const ForeignPage = () => {
    const [etrangers, setEtrangers] = useState([]);


    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();


    const getEtranger = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/getEtrangers`;
            const data = await fetchWithToken(apiUrl, "POST", {});
            setEtrangers(data);
        } catch (error) {
            console.error(error);
        }
    }, [fetchWithToken]);

    useEffect(() => {
        getEtranger();
    }, []);

    const handleDeleteEtranger = async (id) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/deleteEtranger`;
            await fetchWithToken(apiUrl, 'POST', { function_to_delete: id });
            getEtranger();
        } catch (error) {
            console.error('Error while deleting foreign user: ', error);
        }
    };

    return (
        <Container className='textEditor-container'>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: 'black', margin: '16px 0' }}>
                {t("ADD_ETRANGER")}
            </Typography>
            <div className='add-text-container desktop-restrict'>
               <ForeignPageEditor  getEtranger={getEtranger}/>
            </div>
            <Divider />
            <TableContainer sx={{ maxHeight: '100%' }} className='desktop-restrict'>
                <Table stickyHeader size={'small'}>
                    <ForeignTableHead />
                    <ForeignTableBody etrangers={etrangers} getEtranger={getEtranger} handleDeleteEtranger={handleDeleteEtranger}/>
                </Table>
            </TableContainer>

        </Container>
    );
};

export default withAdminRole(ForeignPage);
