import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography, InputLabel, Select, MenuItem, Box, FormControl} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import './styles/StagiaireCard.css';
import StagiareCardDocument from "./StagiareCardDocument";
import StagiareCardFiche from "./StagiareCardFiche";
import StagiareCardFichePdf from "./StagiareCardFichePdf";
import StagiareCardMedia from "./StagiareCardMedia";

const StagiareCard = ({user, attachments, teams, onAssign}) => {
    const [imageUrl, setImageUrl] = useState('');
    const fetchWithToken = useFetchWithToken();
    const [path, setPath] = useState('');
    const [selectedTeam, setSelectedTeam] = useState(user.formation_user_group || ''); // Selected team for assignment

    const dataImage = {
        "formation_id": user.formation_id,
        "auth": "mestre",
    };


    const fetchImage = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/images/${path}/300/300`;
            const imageData = await fetchWithToken(apiUrl, "POST", dataImage, false, "TEXT");
            setImageUrl(imageData);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image :', error);
        }
    };

    useEffect(() => {
        if (attachments) {
            const photoAttachment = attachments.find(attachment => attachment.type === 'photo');
            if (photoAttachment) {
                setPath(photoAttachment.path);
            }
        }
    }, []);

    useEffect(() => {
        if (path) {
            // console.log("Fetching image for path:", path); // Debug
            fetchImage();
            return () => URL.revokeObjectURL(imageUrl);
        }
    }, [path]);

    const handleTeamChange = (event) => {
        const teamId = event.target.value;
        setSelectedTeam(teamId);
        onAssign(user.formation_user_id, teamId);
    };

    return (
        <Card id="stagiaire-card-item">
            <StagiareCardMedia imageUrl={imageUrl} />
            <CardContent id='stagiaire-card-content'>
                <Typography variant="h6">
                    {user.user_code_sycomore ? `${user.user_code_sycomore} - ` : ""}{user.user_firstname} {user.user_lastname}
                </Typography>
                <div id='buttons'>
                    <StagiareCardFiche user={user} />
                    <StagiareCardDocument user={user} />
                    <StagiareCardFichePdf user={user} />
                    <Box id='select' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }}>
                        <FormControl fullWidth sx={{ height: '40px' }}>
                            <InputLabel id='label-equipe'>Équipe</InputLabel>
                            <Select
                                labelId='label-equipe'
                                label="Équipe"
                                value={selectedTeam}
                                onChange={handleTeamChange}
                                fullWidth
                                sx={{ width: '132px', maxHeight: '40px', height: '40px', lineHeight: '40px' }}
                                defaultValue={"Aucune"}
                            >
                                <MenuItem value="">
                                    <em>Aucune</em>
                                </MenuItem>
                                {teams.map(team => (
                                    <MenuItem key={team.id} value={team.id}>{team.nom}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </CardContent>
        </Card>
    );
};
export default StagiareCard;
