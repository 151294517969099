import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Collapse, Box } from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { useNavigate } from "react-router-dom";
import { ExpandMore, ExpandLess, Download } from '@mui/icons-material';
import StructureItemListChef from "./StructureItemListChef";
import { saveAs } from 'file-saver';
import FeedbackFormModal from '../Feedback/FeedbackFormModal';
import FeedbackForm from '../Feedback/FeedbackForm';
import './styles/Structure.css';

const StructureItem = ({ structure, list, size }) => {
    const [chefs, setChefs] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const navigate = useNavigate();
    const hasChild = list.filter(u => u.parent_id === structure.id).length > 0;
    const [modalAvisEdit, setModalAvisEdit] = useState(false);
    const [openModalAvis, setOpenModalAvis] = useState(false);
    const [currentAvis, setCurrentAvis] = useState(null);
    const [selectedChefAvis, setSelectedChefAvis] = useState(null);
    const [selectedChefType, setSelectedChefType] = useState(null);
    const [showChef, setShowChef] = useState(size == 1 ? true : false);

    const urlStructId = parseInt(window.location.pathname.split('/')[2]);
    const isStructActual = urlStructId === structure.id;

    useEffect(() => {
        if (selectedChefAvis) {
            setOpenModalAvis(true);
        }
    }, [selectedChefAvis]);

    const handleCloseModalAvis = () => {
        setOpenModalAvis(false);
        setSelectedChefAvis(null);
    };

    const handleNavigation = () => {
        if (hasChild)
            navigate(`/mes-maitrises/${structure.id}`);
    };

    async function exportChef() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/unite/${structure.id}/maitrise/export`;
            const response = await fetchWithToken(apiUrl, 'POST', {}, false, "BLOB");

            if (response) {
                // Télécharge le fichier en utilisant file-saver
                saveAs(response, `list_${structure.nom}.xlsx`);
            } else {
                console.error('Erreur lors de l\'exportation : Réponse vide');
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function loadChefs() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/unite/${structure.id}/maitrise`;
            const data = await fetchWithToken(apiUrl);
            setChefs(data.chefs);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        loadChefs();
    }, []);

    const sortedChefs = chefs.sort((a, b) => {
        // Prioritize chefs with 'ch' or 'com' in fonction
        if (a.fonction === 'CH' || a.fonction === 'COM') return -1;
        if (b.fonction === 'CH' || b.fonction === 'COM') return 1;
        return 0;
    });

    function formatUnitName(name) {
        return name.replace(/L /g, "L'");
    }

    return (
        <Card className='units-item-container'>
            <CardContent className='units-item-content'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box variant="h6" onClick={handleNavigation}
                        sx={{
                            // cursor: !isStructActual ? 'pointer' : 'default',
                            mb: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start', // Add this line to center the content horizontally
                            textAlign: 'left', // Add this line to center the text
                            marginBottom: 0,
                        }}
                    >
                        {chefs.length > 0 ? (
                            <IconButton onClick={(event) => {
                                event.stopPropagation();
                                setShowChef(!showChef);
                            }}>
                                {showChef ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        ): (
                            <Box sx={{ width: '40px' }}></Box>
                        )}
                        <Box className="unite-name-container">
                            <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '400' }} className={(chefs.length > 0 && (hasChild && !isStructActual)) ? 'unite-name' : 'unite-name-no-chef'}>
                                {formatUnitName(structure.nom)}
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={() => exportChef(!showChef)}>
                        <Download />
                    </IconButton>
                </Box>
                <Collapse in={showChef} timeout="auto" unmountOnExit>
                    <div className='units-members-container'>
                        <StructureItemListChef
                            structure={structure}
                            loadChefs={loadChefs}
                            showChef={showChef}
                            setCurrentAvis={setCurrentAvis}
                            setModalAvisEdit={setModalAvisEdit}
                            sortedChefs={sortedChefs}
                            setSelectedChefAvis={setSelectedChefAvis}
                            setSelectedChefType={setSelectedChefType}
                        />
                    </div>
                </Collapse>

            </CardContent>
            <FeedbackFormModal open={openModalAvis} onClose={handleCloseModalAvis}>
                <FeedbackForm
                    chef={selectedChefAvis}
                    structure={structure}
                    onClose={handleCloseModalAvis}
                    editable={modalAvisEdit}
                    loadChef={loadChefs}
                    currentAvis={currentAvis}
                    selectedChefType={selectedChefType}
                />
            </FeedbackFormModal>
        </Card>
    );
};

export default StructureItem;
