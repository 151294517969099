import React, {useEffect, useState} from 'react';
import {useFetchWithToken} from '../../hooks/useFetchWithToken';
import { Drawer, Divider, Typography } from '@mui/material';
import SearchResult from "./SearchResult";
import './styles/Search.css';
import SearchModalInput from "./SearchModalInput";

const SearchModal = ({modal , open, onClose, onClick, functionMode,foreign }) => {
    const fetchWithToken = useFetchWithToken();
    const [searchSycomore, setSearchSycomore] = useState('');
    const [searchLastname, setSearchLastname] = useState('');
    const [searchFirstname, setSearchFirstname] = useState('');
    const [Chefs, setChefs] = useState([]);

    const handleSearch = async () => {
        if (searchSycomore || searchLastname != "" || searchFirstname != "") {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/search`;
            const body = ({
                sycomore: searchSycomore, lastname: searchLastname, firstname: searchFirstname,
                foreign
            })
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setChefs(data.chefs)
        } else {
            setChefs([])
        }
    }

    useEffect(() => {
        setSearchSycomore("")
        setSearchFirstname("")
        setSearchLastname("")
    }, []);
    useEffect(() => {
        handleSearch();
    }, [searchSycomore, searchLastname, searchFirstname]);


    const content = (
        <>
            <Typography variant="h6" gutterBottom>
                Rechercher un Chef
            </Typography>
            <SearchModalInput label="Code Sycomore" value={searchSycomore} callBack={setSearchSycomore} />
            <SearchModalInput label="Nom de famille" value={searchLastname} callBack={setSearchLastname} />
            <SearchModalInput label="Prénom" value={searchFirstname} callBack={setSearchFirstname}  />
            <Divider style={{ margin: '16px 0' }} />
            <div className='search-result-card-container'>
                {Chefs && Chefs.map((chef, index) => (
                    <SearchResult
                        key={index}
                        person={chef}
                        handleClick={onClick}
                        functionMode={functionMode}
                    />
                ))}
            </div>
        </>
    );
    return modal ? (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div style={{width: 300, padding: 16}}>
                {content}
            </div>
        </Drawer>
) : (
        <div>
            {content}
        </div>
    );
};

export default SearchModal;
