import React, { useEffect, useState } from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import FormationItem from '../components/Formation/FormationItem';
import { useAuth } from '../Context/AuthContext';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import LoaderWait from "../components/LoaderWait";

const FormationListPage = () => {
    const { isLoadingFunction, currentFunction } = useAuth();
    const fetchWithToken = useFetchWithToken();

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myfomrations`;
            const data = await fetchWithToken(apiUrl);
            setWishes(data);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };
    useEffect(() => {
        loadWishes();
    }, [currentFunction])

    const [wishes, setWishes] = useState([]);

    if (!isLoadingFunction) {
        return <LoaderWait />;
    }

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Grid2 container spacing={2}>
                {wishes.length > 0 ? (
                    wishes.map((wish, index) => (
                        <FormationItem key={index} formation={wish} index={index} />
                    ))
                ) : (
                    <Typography variant="h5" sx={{ textAlign: 'center', width: '100%' }}>
                        Aucun formation trouvée
                    </Typography>
                )}
            </Grid2>
        </Box>
    );
};

export default FormationListPage;
