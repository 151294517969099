import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseRounded from '@mui/icons-material/Close';
import { makeStyles } from "tss-react/mui";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import { useSystem } from "../../Context/System";
import { useAuth } from "../../Context/AuthContext";
import { style } from "../../Utils/style";
import FunctionSelect from "./FunctionSelect";
import BranchSelect from "./BranchSelect";
import LevelSelect from "./LevelSelect";
import DateSelect from "./DateSelect";
import LoaderWait from "../LoaderWait";
import './styles/AddWishForm.css';
import { cepBranchTypeNoFreeDate } from "../../Utils/CepBranchType";
import ChangeConfigForm from './ChangeConfigForm';
import Speciality from "./Speciality";

const useStyles = makeStyles()(() => ({
    dialogTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '18px', // Adjust font size for mobile
        color: style.bleu1,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px', // Reduce gap for mobile
        padding: '10px', // Add padding for mobile
    },
    openSession: {
        backgroundColor: '#e0f7fa',
        fontWeight: 'bold',
        color: 'green',
    },
    noSession: {
        backgroundColor: 'white',
        fontWeight: 'bold',
        color: 'black',
    },
}));

const AddWishForm = ({ onFormOpen, onClose, onAdd, LevelOptions, branchOptions }) => {
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();
    const { openMonth, currentYear: systemYear } = useSystem()
    const { currentFunction } = useAuth();
    const { classes } = useStyles();

    const [wish, setWish] = useState({
        date: '',
        function_id_2: currentFunction,
        level: '',
        branch: '',
        formation: ["no_preference"],
        cepBranchType: ["no_preference"],
        specialty: "no_preference"
    });

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedFunction, setSelectedFunction] = useState(currentFunction);
    const [selectedBranch, setSelectedBranch] = useState();
    const [selectedLevel, setSelectedLevel] = useState();

    const [specialtyOption, setSpecialtyOption] = useState(false);
    const [specificFormation, setSpecificFormation] = useState(false);
    const [saturdays, setSaturdays] = useState([]);
    const [openDates, setOpenDates] = useState([]);
    const [forceSelectMonth, setForceSelectMonth] = useState(true);
    const [canSelectDate, setCanSelectDate] = useState();
    const [choiceCep, setChoiceCep] = useState();

    const [resetFormation, setResetFormation] = useState(false);
    const [resetTypeFormation, setResetTypeFormation] = useState(false);

    const [trainingSessions, setTrainingSessions] = useState([]);
    const [trainingSessionsDates, setTrainingSessionsDates] = useState([]);

    const loadFormation = async (branche, level, month) => {
        try {
            const body = { branche, level, month,  };

            // Ajouter specialty seulement s'il est défini et différent de "no-specialty"
            if (wish.specialty && wish.specialty !== "no_preference") {
                body.specialty = wish.specialty;
            }

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationdates`;
            const data = await fetchWithToken(apiUrl, "POST", body);
            return data;
        } catch (error) {
            console.error(error);
        }
        return [];
    };

    const changeFunction = (e) => {
        setSelectedFunction(e);
        setWish(prev => ({ ...prev, function_id_2: e }));
    };

    // const handleCepBranchTypeChange = (selectedId) => {
    //     setWish((prev) => {
    //         const currentFormations = prev.cepBranchType || [];

    //         // Vérifie si l'ID est déjà dans la liste
    //         const isAlreadySelected = currentFormations.includes(selectedId);

    //         // Si l'ID est déjà dans la liste, on le retire ; sinon, on l'ajoute
    //         const updatedFormations = isAlreadySelected
    //             ? currentFormations.filter((id) => id !== selectedId) // Supprime l'ID
    //             : [...currentFormations, selectedId]; // Ajoute l'ID

    //         return {
    //             ...prev,
    //             cepBranchType: updatedFormations,
    //         };
    //     });

    // };

    const handleFormationChange = (selectedId) => {
        setWish((prev) => {
            const currentFormations = prev.formation || [];

            if (Array.isArray(selectedId) && selectedId.length === 0) {
                // Si un tableau vide est passé, vider la liste
                return {
                    ...prev,
                    formation: [],
                };
            }

            // Vérifie si l'ID est déjà dans la liste
            const isAlreadySelected = currentFormations.includes(selectedId);

            // Si l'ID est déjà dans la liste, on le retire ; sinon, on l'ajoute
            const updatedFormations = isAlreadySelected
                ? currentFormations.filter((id) => id !== selectedId) // Supprime l'ID
                : [...currentFormations, selectedId]; // Ajoute l'ID

            return {
                ...prev,
                formation: updatedFormations,
            };
        });
    };

    const getSaturdaysOfMonth = (year, month) => {
        const dates = [];
        let date = new Date(year, month, 1);

        while (date.getDay() !== 6) {
            date.setDate(date.getDate() + 1);
        }

        while (date.getMonth() === month) {
            const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
            const formattedDate = localDate.toISOString().slice(0, 10); // Formater en YYYY-MM-DD
            dates.push(formattedDate);
            date.setDate(date.getDate() + 7);
        }
        return dates;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onAdd(wish);
        onClose();
    };

    const handleBranchChange = (newBranch) => {
        setWish(prev => ({ ...prev, branch: newBranch, level: '', date: '', formation: [], cepBranchType: [] }));

        setSelectedBranch(newBranch);
        setSelectedLevel(undefined);
        setForceSelectMonth(true);
        setTrainingSessions([]);
        setTrainingSessionsDates([]);

        const dataBranch = branchOptions.find(option => option.key == newBranch);
        setChoiceCep(dataBranch.choicecep)
    };

    const handleDateChange = (newDate) => {
        setWish({ ...wish, date: newDate, formation: [], cepBranchType: [] })
    };

    const handleLevelChangeNext = (newLevel) => {
        setWish(prev => ({ ...prev, level: newLevel, date: '' }));
        setSelectedLevel(newLevel);
        setSelectedMonth(undefined);
    };

    const handleMonthChange = (newLevel) => {
        setWish({ ...wish, date: false, });
        setSelectedMonth(newLevel)
        // setSelectedBranch(undefined);
        // setSelectedLevel(undefined);
    };

    const handleSpecialtyChange = (newSpecialty) => {
        setWish({...wish, specialty: newSpecialty});
    };

    const handleLoadFormationDate = (data, level) => {

        if (!data.canSelectDate || cepBranchTypeNoFreeDate.includes(wish.specialty)) {
            loadFormation(selectedBranch, level).then(data => {
                setTrainingSessions(data.formations);
                const dates = [];
                const datesEnd = [];
                if (data.formations && data.formations.length) {
                    data.formations.forEach(d => dates.push(d.date));
                    data.formations.forEach(d => datesEnd.push({ "start": d.date, "end": d.end_date }));
                }
                setSaturdays(dates);
                setTrainingSessionsDates(datesEnd);
                handleLevelChangeNext(level);
            });
        } else {
            handleLevelChangeNext(level);
            setTrainingSessions([]);
        }
    };
// UseEffect to handle loading formations whenever level or specialty changes

    const handleLevelChange = (newLevel) => {
        const data = LevelOptions.find(option => option.key == newLevel);

        // Reset specialty when level changes
        setWish(prev => ({ ...prev, level: newLevel, date: '', specialty: "no_preference" }));
        setCanSelectDate(data.canSelectDate);
        setSelectedMonth(undefined);
        handleLoadFormationDate(data, newLevel);

    };

    useEffect(() => {
        setWish({
            date: '',
            function: '',
            level: '',
            branch: '',
            formation: [],
            cepBranchType: [],
            specialty: "no_preference"
        });
        setSelectedMonth(undefined);
        setSelectedLevel(undefined);
        setSelectedBranch(undefined);
        setResetTypeFormation(false)
        setResetFormation(false)
        setForceSelectMonth(true);
        setTrainingSessions([]);
        setTrainingSessionsDates([]);
    }, [onFormOpen]);

    useEffect(() => {
        if (specificFormation == 1) {
            setSpecialtyOption(false)
        }
    }, [specificFormation]);

    useEffect(() => {
        if (specialtyOption == 1) {
            setSpecificFormation(false)
        }
    }, [specialtyOption]);

    useEffect(() => {
        if (resetFormation) {
            setWish(prev => ({ ...prev, formation: ["no_preference"] }))
            setResetFormation(false)
        }
    }, [resetFormation]);

    useEffect(() => {
        if (resetTypeFormation) {
            setWish(prev => ({ ...prev, cepBranchType: ["no_preference"] }))
            setResetTypeFormation(false)
        }
    }, [resetTypeFormation]);

    useEffect(() => {
        if (forceSelectMonth) {
            setSelectedMonth(undefined);
            setForceSelectMonth(false);
            setResetTypeFormation(false)
            setResetFormation(false)
            setTrainingSessions([]);
            setTrainingSessionsDates([]);
            setWish(prev => ({ ...prev, formation: [] }))
            setWish(prev => ({ ...prev, cepBranchType: [] }))
        }
    }, [forceSelectMonth]);

    useEffect(() => {
        if (selectedMonth !== null && selectedMonth !== undefined) {
            const currentYear = new Date().getFullYear();
            const currentDateYear = new Date().getFullYear();
            const year = selectedMonth < 8 && currentDateYear < parseInt(systemYear.code_saison) ? currentYear + 1 : currentYear;

            const daysSamedi = getSaturdaysOfMonth(year, selectedMonth);
            setSaturdays(daysSamedi);

            loadFormation(selectedBranch, selectedLevel, selectedMonth).then(data => {
                setTrainingSessions(data.formations);
                const datesEnd = [];
                setOpenDates(data.openDates);
                if (data.formations && data.formations.length) {
                    data.formations.forEach(d => datesEnd.push({ "start": d.date, "end": d.end_date }));
                }
                setTrainingSessionsDates(datesEnd);
            });
        }
    }, [selectedMonth]);

    useEffect(() => {
        if (selectedLevel) {
            const data = LevelOptions.find(option => option.key == selectedLevel);
            handleLoadFormationDate(data, selectedLevel)
        }
    }, [wish.specialty]);

    if (!openMonth) {
        return <LoaderWait />;
    }

    return (
        <Dialog open={onFormOpen} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '10px' }}>
                <DialogTitle id="form-dialog-title">
                    <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#1976d2' }} >
                        {t("TEXT_ADD_WISH")}
                    </Typography>
                </DialogTitle>

                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FunctionSelect selectedFunction={selectedFunction} changeFunction={changeFunction} />
                    <BranchSelect wish={wish} classes={classes} branchOptions={branchOptions} handleBranchChange={handleBranchChange} />
                    <LevelSelect wish={wish} classes={classes} handleLevelChange={handleLevelChange} />
                    <Speciality wish={wish}
                        canSelectDate={canSelectDate}
                        choiceCep={choiceCep}
                        handleSpecialtyChange={handleSpecialtyChange}
                        handleLevelChange={handleLevelChange}
                    />
                    <DateSelect wish={wish} classes={classes}
                        trainingSessionsDates={trainingSessionsDates}
                        openDates={openDates}
                        canSelectDate={canSelectDate}
                        choiceCep={choiceCep}
                        forceSelectMonth={forceSelectMonth}
                        selectedMonth={selectedMonth}
                        handleMonthChange={handleMonthChange}
                        openMonth={openMonth}
                        handleDateChange={handleDateChange}
                        saturdays={saturdays}
                        trainingSessions={trainingSessions}
                    />
                        <ChangeConfigForm
                        wish={wish}
                        specificFormation={specificFormation}
                        setSpecialtyOption={setSpecialtyOption}
                        trainingSessions={trainingSessions}
                        selectedFormations={Array.isArray(wish.formation) ? wish.formation : []}
                        handleFormationChange={handleFormationChange}
                    />
                </DialogContent>

                <DialogActions style={{ display: "flex", flexDirection: 'row', gap: '8px', justifyContent: 'center' }}>
                    {wish
                        && wish.date
                        && wish.branch
                        && wish.level
                        && (choiceCep == 0 || choiceCep == 1 && (wish.cepBranchType))
                        && (
                            <Button type="submit" className="azimuth-btn submit-form" startIcon={<AddIcon />}>
                                {t("BUTTON_AJOUTER_WHISH")}
                            </Button>
                        )
                    }
                    <Button onClick={onClose} color='error' startIcon={<CloseRounded />}>
                        {t("BUTTON_ANNULER")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddWishForm;
