import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid2 } from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken"
import DocumentCard from "../Core/DocumentCard";
import "./styles/Formation.css"
import useTexts from "../../hooks/useTexte";
import FolderIcon from '@mui/icons-material/FolderOpenRounded';

const FormationMangerItemDoc = ({ formation, handleDownloadDocument }) => {
    const fetchWithToken = useFetchWithToken();
    const [openDocumentsModal2, setOpenDocumentsModal2] = useState(false);
    const [documents, setDocuments] = useState([]);
    const { t } = useTexts();

    const handleOpenDocumentsModal = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/form/voeu/${formation.id}/docs`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setDocuments(data);
            setOpenDocumentsModal2(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };

    const handleCloseDocumentsModal2 = () => {
        setOpenDocumentsModal2(false);
    };
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'center', cursor: 'pointer' }} onClick={handleOpenDocumentsModal}>
                <FolderIcon />
                {t("DOC_STAGIAIRE")}
            </Box>

            <Dialog open={openDocumentsModal2} onClose={handleCloseDocumentsModal2} maxWidth="lg" fullWidth>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                    <Grid2 container spacing={2}>
                        {documents.map((doc) => (
                            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={doc.id}>
                                <DocumentCard document={doc}
                                    onDownload={() => handleDownloadDocument(doc.path)} />
                            </Grid2>
                        ))}
                    </Grid2>
                </DialogContent>
            </Dialog>

        </>

    )

};

export default FormationMangerItemDoc;
