import React, { useState} from 'react';
import {Typography, Button,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import './styles/StagiaireCard.css';


const StagiareCardFichePdf = ({user}) => {
    const fetchWithToken = useFetchWithToken();
    const [loadingPdf, setLoadingPdf] = useState(false);

    const handleExportPDF = async () => {
        setLoadingPdf(true);
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_user_id}/${user.formation_id}/answer?format=pdf`;
            const response = await fetchWithToken(apiUrl, "POST", {}, false, "RESPONSE");

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            const blob = await response.blob();
            const contentDisposition = response.headers.get('Content-Disposition');

            let fileName = 'export.pdf'; // Default name
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^";]+)"?/);
                if (fileNameMatch && fileNameMatch[1]) {
                    fileName = fileNameMatch[1];
                }
            }

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            setLoadingPdf(false);
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
            setLoadingPdf(false);
        }
    };

    return (
        <>
            {loadingPdf ? (
                <Typography variant="body1" color="textSecondary">
                    Export en cours
                </Typography>
            ) : (
                <Button variant="outlined" color="primary" onClick={handleExportPDF}>
                    Exporter en PDF
                </Button>
            )}


        </>
    );

};
export default StagiareCardFichePdf;
