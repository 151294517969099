import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React from "react";
import PreviewCheckbox from "./types/PreviewCheckbox";
import PreviewRadio from "./types/PreviewRadio";
import PreviewSelect from "./types/PreviewSelect";
import PreviewCompetences from "./types/PreviewCompetences";

const PreviewModalItem = ({questionIndex, question}) => {

    return (
        <Box key={questionIndex} className="question-preview" sx={{marginBottom: 3}}>
            <Typography variant="subtitle1" sx={{fontWeight: 500, marginBottom: 1}}>
                {question.label || `Question ${questionIndex + 1}`}
            </Typography>

            {question.type === 'text' && (
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Réponse courte"
                    sx={{marginBottom: 2}}
                />
            )}

            {question.type === 'textarea' && (
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Réponse longue"
                    sx={{marginBottom: 2}}
                />
            )}

            {(question.type === 'radio') && (
                <PreviewRadio question={question}/>
            )}

            {question.type === 'checkbox' && (
                <PreviewCheckbox question={question}/>
            )}

            {question.type === 'select' && (
                <PreviewSelect question={question} />
            )}

            {question.type === 'competences' && question.competences && (
               <PreviewCompetences question={question} />
            )}
        </Box>
    );
};

export default PreviewModalItem;
