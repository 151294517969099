
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


const DefaultType = ({question, sectionIndex, questionIndex, updateOption, moveUpOption, moveDownOption, removeOption, addOption}) => {


    return (
        <>
            {question.options?.map((option, optionIndex) => (
                <TextField
                    key={optionIndex}
                    label={`Option ${optionIndex + 1}`}
                    fullWidth
                    variant='standard'
                    value={option || ''}
                    onChange={(e) =>
                        updateOption(sectionIndex, questionIndex, optionIndex, e.target.value)
                    }
                    InputProps={{
                        endAdornment: (
                            <div className='input-endIcons'>
                                <IconButton
                                    aria-label="monter l'option"
                                    onClick={() => moveUpOption(sectionIndex, questionIndex, optionIndex)}
                                >
                                    <ArrowUpwardIcon/>
                                </IconButton>
                                <IconButton
                                    aria-label="descendre l'option"
                                    onClick={() => moveDownOption(sectionIndex, questionIndex, optionIndex)}
                                >
                                    <ArrowDownwardIcon/>
                                </IconButton>
                                <IconButton
                                    onClick={() => removeOption(sectionIndex, questionIndex, optionIndex)}
                                    aria-label="supprimer l'option"
                                >
                                    <ClearIcon/>
                                </IconButton>
                            </div>
                        ),
                    }}
                    sx={{marginBottom: 1}}
                />
            ))}
            <Button
                startIcon={<AddCircleIcon/>}
                onClick={() => addOption(sectionIndex, questionIndex)}
            >
                Ajouter une option
            </Button>
        </>
    )

}
export default DefaultType