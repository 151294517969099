import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,  InputLabel, MenuItem,  Select,} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import ListIcon from "@mui/icons-material/List";


const FormationMangerAdminSelectQuestionaire = ({formation, isAdministrator, questionnaires}) => {
    const fetchWithToken = useFetchWithToken();
    const [selectedFormationId, setSelectedFormationId] = useState(null);
    const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');

    const handleSelectQuestionnaire = async (formationId, questionnaireId) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formationId}/assign-questionnaire`;
        await fetchWithToken(apiUrl, 'POST', {questionnaireId});
        handleCloseQuestionnaireModal();
    };
    const handleChangeQuestionnaire = (event) => {
        setSelectedQuestionnaire(event.target.value);
    };

    const handleOpenQuestionnaireModal = (id) => {
        setSelectedFormationId(id);
        setOpenQuestionnaireModal(true);
    };

    const handleCloseQuestionnaireModal = () => {
        setOpenQuestionnaireModal(false);
        setSelectedFormationId(null);
    };


    const {t} = useTexts();


    return isAdministrator && formation.close == 0 && (
        <>
            <Button
                className='azimuth-btn full-width'
                variant="contained"
                onClick={() => handleOpenQuestionnaireModal(formation.id)}
                startIcon={<ListIcon />}
            >
                {t('BUTTON_CHOOSE_FORM')}
            </Button>

            <Dialog open={openQuestionnaireModal} onClose={handleCloseQuestionnaireModal}>
                <DialogTitle>{t("TITLE_SELECT_QUESTIONNAIRE")}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel id='label-label-questionnaire'>{t("LABEL_QUESTIONNAIRE")}</InputLabel>
                        <Select
                            labelId='label-label-questionnaire'
                            label={t("LABEL_QUESTIONNAIRE")}
                            value={selectedQuestionnaire}
                            onChange={handleChangeQuestionnaire}
                            displayEmpty={false}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.id} value={questionnaire.id}>
                                    {questionnaire.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseQuestionnaireModal} color="primary">
                        {t("BUTTON_CANCEL")}
                    </Button>
                    <Button onClick={() => handleSelectQuestionnaire(selectedFormationId, selectedQuestionnaire)}
                            color="primary">
                        {t("BUTTON_SELECT")}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default FormationMangerAdminSelectQuestionaire;
