import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import React from "react";
import { LevelOptions } from "../../Utils/LevelOption";
import { branchOptions } from "../../Utils/BranchOption";
import WishDate from "./WishDate";
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const WishHeader = ({ wish, type, onDelete, setOpenChangeFunction, isRequireValidate }) => {
    const level = LevelOptions.find((branch) => wish.level === branch.key);
    const branch = branchOptions.find((branch) => wish.branch === branch.key);
    const isMobile = useMediaQuery('(max-width:768px)');
    const [openMore, setOpenMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <Box className='wish-card-header'>
            <Box className='wish-card-header-infos' display={'flex'} gap={2} width={'100%'}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='wish-card-header-infos-formation'>
                        <img src={branch.logo} alt="Logo Branche" style={{ width: '50px', height: '50px' }} />
                        <Typography id='formation-name' variant='h6'>{level.label} {branch.label}</Typography>
                    </div>
                    {isMobile && (
                        <div className='wish-card-header-actions'>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMore}
                                onClose={() => setOpenMore(false)}
                                onClick={() => setOpenMore(false)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    p: 0,
                                    '& .MuiMenuItem-root': {
                                        padding: 0,
                                        margin: 0,
                                        minHeight: '40px',
                                    },
                                    '& .MuiMenu-list': {
                                        padding: 0,
                                        margin: 0,
                                    },
                                    '& .MuiPaper-root': {
                                        padding: 0,
                                        margin: 0,
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                {type !== "delete" && wish.close == 0 && (
                                    <>
                                        <MenuItem onClick={() => onDelete(wish.voeu_id)}>
                                            {/* <IconButton className='icon-button-delete'>
                                                <DeleteIcon />
                                            </IconButton> */}
                                            <Typography variant='body2' color='error' sx={{ padding: '10px 20px', width: '100%' }}>
                                                Supprimer le voeu
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => setOpenChangeFunction(true)}>
                                            {/* <IconButton>
                                                <Edit />
                                            </IconButton> */}
                                            <Typography variant='body2' color='primary' sx={{ padding: '10px 20px', width: '100%' }}>
                                                Modifier le voeu
                                            </Typography>
                                        </MenuItem>
                                    </>
                                )}
                            </Menu>
                            <IconButton
                                className='icon-button-more'
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setOpenMore(!openMore);
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </div>
                    )}
                </Box>
                <WishDate wish={wish} />
            </Box>
            {type !== "delete" && wish.close == 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1}}>
                    {!isMobile && (
                        <>
                            {!isRequireValidate && (
                                <IconButton onClick={() => setOpenChangeFunction(true)}>
                                    <Edit />
                                </IconButton>
                            )}
                            <IconButton
                                className='icon-button-delete'
                                onClick={() => onDelete(wish.voeu_id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default WishHeader
