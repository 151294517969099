import React, { useState } from "react";

/* Material UI Components */
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

/* Custom Components */
import useTexts from "../../hooks/useTexte";
import { calculateEndDate, formatDate } from "../../Utils/functionTransforms";
import { LevelOptions } from "../../Utils/LevelOption";
import { branchOptions } from "../../Utils/BranchOption";
import { useSystem } from "../../Context/System";

const ValidateWish = ({ openValidation, setOpenValidation, wishValidation, wish, onValidate }) => {
    const { userData } = useSystem();
    const { t } = useTexts();

    const [checkboxes, setCheckboxes] = useState({
        validationWarning: false,
        nomination: false,
        stNicolas: (userData?.formation_saint_nicolas ? true : false),
    });

    const handleCheckboxChange = (key) => {
        setCheckboxes({
            ...checkboxes,
            [key]: !checkboxes[key],
        });
    };

    const isAllChecked = Object.values(checkboxes).every((value) => value);

    const level = LevelOptions.find((branch) => wish.level === branch.key);
    const branch = branchOptions.find((branch) => wish.branch === branch.key);

    return (
        <Dialog open={openValidation} onClose={() => setOpenValidation(false)} maxWidth="md">
            <DialogTitle>{t("TEXT_VALIDER_PLACE_FORMATION")}</DialogTitle>
            <DialogContent>
                <Typography>{t("TEXT_YOU_HAVE_TAKE_TO_FORMATION")}</Typography>
                <ul>
                    <li><Typography variant="body2">{t("TEXT_COL_BRANCHE")} : {wishValidation.branch}</Typography></li>
                    <li><Typography variant="body2">{t("TEXT_COL_TYPE")} : {wishValidation.level}</Typography></li>
                    <li><Typography variant="body2">{t("TEXT_COL_DATE")} : {formatDate(wishValidation.date)} au {calculateEndDate(wishValidation.date, branch.key, level.key)}</Typography></li>
                </ul>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ marginBottom: 2, display: "flex", alignItems:'flex-start' }}>
                    {/* Cases à cocher */}
                    <FormControlLabel
                        sx={{ display: 'flex', alignItems: 'flex-start' }}
                        control={
                            <Checkbox
                                checked={checkboxes.validationWarning}
                                onChange={() => handleCheckboxChange("validationWarning")}
                            />
                        }
                        label={
                            <Typography sx={{ pt: 1, textAlign: { xs: 'left', sm: 'justify' } }}>
                                {t("TEXT_WARNING_VALIDATION")}
                            </Typography>
                        }
                    />
                </Box>
                <Box sx={{ marginBottom: 2, display: "flex", alignItems:'flex-start' }}>
                    <FormControlLabel
                        sx={{ display: 'flex', alignItems: 'flex-start' }}
                        control={
                            <Checkbox
                                checked={checkboxes.nomination}
                                onChange={() => handleCheckboxChange("nomination")}
                            />
                        }
                        label={
                            <Typography sx={{ pt: 1, textAlign: { xs: 'left', sm: 'justify' } }}>
                                {t("TEXT_NONIMNATION")}
                            </Typography>
                        }
                    />
                </Box>

                {(!userData || userData.formation_saint_nicolas != 1) && (
                    <Box sx={{ marginBottom: 2, display: "flex", alignItems:'flex-start' }}>
                        <FormControlLabel
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                            control={
                                <Checkbox
                                    checked={checkboxes.stNicolas}
                                    onChange={() => handleCheckboxChange("stNicolas")}
                                />
                            }
                            label={
                                <Typography sx={{ pt: 1, textAlign: { xs: 'left', sm: 'justify' } }}>
                                    {t("TEXT_ST_NICOLAS")}
                                </Typography>
                            }
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <button className='azimuth-btn error' onClick={() => setOpenValidation(false)}>
                    {t("BUTTON_ANNULER")}
                </button>
                <button
                    className={`azimuth-btn ${isAllChecked ? "success" : ""}`}
                    disabled={!isAllChecked}
                    onClick={() => onValidate(setOpenValidation)}
                >
                    {t("BUTTON_VALIDER")}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default ValidateWish;
