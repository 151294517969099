import {Button, Dialog, DialogActions, DialogTitle,} from "@mui/material";
import React, {useState} from "react";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import TestingDateSelector from "./TestingDateSelector";

const TestingDate = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const [trainingDates, setTrainingDates] = useState([{ date: '', persons: 0 }]);
    const fetchWithToken = useFetchWithToken();

    const testOpeningDates = async () => {
        try {

            const body = {
                dates:trainingDates,
                selectedBranch,
                selectedLevel

            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/testDate`;
            const blob = await fetchWithToken(apiUrl, "POST", body, false, "BLOB");
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'simulation.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors du chargement des formations:', error);
        }
    };



    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    return (
        <>

            <Button className="azimuth-btn" onClick={handleOpenModal} sx={{m: 2, width: 'fit-content'}}>
                Tester Jour de Formation
            </Button>

            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                <DialogTitle>Tester Jour de Formation</DialogTitle>
                <TestingDateSelector trainingDates={trainingDates} setTrainingDates={setTrainingDates} selectedBranch={selectedBranch} selectedLevel={selectedLevel}
                setSelectedLevel={setSelectedLevel}
                setSelectedBranch={setSelectedBranch}
                />
                <DialogActions>
                    <Button onClick={handleCloseModal}>Annuler</Button>
                    <Button onClick={() => {
                        testOpeningDates()
                    }}>Ajouter</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default TestingDate