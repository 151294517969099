import React, { useEffect, useState } from 'react';
import { Container, Grid2, Typography, Box, Divider, } from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { useAuth } from "../Context/AuthContext";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import UserListPageInfo from "../components/UserListPageInfo";
import UserListFunction from "../components/UserListFunction";
import UserListFormation from "../components/UserListFormation";
import UserListCamp from "../components/UserListCamp";
import UserListSelector from "../components/UserListSelector";
import LoaderWait from "../components/LoaderWait";
import UserListDocument from "../components/UserListDocument";
import { useMediaQuery } from '@mui/system';

const UserListPage = ({ codeSycomoreData }) => {
    const [selectedPersonne, setSelectedPersonne] = useState(false);
    const [functions, setFunctions] = useState([]);
    const [formations, setFormations] = useState([]);
    const [camps, setCamps] = useState([]);
    const [birthday, setBirthday] = useState("");
    const [email, setEmail] = useState("");
    const [codeSycomore, setCodeSycomore] = useState("");
    const [isFunctionsEmpty, setIsFunctionsEmpty] = useState(false); // Pour gérer l'état de fonctions vides
    const fetchWithToken = useFetchWithToken();
    const { isLoadingFunction } = useAuth();
    const [documents, setDocuments] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const isMobile = useMediaQuery('(max-width: 600px)');

    const loadUserFunction = async () => {
        try {
            setIsLoad(false)

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/user`;
            const body = {
                user: (selectedPersonne ? selectedPersonne.id : null),
                codeSycomoreData: codeSycomoreData,
                page: 1
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setFunctions(data.functions);
            setFormations(data.formation);
            setCamps(data.camps);
            setCodeSycomore(data.user.code_sycomore);
            setEmail(data.user.email);
            setDocuments(data.documents);
            setBirthday(data.user.birthday);
            setCodeSycomore(data.functions[0].code_sycomore); // Récupérer l'email à partir de la première fonction
            if (data.functions && data.functions.length > 0) {
                setIsFunctionsEmpty(false); // Si des fonctions sont trouvées, fonctions non vides
            } else {
                setIsFunctionsEmpty(true); // Si la liste est vide, définir à true
            }
            setIsLoad(true)
        } catch (error) {
            setFunctions([]);
            setFormations([]);
            setIsLoad(false)

            setCamps([]);
            setCodeSycomore("");
            setEmail("");
            setBirthday("");
            setCodeSycomore(""); // Récupérer l'email à partir de la première fonction
            console.error('Erreur lors du chargement des fonctions:', error);
        }
    };

    useEffect(() => {
        if (selectedPersonne || codeSycomoreData) {
            loadUserFunction();
        } else {
            setIsFunctionsEmpty(false);
            setEmail("");

        }
    }, [selectedPersonne, codeSycomoreData]);

    if (!isLoadingFunction) {
        return (<LoaderWait />);
    }

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 2 }}>Voir un utilisateur</Typography>
            <UserListSelector
                codeSycomoreData={codeSycomoreData}
                selectedPersonne={selectedPersonne}
                setSelectedPersonne={setSelectedPersonne}
            />
            {isLoad === true && (
                <Grid2 xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <UserListPageInfo
                            birthday={birthday}
                            selectedPersonne={selectedPersonne}
                            codeSycomore={codeSycomore}
                            email={email}
                            isFunctionsEmpty={isFunctionsEmpty}
                        />
                        <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
                        <UserListDocument documents={documents} isOwner={0} setDocuments={setDocuments} />
                    </Box>
                    <UserListFunction functions={functions} loadUserFunction={loadUserFunction} />
                    <UserListFormation formations={formations} />
                    <UserListCamp camps={camps} />
                </Grid2>
            )}
        </Container>
    )
}

export default withAdminOrFormationRole(UserListPage);