import {Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchModal from "../Search/SearchModal";
import React, {useState} from "react";
import useTexts from "../../hooks/useTexte";


const FormationFormMestre = ({setWish, wish}) => {

    const {t} = useTexts()
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenSearchDialog = () => {
        setDialogOpen(true);
    };
    const handleRemoveMestre = (index) => {
        const updatedMestre = wish.mestre.filter((_, i) => i !== index);
        setWish({...wish, mestre: updatedMestre});
    };

    const handleSelectCampMaster = (chef) => {
        if (chef) {
            const {code_sycomore, firstname, lastname, id} = chef;
            const newMestre = {user_id: id, code_sycomore, firstname, lastname};

            setWish(prevWish => ({
                ...prevWish,  // Préserve toutes les autres valeurs de l'état
                mestre: [...(prevWish.mestre || []), newMestre]  // Ajoute le nouveau mestre à la liste
            }));
        }
        setDialogOpen(false);  // Ferme la boîte de dialogue après sélection
    };

    return (
        <Grid item xs={12} md={6}>
            <Button variant="contained" onClick={handleOpenSearchDialog}>
                Rechercher un Mestre
            </Button>
            {wish.mestre && wish.mestre.length > 0 ? (
                <List>
                    {wish.mestre.map((m, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={`${m.code_sycomore} ${m.firstname} - ${m.lastname}`}
                            />
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMestre(index)}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <p>Aucun mestre ajouté pour l'instant.</p>
            )}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="search-dialog-title">

                <DialogTitle id="search-dialog-title">{t("FIELD_MESTRE_CAMP")}</DialogTitle>
                <DialogContent>
                    <SearchModal onClick={handleSelectCampMaster}/>
                </DialogContent>
            </Dialog>

        </Grid>
    );
};

export default FormationFormMestre;
