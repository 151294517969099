import {Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";

const TextEditorModal = ({isDialogOpen , handleDialogClose, handleDeleteText,dialogType,setEditText,editText, handleEditSave  }) => {
    const { t } = useTexts();

    return (

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            {dialogType === 'delete' ? (
                <>
                    <DialogTitle>Supprimer le texte</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Êtes-vous sûr de vouloir supprimer ce texte ?
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDialogClose}
                        >
                            {t("BUTTON_CANCEL")}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDeleteText}
                        >
                            {t("BUTTON_DELETE")}
                        </Button>
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle>Modifiez le label et le texte</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="KEY_LABEL"
                            fullWidth
                            value={editText.key}
                            onChange={e => setEditText({ ...editText, key: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="Texte"
                            fullWidth
                            value={editText.text}
                            onChange={e => setEditText({ ...editText, text: e.target.value })}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEditSave}
                        >
                            {t("BUTTON_SAVE")}
                        </Button>
                    </DialogContent>
                </>
            )}
        </Dialog>

    )
}

export default TextEditorModal