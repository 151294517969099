import {TextField} from "@mui/material";
import ReactQuill from "react-quill";
import React from "react";

const EmailEditorInputs = ({selectedTemplate, handleTemplateChange, setSelectedTemplate, setFocusedField, quillRef}) =>
{
    const handleQuillChange = (content, delta, source, editor) => {
        setSelectedTemplate((prevTemplate) => ({
            ...prevTemplate,
            template: editor.getHTML(),
        }));
    };

    return (
        <>
            <TextField
                id="subject-field"
                label="Objet"
                name="subject"
                value={selectedTemplate?.subject}
                onChange={handleTemplateChange}
                onFocus={() => setFocusedField('subject')}
                fullWidth
                margin="normal"
                variant="outlined"
            />
            <TextField
                id="description-field"
                label="Description"
                name="description"
                value={selectedTemplate?.description}
                onChange={handleTemplateChange}
                onFocus={() => setFocusedField('description')}
                fullWidth
                margin="normal"
                variant="outlined"
            />
            <ReactQuill
                ref={quillRef}
                value={selectedTemplate?.template}
                onChange={handleQuillChange}
                onFocus={() => setFocusedField('template')}
                style={{height: '400px', marginBottom: '50px'}}
                modules={{
                    toolbar: [
                        [{'header': '1'}, {'header': '2'}, {'font': []}],
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'},
                            {'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image'],
                        ['clean'],
                        [{'insertVar': 'variable'}]
                    ]
                }}
            />
        </>
    )
}

export default EmailEditorInputs;
