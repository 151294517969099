import { Route } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import PrivateRoute from "../components/Core/PrivateRoute";
import React from "react";
import HomePage from "../Page/HomePage";
import WishListPage from "../Page/WishListPage";
import FormationListPage from "../Page/FormationListPage";
import FormationManagerListPage from "../Page/FormationManagerListPage";
import QuestionairePostFormation from "../components/Fiche/QuestionairePostFormation";
import Maitrise from "../Page/Maitrise";
import QuestinaireSectionPage from "../Page/QuestionaireSectionPage";
import Forms from "../Page/Forms";
import CustomFormBuilder from "../components/customFormBuilder/CustomFormBuilder";
import VoeuxListPage from "../Page/VoeuxListPage";
import MyForms from "../Page/MyForms";
import ErrorPage from "../Page/ErrorPage";
import MafichePage from "../Page/MaFichePage";
import FormationProvinceListPage from "../Page/FormationProvinceListPage";

const DefaultRoutes = [
    <Route
        path="/"
        element={<DefaultLayout><PrivateRoute><HomePage /></PrivateRoute></DefaultLayout>}
        key="home"
    />,
    <Route
        path="/voir-ceps"
        element={<DefaultLayout><PrivateRoute><FormationProvinceListPage /></PrivateRoute></DefaultLayout>}
        key="voir-ceps"
    />,
    <Route
        path="/ma-fiche"
        element={<DefaultLayout><PrivateRoute><MafichePage /></PrivateRoute></DefaultLayout>}
        key="ma-fiche"
    />,
    <Route
        path="/voeux"
        element={<DefaultLayout><PrivateRoute><WishListPage /></PrivateRoute></DefaultLayout>}
        key="voeux"
    />,
    <Route
        path="/formations"
        element={<DefaultLayout><PrivateRoute><FormationListPage /></PrivateRoute></DefaultLayout>}
        key="formations"
    />,
    <Route
        path="/gerer-mes-ceps"
        element={<DefaultLayout><PrivateRoute><FormationManagerListPage /></PrivateRoute></DefaultLayout>}
        key="gerer-mes-ceps"
    />,
    <Route
        path="/avis-formation/:link/:user_id/:formation_id"
        element={<QuestionairePostFormation />}
        key="avis-formation"
    />,
    <Route
        path="/mes-maitrises"
        element={<DefaultLayout><PrivateRoute><Maitrise /></PrivateRoute></DefaultLayout>}
        key="mes-maitrises"
    />,
    <Route
        path="/mes-maitrises/:id"
        element={<DefaultLayout><PrivateRoute><Maitrise /></PrivateRoute></DefaultLayout>}
        key="mes-maitrises-id"
    />,
    <Route
        path="/questionaireeval"
        element={<DefaultLayout><PrivateRoute><QuestinaireSectionPage /></PrivateRoute></DefaultLayout>}
        key="questionaireeval"
    />,
    <Route
        path="/forms"
        element={<DefaultLayout><PrivateRoute><Forms /></PrivateRoute></DefaultLayout>}
        key="forms"
    />,
    <Route
        path="/creer-template"
        element={<DefaultLayout><PrivateRoute><CustomFormBuilder /></PrivateRoute></DefaultLayout>}
        key="creer-template"
    />,
    <Route
        path="/creer-template/:id"
        element={<DefaultLayout><PrivateRoute><CustomFormBuilder /></PrivateRoute></DefaultLayout>}
        key="creer-template-id"
    />,
    <Route
        path="/voir-voeux"
        element={<DefaultLayout><PrivateRoute><VoeuxListPage /></PrivateRoute></DefaultLayout>}
        key="voir-voeux"
    >
        <Route path=":id" element={<VoeuxListPage />} />
    </Route>,
    <Route
        path="/template"
        element={<DefaultLayout><PrivateRoute><Forms /></PrivateRoute></DefaultLayout>}
        key="template"
    />,
    <Route
        path="/my-template"
        element={<DefaultLayout><PrivateRoute><MyForms /></PrivateRoute></DefaultLayout>}
        key="my-template"
    />,
    <Route
        path="/error"
        element={<DefaultLayout><PrivateRoute><ErrorPage /></PrivateRoute></DefaultLayout>}
        key="error"
    />
];

export default DefaultRoutes;
