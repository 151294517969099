/**
 * Update the function of a wish
 * @param wishId - wish.id
 * @param saveFunction - new function to save
 * @param fetchWithToken - function to fetch with token
 * @returns {Promise<{success: boolean, message: string}>}
*/

const updateWishFunction = async (wishId, saveFunction, fetchWithToken) => {
    try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wishId}/updateFunction/${saveFunction}`;
        await fetchWithToken(apiUrl, "POST");
        return { success: true, message: "Wish function updated successfully" };
    } catch (error) {
        console.error("Error while updating wish function:", error);
        return { success: false, message: `Error while updating wish function: ${error.message}` };
    }
};

export default updateWishFunction;