import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import DescriptionIcon from "@mui/icons-material/Description";
import './styles/DocumentCard.css';

const DocumentCard = ({ document, onDelete, onDownload }) => {

    // Converti les accents: Ã©claireuses
    function documentNameAccent(nom) {
        let newNom = nom;
        let accent = {
            "Ã©": "é",
        };
        for (let key in accent) {
            newNom = newNom.replace(key, accent[key]);
        }
        return newNom;
    }

    return (
        <>
            <div id='document-infos'>
                <DescriptionIcon color="primary" fontSize="large" />
                <Typography variant="body1" textOverflow={"ellipsis"} noWrap>
                    {documentNameAccent(document.nom)}
                </Typography>
            </div>
            <div id='document-actions'>
                <IconButton onClick={onDownload}><DownloadIcon /></IconButton>
                {onDelete && <IconButton onClick={onDelete}><DeleteIcon color="error" /></IconButton>}
            </div>
        </>
    );
};

export default DocumentCard;
