import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import scoutImage from '../assets/scoutetonne.jpg'; // Remplacez par le chemin correct vers l'image d'un scout
import logoAzimuth from '../assets/logo/AZIMUTH.svg';

const ErrorConnexion = () => {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 3 }}>
                    <img
                        src={scoutImage}
                        alt="Scout d'Europe"
                        style={{ width: '100%', maxWidth: '200px' }}
                    />
                    <img
                        src={logoAzimuth}
                        alt="Scout d'Europe 2"
                        style={{ width: '100%', maxWidth: '200px' }}
                    />
                </Box>
                <Typography variant="h4" gutterBottom>
                    Azimuth revient de suite
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                    Merci de patienter pendant que nous tentons de résoudre ce problème.
                </Typography>
            </Box>
        </Container>
    );
};

export default ErrorConnexion;
