import Person2Icon from "@mui/icons-material/Person2Rounded";
import React from "react";

const AvatarPlaceholder = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
        }}>
            <div style={{
                height: '56px',
                width: '56px',
                backgroundColor: 'lightgray',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Person2Icon className='avatar-placeholder-icon' style={{fontSize:"48px", color: 'whitesmoke' }} />
            </div>
        </div>
    );
};
export  default AvatarPlaceholder;