import {Divider, Typography} from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";
import {GroupsRounded} from "@mui/icons-material";
import {transformFunctionOption} from "../../Utils/functionTransforms";
import {useAuth} from "../../Context/AuthContext";


const ChangeFunctionWish = ({ chefFunction, close}) => {
    const {t} = useTexts();
    const { functionOptions} = useAuth();

    return (
        <>
            <Divider/>
            {(close === 0 && !chefFunction || functionOptions.length > 1)  && (
                <div
                    className="wish-content-function"
                    style={{flex: "1 1 20%", marginBottom: {xs: 2, sm: 0}}}
                >
                    <div id="wish-content-item-left">
                        <GroupsRounded/>
                        {chefFunction ? (
                            <Typography variant='body2' sx={{ ml: 1}}>{transformFunctionOption(chefFunction)}</Typography>
                        ) : (
                            <div>{t("INVALIDE_FUNCTION_NEED_ACTION")}</div>
                        )}
                    </div>

                </div>
            )}
        </>
    );
};

export default ChangeFunctionWish;
