import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import { useAuth } from '../Context/AuthContext';
import StructureItem from '../components/Structure/StructureItem';
import './styles/Maitrise.css';

function Maitrise() {
    const { id } = useParams();
    const navigate = useNavigate();
    const fetchWithToken = useFetchWithToken();
    const location = useLocation();
    const { currentFunction } = useAuth();
    const [units, setUnits] = useState([]);
    const [unitsFilter, setunitsFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentUrl, setCurrentUrl] = useState(null);
    const [initialId, setInitialId] = useState(null);

    useEffect(() => {
        setCurrentUrl(location.pathname);
    }, [location]);

    const customSort = (a, b) => {
        const order = ['Province', 'District', 'Groupe'];

        const indexA = order.indexOf(a.type);
        const indexB = order.indexOf(b.type);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }

        if (indexA !== -1) {
            return -1;
        }

        if (indexB !== -1) {
            return 1;
        }

        if (a.type < b.type) {
            return -1;
        }
        if (a.type > b.type) {
            return 1;
        }
        return 0;
    };

    async function loadUnits() {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/chilsstruct/`;
        const data = await fetchWithToken(apiUrl);
        setUnits(data);

        let selectedId = id;

        if (!id) {
            const firstUnit = data.find(u => u.diff === 0);
            if (firstUnit) {
                setInitialId(firstUnit.id);
                navigate(`/mes-maitrises/${firstUnit.id}`);
            }
        }

        if (selectedId) {
            const specificUnit = data.find(u => u.id.toString() === selectedId);
            if (specificUnit) {
                const filterData = data.filter(u => u.parent_id === specificUnit.id || u.id === specificUnit.id);
                filterData?.sort((a, b) => (b.diff === 0) - (a.diff === 0));
                setunitsFilter(filterData);
            }
        } else {
            const filterData = data.filter(u => u.diff === 1 || u.diff === 0);
            filterData?.sort((a, b) => (b.diff === 0) - (a.diff === 0));
            setunitsFilter(filterData);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadUnits();
    }, [currentFunction, id]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    const filteredUnitsName = unitsFilter?.filter(unit => !unit.nom.toUpperCase().startsWith('DEPARTEMENT'));
    const sortedUnitsFilter = filteredUnitsName?.sort(customSort);

    return (
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                <button
                    onClick={() => navigate(`/mes-maitrises/${initialId}`)}
                    className='azimuth-btn'
                    disabled={
                        initialId === null ||
                        currentUrl === `/mes-maitrises/${initialId}`
                    }
                >
                    Réinitialiser la navigation
                </button>
            </div>

            <div className='units-container'>
                {sortedUnitsFilter?.map((unit) => (
                    <StructureItem structure={unit} list={units} size={sortedUnitsFilter.length} key={unit.id} />
                ))}
            </div>
        </Box>
    );
}

export default Maitrise;
