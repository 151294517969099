import React from 'react';
import {Button,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken"
import QuestionnaireIcon from "@mui/icons-material/ListAlt";
import useTexts from "../../hooks/useTexte";

const FormationMangerItemOpenQuestionaire = ({formation, callBack}) => {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();

    const handleOpenQuestionaire = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/openfiche`;
        try {
            await fetchWithToken(apiUrlDocuments);
            callBack();
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };

    return (
        <>
            {formation.open_questionaire == 0 && (
                <Button
                    className='azimuth-btn full-width'
                    variant="contained"
                    onClick={() => handleOpenQuestionaire()}
                    startIcon={<QuestionnaireIcon />}
                >
                    {t("QUESTIONAIRE_STAGIAIRE_OPEN")}
                </Button>
            )}
        </>
    );
};

export default FormationMangerItemOpenQuestionaire;
