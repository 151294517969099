import React, {useState, useEffect} from 'react';
import {Button, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Grid} from '@mui/material';
import {useSystem} from '../../Context/System';
import useTexts from "../../hooks/useTexte";
import FormationFormMestre from "./FormationFormMestre";
import FormationFormSimpleFields from "./FormationFormSimpleFields";

const FormationForm = ({open, onClose, onAdd, initialData}) => {
    const {provinces} = useSystem();
    const {t} = useTexts()

    const [wish, setWish] = useState({
        capacity: 0,
        reserve_capacity: 0,
        open_capacity: 0,
        mestre: [],
        provinces: []
    });

    useEffect(() => {
        if (initialData) {
            setWish({
                nom: initialData.nom,
                capacity: initialData.capacity || 0,
                reserve_capacity: initialData.reserve_capacity || 0,
                open_capacity: initialData.open_capacity || 0,
                mestre: initialData.mestre || [],
                provinces: initialData.provinces || [],
                id: initialData.id || null,
                mestreData: initialData.mestreData || []
            });

        }
    }, [initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const totalCapacity = parseInt(wish.capacity, 10);
        const reservedCapacity = parseInt(wish.reserve_capacity, 10);
        const openCapacity = parseInt(wish.open_capacity, 10);

        if (reservedCapacity + openCapacity > totalCapacity) {
            alert("Le nombre total de places (réservées + ouvertes) ne peut pas dépasser le nombre de places totales.");
            return;
        }
        onAdd(wish);
        onClose();
    };
    if (!provinces) {
        return <>Loading</>;
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <DialogTitle
                    id="form-dialog-title">{initialData && initialData.id ? "Modifier la Formation" : "Ajouter une Formation"} {wish.nom}</DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <FormationFormMestre wish={wish} setWish={setWish}/>

                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={provinces}
                                getOptionLabel={(option) => option.id_sycomore + " " + option.nom}
                                renderInput={(params) => <TextField {...params} label="Provinces" variant="outlined"
                                                                    fullWidth/>}
                                value={provinces.filter(province => wish.provinces.includes(province.id)) || []}
                                onChange={(event, newValue) => {
                                    setWish({...wish, provinces: newValue.map(option => option.id)});
                                }}
                            />
                        </Grid>
                        <FormationFormSimpleFields wish={wish} setWish={setWish}/>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">{t("BUTTON_ANNULER")}</Button>
                    <Button type="submit"
                            color="primary">{initialData && initialData.id ? t("BUTTON_EDIT_FORMATION") : t("BUTTON_ADD_FORMATION")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FormationForm;
