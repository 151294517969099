import React from 'react';
import {TableCell, TableHead, TableRow,} from '@mui/material';


const FormationAdminListUserTableHead = () => {


    return (
        <TableHead>
            <TableRow>
                <TableCell>Code Sycomore</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Structure</TableCell>
                <TableCell>Fonction</TableCell>
                <TableCell>Date de fin de validité</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Actions</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default FormationAdminListUserTableHead;
