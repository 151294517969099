import { UAParser } from "ua-parser-js";

export function getUserAgent() {
    const parser = new UAParser();
    const userAgent = parser.getResult();

    const browser = userAgent.browser;
    const cpu = userAgent.cpu;
    const device = userAgent.device;
    const engine = userAgent.engine;
    const os = userAgent.os;
    const ua = userAgent.ua;

    return { browser, cpu, device, engine, os, ua };
}
