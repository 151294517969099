import { TableBody } from "@mui/material";
import React from "react";
import StagiareCardAdmin from "./StagiareCardAdmin";

const FormationMangerAdminListUserWaitTableBody = ({ usersWait, wish, filters, isAdministrator, handleListUserWait }) => {
    const applyFilters = (user) => {
        const userStatus = user.status || 'WAIT';

        // Check if user status matches any of the selected statuses
        const isStatusSelected = Object.values(filters.status).some(value => value);
        const statusMatch = isStatusSelected ? Object.keys(filters.status).some(status => filters.status[status] && status === userStatus) : true;

        // Check if code Sycomore matches
        const codeSycomoreMatch = filters.codeSycomore ? user.user_code_sycomore.includes(filters.codeSycomore) : true;
        const hideMtach = filters.hideInWait ? user.other_inscription_wait_validation == "No" : true;
        return statusMatch && codeSycomoreMatch && hideMtach;
    };

    return (
        <TableBody>
            {usersWait && usersWait.filter(applyFilters).map((user, index) => (
                <StagiareCardAdmin
                    key={index}
                    user={user}
                    index={index}
                    isAdministrator={isAdministrator}
                    refreshUser={handleListUserWait}
                    formation={wish}
                    displayDateStatus={false}
                />
            ))}
        </TableBody>
    );
};

export default FormationMangerAdminListUserWaitTableBody;
