import {Grid, TextField} from "@mui/material";
import React from "react";
import useTexts from "../../hooks/useTexte";

const FormationFormSimpleFields = ({wish, setWish}) => {
    const {t} = useTexts();

    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t("FIELD_NBR_PLACE")}
                    type="number"
                    value={wish.capacity}
                    onChange={e => setWish({...wish, capacity: e.target.value})}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t("FIELD_NBR_OPEN_PLACE")}
                    type="number"
                    value={wish.open_capacity}
                    onChange={e => setWish({...wish, open_capacity: e.target.value})}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t("FIELD_NBR_RESEVERE_PLACE")}
                    type="number"
                    value={wish.reserve_capacity}
                    onChange={e => setWish({...wish, reserve_capacity: e.target.value})}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
        </>
    )
        ;
};

export default FormationFormSimpleFields;
