import { Divider, Grid2, Typography } from "@mui/material";
import React from "react";
import UserFormations from "./Search/UserFormations";

const UserListFormation = ({ formations }) => {
    return (Array.isArray(formations) && formations.length > 0 &&
        <>
            <Divider sx={{ my: 4 }} />
            <Typography variant="h5" sx={{ mb: 4, ml: 1, textAlign: { xs: 'center', sm: 'left' } }}>
                Formations du chef
            </Typography>
            <Grid2 container spacing={1} direction={{ xs: 'column', sm: 'row' }} sx={{ mb: 4 }}>
                {formations.map((userf) => (
                    <Grid2 size={{ xs: 12, md: 4, lg: 4 }} key={userf.id}>
                        <UserFormations f={userf} key={userf.id} />
                    </Grid2>
                ))}
            </Grid2>
        </>
    );
}
export default UserListFormation;