import React from "react";
import ChangeConfigWishDisplayRemove from "./ChangeConfigWishDisplayRemove";
import ChangeConfigWishDisplayForm from "./ChangeConfigWishDisplayForm";

const ChangeConfigWish = ({
    wish,
    realodWish,
    openChangeFunction,
    setOpenChangeFunction
}) => {
    return (
        <>
            {/* If wish is NOT closed, allow */}
            {wish.close === 0 ? (
                <ChangeConfigWishDisplayForm wish={wish} setOpenChangeFunction={setOpenChangeFunction} openChangeFunction={openChangeFunction} realodWish={realodWish} />
            ) : (
                <ChangeConfigWishDisplayRemove wish={wish} />
            )}
        </>
    );
};

export default ChangeConfigWish;
