import React, {useState} from 'react';

import {Button, MenuItem, Select, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle,} from '@mui/material';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import {useConfirm} from "../../Provider/ConfirmProvider";
import useTexts from "../../hooks/useTexte";

const StagiareCardAdminMove = ({fetchFormations, user, refreshUser, formations}) => {
    const [open, setOpen] = useState(false);
    const {requestConfirm} = useConfirm();
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();


    const moveToFormation = async (formationId) => {
        requestConfirm(t("CONFIRMATION_MOVE_STAGIAIRE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/user/${user.formation_id}/${user.user_id}/move/${formationId}`;
                await fetchWithToken(apiUrl, 'POST');
                refreshUser();
                setOpen(false);
            } catch (error) {
                console.error('Erreur lors du déplacement du stagiaire:', error);
            }
        });
    };

    return (
        <>
            <Button
                className='azimuth-btn outlined full-width'
                onClick={() => {
                    fetchFormations();
                    setOpen(true);
                }}
            >
                Déplacer
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Déplacer le stagiaire</DialogTitle>
                <DialogContent>
                    {formations.length > 0 && (
                        <FormControl fullWidth sx={{marginTop: 2}}>
                            <InputLabel id='label-formation'>Formation</InputLabel>
                            <Select
                                labelId='label-formation'
                                label="Formation"
                                value=""
                                onChange={(e) => moveToFormation(e.target.value)}
                            >
                                {formations.map((formation) => (
                                    <MenuItem key={formation.id} value={formation.id}>
                                        {formation.nom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default StagiareCardAdminMove;
