import { Box, Button, Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTexts from "../../hooks/useTexte";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import LoaderWait from "../LoaderWait";
import MaficheDocumentMedia from "./MaficheDocumentMedia";

function MaficheDocument({ id }) {
    const [attachments, setAttachments] = useState([]);
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const [isLoadingAttachement, setIsLoadingAttachement] = useState(true);

    const photoAttachment = attachments.find(attachment => attachment.type === 'photo');

    const loadAttachement = async () => {
        try {
            setIsLoadingAttachement(true);
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/attachement`;
            const data = await fetchWithToken(apiUrl);
            setAttachments(data);
            setIsLoadingAttachement(false);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };


    const handleFileUpload = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);

        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/savedoc/${type}`;
            await fetchWithToken(apiUrl, "POST", formData, 1);
            loadAttachement();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    useEffect(() => {
        loadAttachement();
    }, []);

    if (isLoadingAttachement) {
        return <LoaderWait />;
    }
    return (

        <div className='fiche-documents'>
            <Grid2 size={{ xs: 12, sm: 6 }} className='photoInput'>
                <MaficheDocumentMedia photoAttachment={photoAttachment} id={id} loadAttachement={loadAttachement} />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={e => handleFileUpload(e.target.files[0], 'photo')}
                        style={{ display: 'none' }}
                        id="upload-photo"
                    />
                    <label htmlFor="upload-photo">
                        <Button variant="contained" component="span">{t("TEXT_ADD_PHOTO")}</Button>
                    </label>
                </Box>
            </Grid2>
        </div>
    )
}

export default MaficheDocument;
