import FormationMangerAdminListUserAdd from "./FormationAdminListUserAdd";
import {Box, Button} from "@mui/material";
import React from "react";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";

const FormationAdminListUserFormAction = ({wish, handleListUser}) => {
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();

    const handleLaunchMoulinetteValidation = async () => {
        try {
            var data = {
                type: "validerliste"
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/moulinette`;
            await fetchWithToken(apiUrl, "POST", data);

            handleListUser();
        } catch (error) {
            console.error('Erreur lors du lancement de la moulinette:', error);
        }
    };
    const handleLaunchMoulinetteCanceln = async () => {
        try {
            var data = {
                type: "validerliste"
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/cancelmoulinette`;
            await fetchWithToken(apiUrl, "POST", data);

            handleListUser();
        } catch (error) {
            console.error('Erreur lors du lancement de la moulinette:', error);
        }
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <FormationMangerAdminListUserAdd wish={wish} handleListUser={handleListUser}/>
            <Button
                className="azimuth-btn full-width"

                onClick={handleLaunchMoulinetteValidation}
            >
                {t("VALID_MOULINETTE_PROPAL")}
            </Button>
            <Button
                className="azimuth-btn full-width"
                onClick={handleLaunchMoulinetteCanceln}
            >
                {t("EFFACER_PROPOSITION")}
            </Button>
        </Box>

    )

};

export default FormationAdminListUserFormAction;
