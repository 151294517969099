import { FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

const  AzSelect = ({handleInputChange,sectionIndex , field, fieldValue})=>{
    return (
        <FormControl fullWidth className="form-element" sx={{ mt: 3 }}>
            <InputLabel id={`select-label-${field.name}`}>
                Sélectionner une option
            </InputLabel>
            <Select
                label='Sélectionner une option'
                labelId={`select-label-${field.name}`}
                value={fieldValue}
                onChange={e => handleInputChange(sectionIndex, field.name, e.target.value)}
            >
                {field.options.map(option => (
                    <MenuItem key={option} value={option} className="form-element">{option}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default  AzSelect
