import React from 'react';
import CompetenceTable from "./CompetenceTable";
import AzCheckbox from "./types/AzCheckbox";
import AzSelect from "./types/AzSelect";
import AzText from "./types/AzText";
import AzRadio from "./types/AzRadio";

function areEqual(prevProps, nextProps) {

    // Vérifier d'abord si les champs de base sont égaux.
    const prevValue = prevProps.formState[`${prevProps.sectionIndex}_${prevProps.field.name}`];
    const nextValue = nextProps.formState[`${nextProps.sectionIndex}_${nextProps.field.name}`];
    if (prevValue !== nextValue) {
        return false;
    }

    // Vérifier les champs spécifiques aux compétences si le type est 'competences'
    if (prevProps.field.type === 'competences') {
        for (let competence of prevProps.field.competences) {
            const prevCompetenceValue = prevProps.formState[`${prevProps.sectionIndex}_${prevProps.field.name}_${competence}`];
            const nextCompetenceValue = nextProps.formState[`${prevProps.sectionIndex}_${prevProps.field.name}_${competence}`];
            if (prevCompetenceValue !== nextCompetenceValue) {
                return false;
            }
        }
    }

    return true;
}

const FormField = React.memo(({
    field,
    sectionIndex,
    formState,
    handleInputChange,
    handleCheckboxChange,
    readOnly
}) => {

    let fieldValue = formState[`${sectionIndex}_${field.name}`] || '';

    switch (field.type) {
        case 'text':
        case 'textarea':
            return  <AzText readOnly={readOnly} field={field} sectionIndex={sectionIndex} fieldValue={fieldValue} handleInputChange={handleInputChange} />
        case 'select':
            return  <AzSelect field={field} sectionIndex={sectionIndex} fieldValue={fieldValue} handleInputChange={handleInputChange} />
        case 'checkbox':
            return  <AzCheckbox field={field} sectionIndex={sectionIndex} formState={formState} handleCheckboxChange={handleCheckboxChange}/>
        case 'radio':
            return  <AzRadio field={field} sectionIndex={sectionIndex} handleInputChange={handleInputChange}  fieldValue={fieldValue}/>
        case 'competences':
            return (
                <CompetenceTable
                    className="form-element"
                    field={field}
                    sectionIndex={sectionIndex}
                    formState={formState}
                    handleInputChange={handleInputChange}
                />
            );

        default:
            return null;
    }
}, areEqual);

export default FormField;
