import {Button} from "@mui/material";
import React, {useState} from "react";
import useTexts from "../hooks/useTexte";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import ForeignPageEditorModal from "./editor/ForeignPageEditorModal";

const ForeignPageEditor = ({personne, getEtranger}) => {
    const {t} = useTexts();
    const fetchWithToken = useFetchWithToken();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formValues, setFormValues] = useState({email: '', nom: '', prenom: '', telephone: ''});
    const [currentEtrangerId, setCurrentEtrangerId] = useState(null);

    const handleOpenEditDialog = () => {
        setFormValues({
            email: personne.email || '',
            nom: personne.lastname || '',
            prenom: personne.firstname || '',
            telephone: personne.phone || ''
        });
        setCurrentEtrangerId(personne.id);
        setDialogOpen(true);
    };
    const handleOpenAddDialog = () => {

        setDialogOpen(true);
    };
    const handleAddPersonne = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/etranger`;
            await fetchWithToken(apiUrl, "POST", formValues);
            setFormValues({email: '', nom: '', prenom: '', telephone: ''});
            setDialogOpen(false);
            getEtranger();
        } catch (error) {
            console.error("Error while adding foreign user: ", error);
        }
    };

    const handleEditPersonne = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/etranger/${currentEtrangerId}`;
            await fetchWithToken(apiUrl, "POST", {id: currentEtrangerId, ...formValues});
            setFormValues({email: '', nom: '', prenom: '', telephone: ''});
            setDialogOpen(false);
            setCurrentEtrangerId(null);
            getEtranger();
        } catch (error) {
            console.error("Error while updating foreign user: ", error);
        }
    };

    const handleSave = async () => {
        if (personne) {
            return handleEditPersonne();
        } else {
            return handleAddPersonne();
        }
    };

    const handleStart = async () => {
        if (personne) {
            return handleOpenEditDialog();
        } else {
            return handleOpenAddDialog();
        }
    };

    return (
        <>
            <Button
                variant="contained"
                color="success"
                onClick={handleStart}

                id='add-text-button'
            >
                {!personne ? (
                    t("BUTTON_ADD_ETRANGER")
                ) : (
                    t("BUTTON_EDIT")
                )}
            </Button>
            <ForeignPageEditorModal handleSave={handleSave} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}  personne={personne} formValues={formValues} setFormValues={setFormValues}/>
        </>
    )
}

export default ForeignPageEditor